import { Button, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';

interface IChangePassProps {
  onSubmitPassword: (email: string) => any;
}

export const ChangePassScreen = ({ onSubmitPassword }: IChangePassProps) => {
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data: any) => {
    console.log('data: ', data);
    if (data.password != data.password_confirm) {
      setError('password_confirm', {
        type: 'manual',
        message: 'not match',
      });
    } else {
      onSubmitPassword(data.password);
    }
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <small className="d-block pwd-note">新しいパスワードを入力してください</small>
      <Form.Group className="change-pass-form">
        <Form.Label className="label-base required mb-3">新パスワード</Form.Label>
        <input
          type="password"
          className="input-base"
          placeholder="新パスワードを入力してください"
          {...register('password', { required: true, minLength: 8, maxLength: 32 })}
        />
        {errors.password && errors.password.type === 'required' && (
          <span className="input-base" style={{ color: 'red' }}>
            新パスワードが必須項目です。
          </span>
        )}
        {errors.password &&
          (errors.password.type === 'minLength' || errors.password.type === 'maxLength') && (
            <span className="input-base" style={{ color: 'red' }}>
              ※８文字以上32文字以内で入力してください。
            </span>
          )}
      </Form.Group>
      <Form.Group className="change-pass-form">
        <Form.Label className="label-base required mb-3">新パスワード（確認用）</Form.Label>
        <input
          type="password"
          className="input-base"
          placeholder="新パスワードを入力してください"
          {...register('password_confirm', { required: true, minLength: 8, maxLength: 32 })}
        />
        {errors.password_confirm && errors.password_confirm.type === 'required' && (
          <span className="input-base" style={{ color: 'red' }}>
            新パスワード（確認用）が必須項目です。
          </span>
        )}
        {errors.password_confirm &&
          (errors.password_confirm.type === 'minLength' ||
            errors.password_confirm.type === 'maxLength') && (
            <span className="input-base" style={{ color: 'red' }}>
              ※８文字以上32文字以内で入力してください。
            </span>
          )}
        {errors.password_confirm && errors.password_confirm.type === 'manual' && (
          <span className="input-base" style={{ color: 'red' }}>
            ※パスワードが一致しませんでした。
          </span>
        )}
      </Form.Group>

      <div className="text-center">
        <Button variant="base" type="submit">
          変更
        </Button>
      </div>
    </Form>
  );
};
