import moment from 'moment';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import DefaultGoldAvatar from 'assets/images/avatar-default-gold.png';
import DefaultPaidAvatar from 'assets/images/avatar-default-paid.png';
import DefaultAvatar from 'assets/images/img_default_avatar.svg';
import { MessageType, PlanType, UserRole } from 'config/constants';
import { Message } from 'modules/message/models';
import { MamaProfile, ProviderProfile } from 'modules/user/models';
import ImageView from './ImageView';

interface IProps {
  message: Message;
  partner: MamaProfile | ProviderProfile;
  userPlan?: PlanType;
  permissionNeed?: PlanType;
}

export const OtherMessage = ({ message, partner, userPlan, permissionNeed }: IProps) => {
  const history = useHistory();

  const [zoomIn, setZoomIn] = useState(false);

  let date = '';
  let time = '';
  try {
    date = moment(message.created_at.toDate()).format('YYYY年M月D日');
    time = moment(message.created_at.toDate()).format('HH:mm');
  } catch (err: any) {
    console.log('Format date time error: ', err);
  }

  const renderAvatarSrc = () => {
    if (partner.role === UserRole.Provider) return partner.avatar || DefaultAvatar;
    if (userPlan && permissionNeed) {
      if (userPlan >= permissionNeed) return partner.avatar || DefaultAvatar;
      if (permissionNeed === PlanType.Standard) return DefaultPaidAvatar;
      if (permissionNeed === PlanType.Gold) return DefaultGoldAvatar;
    }
    return DefaultAvatar;
  };

  const goToProfile = (user: MamaProfile | ProviderProfile) => {
    const isMama = partner.role === UserRole.Provider;
    if (!isMama && userPlan && permissionNeed) {
      if (userPlan < permissionNeed) return;
    }
    history.push(`/search/${user.id}`, {
      user: user,
      isMama: isMama,
    });
  };

  const renderImages = () => {
    if (message.type === MessageType.Images && message.image_urls) {
      return (
        <p>
          {zoomIn && (
            <ImageView imageUrl={message.image_urls[0]} onClose={() => setZoomIn(false)} />
          )}
          {message.image_urls.map((image, index) => (
            <img
              key={index}
              src={image}
              alt=""
              style={{ maxHeight: '10rem', maxWidth: '100%', cursor: 'pointer' }}
              onClick={() => setZoomIn(true)}
            />
          ))}
        </p>
      );
    } else return null;
  };

  return (
    <div className="guest">
      <img className="avatar" src={renderAvatarSrc()} alt="" onClick={() => goToProfile(partner)} />
      {!message.is_deleted && (
        <div className="content">
          <div className="icon-left"></div>
          <div className="text">
            {message.type === MessageType.Text && <p> {message.text}</p>}
            {renderImages()}
          </div>
        </div>
      )}
      {message.is_deleted && <div className="content deleted">メッセージが削除されました</div>}
      <div className="send-time">
        <div>
          <p className="date">{date}</p>
          <p className="time">{time}</p>
        </div>
      </div>
    </div>
  );
};
