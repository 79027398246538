import { DateTimePicker, DatesProvider } from '@mantine/dates';
import moment from 'moment';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { Order } from 'modules/special-order/models';

interface IOrderProps {
  onSubmitOrder: (data: Order) => Promise<boolean>;
}

const HOURS: Array<number> = [];
for (let i = 1; i <= 12; i++) {
  HOURS.push(i);
}

export const OrderCreateForm = ({ onSubmitOrder }: IOrderProps) => {
  const {
    control,
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    register('desired_datetime', { required: true });
  }, [register]);

  const onSubmit = async (data: any) => {
    console.log('data: ', data);
    data = {
      ...data,
      is_receive_gift: data.is_receive_gift ? '1' : undefined,
      desired_datetime: moment(data.desired_datetime).format('YYYY/MM/DD HH:mm'),
    };
    const result = await onSubmitOrder(data);
    if (result) {
      setValue('desired_datetime', '');
      reset();
    }
  };

  return (
    <form className="sorder-form" onSubmit={handleSubmit(onSubmit)}>
      <div className="col">
        <div className="mb-3">
          <label className="form-label">
            <div>
              <span className="require_form">必須</span>
              <span className="title_form">希望日時</span>
            </div>
          </label>
          <Controller
            control={control}
            name="desired_datetime"
            render={({ field }) => (
              <DatesProvider settings={{ locale: 'ja' }}>
                <DateTimePicker
                  placeholder="希望日時を選択してください"
                  value={field.value}
                  onChange={(date: Date) => {
                    field.onChange(date);
                  }}
                  valueFormat="YYYY/MM/DD HH:mm"
                  minDate={new Date()}
                />
              </DatesProvider>
            )}
          />
          {errors.desired_datetime && errors.desired_datetime.type === 'required' && (
            <span style={{ color: 'red' }}>希望日時が必須項目です。</span>
          )}
        </div>
      </div>
      <div className="col">
        <div className="mb-3">
          <label className="form-label">
            <div>
              <span className="require_form">必須</span>
              <span className="title_form">希望デート利用時間</span>
            </div>
          </label>
          <select className="btnx form-select" {...register('desired_time', { required: true })}>
            <option value="">希望時間を選択してください</option>
            {HOURS.map((val) => (
              <option key={val} value={val}>
                {val}時間
              </option>
            ))}
          </select>
          {errors.desired_time && errors.desired_time.type === 'required' && (
            <span style={{ color: 'red' }}>希望時間が必須項目です。</span>
          )}
        </div>
      </div>
      <div className="col">
        <div className="mb-3">
          <label className="form-label">
            <div>
              <span className="require_form">必須</span>
              <span className="title_form">希望場所</span>
            </div>
          </label>
          <input
            className="btnx form-control"
            type="text"
            placeholder="例）渋谷駅"
            {...register('desired_place', { required: true })}
          />
          {errors.desired_place && errors.desired_place.type === 'required' && (
            <span style={{ color: 'red' }}>希望場所が必須項目です。</span>
          )}
        </div>
      </div>
      <div className="col">
        <div>
          <label className="form-label">
            <div>
              <span className="require_form">必須</span>
              <span className="title_form">希望金額</span>
            </div>
          </label>
          <input
            className="btnx form-control"
            type="number"
            placeholder="例）5,000円"
            {...register('desired_price', { required: true })}
          />
          {errors.desired_price && errors.desired_price.type === 'required' && (
            <span style={{ color: 'red' }}>希望金額が必須項目です。</span>
          )}
        </div>
        <div className="mb-3">
          <div className="checkbox_type flex items-center form-check">
            <input
              type="checkbox"
              id="orderFormCheckbox"
              className="form-check-input"
              {...register('is_receive_gift')}
            />
            <label title="" htmlFor="orderFormCheckbox" className="form-check-label">
              物品報酬でも可能な場合はこちらにチェックしてください
            </label>
          </div>
        </div>
      </div>
      <div className="col">
        <div className="mb-3">
          <label className="form-label">
            <div>
              <span className="require_form">必須</span>
              <span className="title_form">メッセージ</span>
            </div>
          </label>
          <textarea
            className="form-control"
            rows={8}
            placeholder="自分のしてほしい事や自分の叶えたい事等を入力してください。
                                叶えてくれるお相手からのメッセージがもらえます。希望金額や希望場所、
                                希望時間などを指定できるためロスタイムなくやり取りが可能になります。"
            {...register('message', { required: true })}
          ></textarea>
          {errors.message && errors.message.type === 'required' && (
            <span style={{ color: 'red' }}>メッセージが必須項目です。</span>
          )}
        </div>
      </div>
      <div className="col text-center">
        <button type="submit" className="submit_btn d-inline btn btn-primary">
          登録
        </button>
      </div>
    </form>
  );
};
