import { Helmet } from 'react-helmet';

import { Paragraph } from 'components/common/customParagraph';

import './style.css';

export const RuleScreen = () => {
  return (
    <div className="col-md-12 col-sm-12 col-xs-12 page-footer">
      <Helmet>
        <title>利用規約</title>
      </Helmet>
      <h3>利用規約</h3>
      <p className="content">
        <b>本サービスに登録し、ご利用いただけるのは、高校生を除く満18歳以上の方に限ります。</b>
        <br />
        <br />
        　本規約は、株式会社侍（以下「弊社」という）が運営するサービスで、”Desire”（以下「本サービス」という）
        を通じて会員に提供するサービスに関して、その諸条件を定めるものです。
        <br />
        ご一読頂き、本サービスを利用されたことにより、本利用規約に同意されたものとみなします。
        <br />
        <br />
        １、サービスの概要 <br />
        <Paragraph className="indent1">
          本サービスは、会員同士が趣味や嗜好の会う人を探し、また、相互にメッセージをやり取りするなどのコミュニティサービスです。
        </Paragraph>
        <Paragraph pre="（１）">
          本サービスの利用は、弊社が定めた認証手続きが完了し、本サービスを利用する資格を持つ、かつ、高校生を除いた18歳以上の
          方に限定しております。
        </Paragraph>
        <br />
        ２，本サービスの利用設定 <br />
        <Paragraph pre="（１）">
          利用希望者は、利用希望者自身が高校生を除く満18歳以上である旨を制約し、弊社が定める情報を入力し、さらにその後
          弊社の定める認証手続きを行う必要があります。 <br />
          尚、会員登録時に提供頂いた情報に虚偽があった場合や過去の利用状況等から、本サービスの健全な運営に支障をきたす恐れの
          あるなど弊社が判断した場合、会員登録を無効とさせて頂きます。 <br />
          さらに、上記の制約に違反していると判断された場合、弊社による損害賠償請求を行うこともあります。
        </Paragraph>
        <br />
        <Paragraph pre="（２）">
          弊社は、他会員の通報や悪質な会員による本利用規約への違反行為を防止する目的等、本サービスの正常かつ健全な運営の
          ために必要な限度で、本サービスへの投稿やメッセージについてその内容の一部を確認することがあります。その際、
          投稿やメッセージの全部、又は一部を閲覧不可能な状態とすることや削除することがあります。
        </Paragraph>
        <br />
        ３，プロフィール情報・個人情報等 <br />
        <Paragraph pre="（１）">
          弊社は、会員のプロフィール情報を以下各号の目的で利用します。
          <Paragraph pre="①">本サービスの運営 </Paragraph>
          <Paragraph pre="②">本サービスの、品質管理の為のアンケート調査や分析等</Paragraph>
          <Paragraph pre="③">会員に対しての連絡</Paragraph>
        </Paragraph>
        <br />
        <Paragraph pre="（２）">
          弊社は、会員のプロフィール情報について、次の各号の場合を除き本人以外の第三者に開示しないものとします。
          <Paragraph pre="①">
            本サービスの向上を目的とし、関連事業開発および提携企業のマーケティング等での取り扱い
          </Paragraph>
          <Paragraph pre="②">
            会員の本利用規約に違反する行為が原因で、第三者から及び弊社が損害賠償金などの支払い等の行為があった場合、また、犯罪行為等による公的機関からの情報提供があった場合
          </Paragraph>
        </Paragraph>
        <br />
        <Paragraph pre="（３）">
          会員は、本サービス利用につき次の本人確認書類の添付とプロフィール情報を登録し、弊社による１８歳以上の者（高校生を除く）である本人確認を行い、利用開始となります。
          <Paragraph pre="①">運転免許証</Paragraph>
          <Paragraph pre="②">国民健康被保険者証、共催組合員証</Paragraph>
          <Paragraph pre="③">旅券</Paragraph>
          <Paragraph pre="④">①～③等の官公庁が発行する公的な本人確認書類</Paragraph>
        </Paragraph>
        <br />
        <Paragraph pre="（４）">
          会員は、本サービスに登録した個人情報について、開示・削除または訂正の請求をおこなうことができ、会員本人からの請求であることが確認出来る場合に限り、弊社は速やかに対応いたします。
          <br />
          尚、弊社の本サービスにおける本件問い合わせ窓口は、次の通りです。
          <br />
          <br />
          お客様ご相談窓口:　info@desire777.com
        </Paragraph>
        <br />
        ４，禁止行為
        <br />
        <Paragraph pre="（１）">
          会員は、以下に定める行為等・内容等での投稿やメッセージの送信等を行ってはいけません。また、会員が禁止行為に違反したと弊社が判断した場合には、送信した内容の削除や本サービスの停止処分等の措置をとらせて頂く場合があります。
          <Paragraph pre="①">公序良俗に反すること </Paragraph>
          <Paragraph pre="②">良識や品位に欠けること。</Paragraph>
          <Paragraph className="indent1">
            ・一般的に、他の会員や会員が不快にに感じる表現や内容の送信等
            <br />
            ・アダルトサイト、他社のマッチングサイト関連の表現・内容の送信等や、リンクの誘導等
            <br />
            ・著しく性欲を刺激する内容や画像（イラスト等も含む）の送信等
            <br />
            ・残虐または、暴力的な内容や画像（イラスト等も含む）の送信等
            <br />
            ・虐待・殺害・自殺・自殺行為を肯定、勧誘あるいは助長したりその恐れのある内容や勧誘等の送信等
            <br />
            ・性に関する表現として、わいせつな行為や対象を連想させるモのや卑劣な表現・内容等の送信等
            <br />
            ・その他、他の会員が不快に感じる、またはその恐れのある表現や内容等の送信等
            <br />
            ・他の会員や第三者に対して、誹謗中傷もしくは侮辱する、または名誉や信用を傷つける行為等の送信等
            <br />
            ・人種・民族・性別・信条・社会的身分・居住地・身体的特徴・病歴・教育・財産等による差別につながる表現等の送信等
            <br />
            ・本人の承諾の無い個人情報の送信等
            <br />
            ・他の会員・第三者に恐怖心を生じさせる目的で危害を加える事を通告する脅迫行為やストーカー行為
            <br />
            ・犯罪・その他法令違反行為を推奨、肯定・もしくは助長する等、社会的に有害である行為の内容等の送信や行為
            <br />
            ・本サービスを通じて児童を対象とする不正誘引または、会員を児童との異性交際等をあっせんする行為
            <br />
            ・売買春を目的として本サービスを利用する行為
            <br />
          </Paragraph>
          <br />
          <Paragraph pre="③">弊社が承諾しない商業行為 </Paragraph>
          <Paragraph className="indent1">
            ・ネットワークビジネス関連（MLM・マネーゲーム等を含む）
            <br />
            ・商業用の広告や宣伝を目的とした本サービスの利用
            <br />
            ・本サービスの一部、もしくは全部の利用権譲渡する行為
            <br />
            ・不正に他人になりすます行為等
            <br />
            ・宗教団体、もしくはそれと同視し得る団体への勧誘・布教・寄付等を求める、またはその恐れのある行為
            <br />
            ・その他、弊社が不適切と判断する行為
          </Paragraph>
        </Paragraph>
        <br />
        ５，利用料金・支払い・プラン変更
        <br />
        <Paragraph pre="（１）">
          無料プラン
          <Paragraph pre="①">受信はできるが送信は不可 </Paragraph>
        </Paragraph>
        <br />
        <Paragraph pre="（２）">
          有料プラン・・・女性、レズビアンの受け側は無料となります。
          <Paragraph pre="①">スタンダード会員　(30日毎に請求）</Paragraph>
          <Paragraph className="indent1">・月額５，５００円（税込み）</Paragraph>
          <br />
          <Paragraph pre="②">
            ゴールド会員（30日日毎に請求）・・・スペシャルオーダーの使用が可。
          </Paragraph>
          <Paragraph className="indent1">
            ・月額７，７００円（税込み）
            <br />
            ・３ヶ月毎（１０％還元）２０，７９０円（税込み）
            <br />
            ・６ヶ月毎（１５％還元）３９，２７０円（税込み）
            <br />
            ・１２ヶ月毎（２０％還元）７３，９２０円（税込み）
          </Paragraph>
        </Paragraph>
        <br />
        <Paragraph pre="（３）">
          プラン変更
          <Paragraph>
            スタンダードプラン契約中にゴールドプランを契約した場合、その月は2プランの引き落としとなります。
          </Paragraph>
        </Paragraph>
        ※注意　　　いかなる場合も返金は致しかねますのでご理解の上、ご契約ください。
        <br />
        <br />
        <Paragraph pre="（４）">
          支払い
          <Paragraph pre="①">
            有料プランの支払い方法は、クレジットカード決済のみとなります。
          </Paragraph>
          <Paragraph pre="②">利用料は、申し込み期間に応じて自動継続課金されます。</Paragraph>
          <Paragraph pre="③">
            会員への本サービスの利用による代金決済について、領収書等は発行致しません。
          </Paragraph>
        </Paragraph>
        <br />
        <Paragraph pre="（５）">
          その他
          <Paragraph pre="①">本サービスは、クーリング・オフの制度が適用されません。</Paragraph>
        </Paragraph>
        <br />
        ６，退会について
        <br />
        <Paragraph className="indent3">
          <Paragraph pre="①">
            会員は、何時でもいかなる理由であっても、本サービスの利用を停止し会員資格を終了させることができます。
            <br />
            退会する場合、弊社が定める手続きに従い、弊社宛に退会通知を行うものとします。また、退会にあたっての手数料等の発生はございません。
          </Paragraph>
          <Paragraph pre="②">
            会員が退会した場合、本サービスにて登録された会員情報は削除されるものとし、弊社は一切責任を負わないとします。
          </Paragraph>
          <Paragraph pre="③">
            会員が退会された場合、本サービスの有料プランの利用期間が終了していなくても、弊社は返金致しません。
          </Paragraph>
        </Paragraph>
        <br />
        ７，その他
        <br />
        <Paragraph className="indent3">
          <Paragraph pre="①">
            会員同士の交際や交流を含む各種のトラブルについては、会員自らが責任を追うものとします。
          </Paragraph>
          <Paragraph pre="②">
            会員の不正アクセスや携帯端末のウイルス感染が原因で、会員のプロフィール情報や個人情報などが流出したり、またそれを第三者の運営するインターネットサービス等に掲載された場合においても、前項と同様に起因自らが責任を負うものとし、弊社では責任を負わないものとします。
          </Paragraph>
        </Paragraph>
      </p>
    </div>
  );
};
