import LayoutStore from './layout';
import NotificationStore from './notification';
import SessionStore from './session';
import UIStore from './ui';

const uiStore = new UIStore();
const notificationStore = new NotificationStore();
const sessionStore = new SessionStore(notificationStore);
const layoutStore = new LayoutStore();

const stores = {
  uiStore,
  notificationStore,
  sessionStore,
  layoutStore,
};

export default stores;
