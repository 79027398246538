import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useHistory, useParams } from 'react-router-dom';

import AddressImg from 'assets/icons/address.png';
import IcBack from 'assets/icons/ic_back.svg';
import IcTrash from 'assets/icons/ic_trash.png';
import ICON3 from 'assets/icons/icon-sorder-3.svg';
import JobImg from 'assets/icons/job.png';
import DefaultAvatar from 'assets/images/img_default_avatar.svg';
import { Pagination, PaginationData } from 'components/common/pagination';
import { MessageType } from 'config/constants';
import useLayout from 'hooks/use-layout';
import { useStores } from 'hooks/use-stores';
import { Conversation } from 'modules/message/models';
import { Order } from 'modules/special-order/models';
import { ProviderProfile } from 'modules/user/models';
import { del, get } from 'services';
import { deleteConversation, getUnreadCount, updateUnread } from 'services/firebase';
import LayoutStore from 'stores/layout';
import NotificationStore from 'stores/notification';
import SessionStore from 'stores/session';
import UIStore from 'stores/ui';
import {
  getAge,
  getGender,
  getPrefecture,
  getProviderService,
  getProviderType,
  toAwait,
} from 'utils';

import '../../provider/favorite/style.scss';
import styles from './styles.module.scss';

export const OrderDetailScreen = observer(() => {
  const history = useHistory();
  const { orderId } = useParams<{ orderId: string }>();

  const uiStore: UIStore = useStores().uiStore;
  const notificationStore: NotificationStore = useStores().notificationStore;
  const layoutStore: LayoutStore = useStores().layoutStore;
  const sessionStore: SessionStore = useStores().sessionStore;

  const [conversations, setConversations] = useState<Array<Conversation>>([]);
  const [order, setOrder] = useState<Order>({} as Order);
  const [pagination, setPagination] = useState<PaginationData>({
    currentPage: 1,
    limit: 30,
    total: 0,
  });
  const [inited, setInited] = useState<boolean>(false);

  useLayout();

  useEffect(() => {
    getData(1).then(() => setInited(true));
  }, []);

  useEffect(() => {
    if (inited) getData(pagination.currentPage); // reload data when new message arrived
  }, [notificationStore.specialCount]);

  const getData = async (page: number) => {
    const loading = uiStore.showLoading();
    const [res, err] = await toAwait(get('mama/orders/' + orderId, { page: page }));
    uiStore.hideLoading(loading);
    if (err) {
      console.log('error when get order: ', err);
      return;
    }
    setOrder(res);
    setPagination({
      currentPage: page,
      limit: res.message_inboxs.per_page,
      total: res.message_inboxs.total,
    });

    let datas = res.message_inboxs.data;
    const getUnreadCountTasks = [];
    for (let i = 0; i < datas.length; i++) {
      getUnreadCountTasks.push(getUnreadCount(datas[i].id.toString(), true));
    }

    Promise.all(getUnreadCountTasks)
      .then((unreadCounts) => {
        console.log('unreadCounts: ', unreadCounts);
        for (let i = 0; i < unreadCounts.length; i++) {
          datas[i].unread_count = unreadCounts[i];
        }
      })
      .finally(() => {
        setConversations(datas);
        uiStore.hideLoading(loading);
      });
  };

  const goToProfile = (item: Conversation) => {
    history.push(`/search/${item.user.id}`, {
      user: item.user,
      isMama: true,
    });
  };

  const gotoChatOrderScreen = (item: Conversation) => {
    history.push(`/messages/${item.user.id}`, {
      order: order,
      conversationId: item.id,
    });
  };

  const renderServices = (item: any) => {
    const user = item.user as ProviderProfile;
    return (
      <>
        {user.member_services?.includes(1) && (
          <span className="user_status_item btn-color-red">{getProviderService(1)}</span>
        )}
        {user.member_services?.includes(2) && (
          <span className="user_status_item btn-color-yellow">{getProviderService(2)}</span>
        )}
        {user.member_services?.includes(3) && (
          <span className="user_status_item btn-color-purple">{getProviderService(3)}</span>
        )}
      </>
    );
  };

  const onDeleteClick = async (conversationId: number) => {
    console.log('Delete conversation');
    const confirmed = await uiStore.confirm({
      title: '削除',
      contentComponent: (
        <p>
          スレッドメッセージを削除します。<br></br>よろしいでしょうか。
        </p>
      ),
      confirmText: 'OK',
      cancelText: 'キャンセル',
    });
    if (confirmed && sessionStore.session) {
      console.log('Delete confirmed: ', conversationId);

      try {
        await deleteConversation(conversationId, sessionStore.session.id);
        await updateUnread(
          sessionStore.session.id,
          sessionStore.session.role,
          conversationId.toString()
        );

        //TODO: invoke API to delete conversation
        const res = await del(`message/delete-inbox/${conversationId}`);
        console.log('Delete api res: ', res);
      } catch (err) {
        console.log('Delete thread error: ', err);
      } finally {
        // onDeleteComplete();
        getData(1);
      }
    }
  };

  return (
    <div id="my_favorite">
      <Helmet>
        <title>スペシャルオーダー</title>
      </Helmet>

      <div className="header-with-back">
        <button onClick={() => history.goBack()}>
          <img src={IcBack} alt="" />
        </button>

        {!layoutStore.isShowHeader && <div className="title">スペシャルオーダー</div>}
      </div>

      {layoutStore.isShowHeader && (
        <div className="sorder-page">
          <Container>
            <div className="text-center top-title-h1">
              <span>SPECIAL ORDER</span>
            </div>
            <div className="sub-title">スペシャルオーダー</div>
          </Container>
        </div>
      )}

      <div className="list_favorite">
        {conversations.map((item) => {
          const unreadCount =
            item.latest_message && item.latest_message.sender_id == item.user.id
              ? item.unread_count
              : 0;
          return (
            <div key={item.user.id} className="favorite_item" style={{ cursor: 'pointer' }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginRight: 10,
                }}
              >
                <img
                  src={IcTrash}
                  alt=""
                  className={styles.deleteButton}
                  onClick={() => onDeleteClick(item.id)}
                />
              </div>
              <div
                className="favorite_item_detail user_avatar has-gift"
                onClick={() => goToProfile(item)}
              >
                <div></div>
                <img src={item.user.avatar || DefaultAvatar} alt="" />
                {(item.user as ProviderProfile).is_receive_gif === 1 && (
                  <div className="received_gift">
                    物品提供
                    <br />
                    でも可
                  </div>
                )}
              </div>
              <div
                className="favorite_item_detail user_info"
                onClick={() => gotoChatOrderScreen(item)}
              >
                <div className="user_info_detail user_status">
                  {renderServices(item)}
                  <span className="user_name">{item.user.nick_name}</span>
                </div>
                <div className="user_info_detail user_age">
                  <span className="user_age_img">
                    <img src={JobImg} alt="" />
                  </span>
                  <span className="user_age_txt">
                    {getAge(item.user.birthday)}歳　{getGender(item.user.gender)}
                    {getProviderType((item.user as ProviderProfile).member_type_id)}
                  </span>
                </div>
                <div className="user_info_detail user_address">
                  <span className="user_address_img">
                    <img src={AddressImg} alt="" />
                  </span>
                  <span className="user_address_txt">{getPrefecture(item.user.prefecture_id)}</span>
                </div>
                {item.latest_message && (
                  <div className="last_message">
                    <span className="d-block">
                      {item.latest_message.type === MessageType.Images &&
                        item.user.id === item.latest_message.sender_id &&
                        item.user.nick_name + 'さんが写真を送りました'}
                      {item.latest_message.type === MessageType.Images &&
                        item.user.id !== item.latest_message.sender_id &&
                        'あなたが写真を送りました'}
                      {item.latest_message.type === MessageType.Text && item.latest_message.message}
                    </span>
                  </div>
                )}
              </div>
              <div
                style={{ marginLeft: 'auto' }}
                className={`d-flex flex-column justify-content-${
                  unreadCount > 0 ? 'between' : 'end'
                }`}
                onClick={() => gotoChatOrderScreen(item)}
              >
                {unreadCount > 0 && (
                  <div className={`d-flex justify-content-center align-items-center unread-count`}>
                    {unreadCount < 100 ? unreadCount : '99+'}
                  </div>
                )}
                <img src={ICON3} alt="icon3" />
              </div>
            </div>
          );
        })}
      </div>

      <Pagination pagination={pagination} onClickPage={(page) => getData(page)} />
    </div>
  );
});
