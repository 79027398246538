import axios from 'axios';
import * as AxiosLogger from 'axios-logger';

import stores from 'stores';
import { API_URL, ENV } from '../config';

const defaultHeaders = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
};

const instance = axios.create({
  headers: defaultHeaders,
});

let headers: any = { ...defaultHeaders };

export const setAuthorization = (token: string) => {
  if (token != '') headers = { ...headers, Authorization: `Bearer ${token}` };
  else delete headers['Authorization'];
};

if (ENV === 'local') {
  instance.interceptors.request.use(AxiosLogger.requestLogger);
  instance.interceptors.response.use(AxiosLogger.responseLogger);
}

instance.defaults.baseURL = API_URL;
instance.interceptors.request.use((config) => {
  config.headers = headers;
  return config;
});

instance.interceptors.response.use(
  (response) => {
    if (response.data) return response.data;
    return Promise.reject({ message: 'Empty response' });
  },
  (error) => {
    if (error.response) {
      console.log('error response: ', error.response.data);
      const { message, errors, error_code } = error.response.data;
      if (error.response.config.url === '/provider/update-plan') {
        // do nothing. Return handle to screen
      } else if (error_code == 'not_active') {
        stores.uiStore.showAlert({
          type: 'alert',
          title: '本人確認中です',
          message:
            '確認が完了し次第、本サービスをご利用頂けるようになります。<br/>本人確認が完了しましたらメールにてお知らせいたします。',
          btnText: '閉じる',
          linkTogo: '/',
        });
      } else if (error_code == 'rejected') {
        stores.uiStore.showAlert({
          type: 'error',
          notShowIcon: true,
          title: '登録が却下されました',
          message:
            '本人確認が完了し、登録が却下されました。<br/>異議申し立てはお問い合わせよりご連絡ください。',
          btnText: 'お問い合わせへ',
          linkTogo: '/inquiry',
        });
      } else if (error_code == 'NOT_ENOUGH_CHAT_POINTS') {
        stores.uiStore
          .confirm({
            title:
              'メッセージを開くためには有料会員になるか、<br/>ポイントを使用をする必要があります',
            content:
              '無料会員としてはお相手のプロフィールを確認することができます。その他お気に入り（相手に通知はされません）やブロック、通報なども可能です。実際に連絡を取ることなどはできません。連絡を取るためには有料会員になる、またはポイントを使用する必要がございます。',
            confirmText: '有料会員になる',
            confirmText2: 'ポイント購入する',
            cancelText: '閉じる',
          })
          .then((res) => {
            console.log('aaaa', res);
            if ((res as any).confirm2) {
              window.location.href = '/chat-point/packages';
            } else if (res == true) {
              window.location.href = '/provider/plan';
            }
          });
      } else {
        if (error.response.config.url !== '/login-sns' || error_code !== 401) {
          stores.uiStore.showAlert({
            type: 'error',
            message: message,
            btnText: '閉じる',
            linkTogo: error_code === 401 ? '/login' : undefined,
          });
        }
      }
    }

    return Promise.reject(error.response.data);
  }
);

export { instance as axios };

export const getAbsPath = async (path: string, params: any) => {
  const res = await axios.get(path, { params });
  console.log('Res banner bridge: ', res);
  return res.data;
};

export const get = async (path: string, params: any) => {
  const res = await instance.get(`/${path}`, { params });
  return res.data;
};

export const post = async (path: string, params: any) => {
  const res = await instance.post(`/${path}`, params);
  return res.data;
};

export const postFormData = async (path: string, formData: FormData) => {
  const res = await instance.post(`/${path}`, formData, {
    headers: { ...headers, 'Content-Type': 'multipart/form-data' },
  });
  return res.data;
};

export const put = async (path: string, params: any) => {
  const res = await instance.put(`/${path}`, params);
  return res.data;
};

export const del = async (path: string) => {
  const res = await instance.delete(`/${path}`);
  return res.data;
};

export const uploadImage = async (files: Array<File>) => {
  const url = 'message/send-image';
  const promises: Array<Promise<any>> = [];
  for (let file of files) {
    const formData = new FormData();
    formData.append('image', file);
    promises.push(postFormData(url, formData));
  }
  const imageUrls = await Promise.all(promises);
  return imageUrls.map((image) => image.url);
};
