import { Helmet } from 'react-helmet';

import './style.css';

export const DisplayScreen = () => {
  return (
    <div className="col-md-12 col-sm-12 col-xs-12 page-footer">
      <Helmet>
        <title>特定商取引法に基づく表示</title>
      </Helmet>
      <h3>特定商取引法に基づく表示</h3>
      <p className="content">
        <b>サービス提供事業者</b>
        <br />
        <br />
        株式会社 侍
        <br />
        <br />
        <b>運営責任者</b>
        <br />
        <br />
        土居 秀敏
        <br />
        <br />
        <b>所在地</b>
        <br />
        <br />
        大阪市淀川区宮原1-16-35
        <br />
        <br />
        <b>お問い合わせ</b>
        <br />
        <br />
        e-mail: info@desire777.com
        <br />
        tel: 06-6396-7107
        <br />
        <br />
        平日１０時から１８時
        <br />
        混雑時は、返信にお時間が掛かる場合がございます。
        <br />
        予めご了承下さい。
        <br />
        <br />
        <b>サービス利用料金</b>
        <br />
        <br />
        商品ごとに記載しており、消費税は内税表示していります。
        <br />
        <br />
        <b>継続決済のご案内について</b>
        <br />
        <br />
        (1)定期的な自動で課金
        <br />
        継続決済をクレジット決済にてお申込みの方は、
        毎月自動的に各種プラン毎の定められた月毎に課金されます。
        <br />
        <br />
        (2)継続決済の解約方法の案内
        <br />
        解約をご希望の場合はご本人様での操作が必要です <br />
        各有料プランのお客様は退会時、無料プランに切り替え後、アカウント削除にて退会となります。
        <br />
        1.マイページに移動 <br />
        2.現在のプラン　より無料会員登録 <br />
        3.プロフィールを編集する　　より最下段のアカウント削除 <br />
        以上で退会となります <br />
        なお、カード決済日はお客様によって異なります、ご契約日より2週間前までにご解約ください、データ反映に2週間前後を必要な場合があります。
        <br />
        ご契約日によってご解約された翌月も引き落としされる場合があります、あらかじめご了承ください。{' '}
        <br />
        いかなる場合もご返金は致しかねますのでご理解の程よろしくお願いします。
        <br />
        <br />
        （3）継続決済の停止処理日について
        <br />
        「継続決済の解約をご希望の場合は、 次回継続決済の課金日前日までにお願いいたします」
        <br />
        <br />
        <br />
        <b>サービス利用料以外の必要料金</b>
        <br />
        <br />
        インターネット接続には別途、ご契約通信料が発生します。
        <br />
        携帯電話からのご利用には、携帯通信料又はパケット通信料が発生します。
        <br />
        <br />
        <b>支払方法及び時間</b>
        <br />
        <br />
        お手続き完了後即時お支払いが発生します。サービス利用料金のお支払いは各カード会社により異なり、支払時期・規則は
        そのカード会社に準じます。
        <br />
        <br />
        <b>商品の返品・交換</b>
        <br />
        <br />
        サービス利用料金の返金は、サービスの性質上いかなる場合も行いません。
        <br />
        <br />
        <b>約款等の存ずる旨</b>
        <br />
        <br />
        インターネット異性紹介事業届け出済み
        <br />
        インターネット異性紹介 届け出番号 6221-0015-000
        <br />
        届出先情報 淀川警察署 tel:06-6305-1234
        <br />
        <br />
        <b>約款等の存ずる旨</b>
        <br />
        <br />
        有料プランご利用の際は必ず、利用規約の内容を確認し、同意をされたうえでお申し込みください。
      </p>
    </div>
  );
};
