import { ProviderProfile } from 'modules/user/models';
import { get } from 'services';

const getMyProfile = async (): Promise<ProviderProfile> => {
  let res = await get('provider/profile', {});
  if (res) {
    return res;
  } else {
    throw new Error('Empty response data!');
  }
};

export default {
  getMyProfile,
};
