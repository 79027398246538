import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { Link, useHistory } from 'react-router-dom';

import ICON3 from 'assets/icons/icon-sorder-3.svg';
import { Pagination, PaginationData } from 'components/common/pagination';
import { useStores } from 'hooks/use-stores';
import { Order } from 'modules/special-order/models';
import { get, post } from 'services';
import { getUnreadCountForOrder } from 'services/firebase';
import NotificationStore from 'stores/notification';
import SessionStore from 'stores/session';
import UIStore from 'stores/ui';
import { toAwait } from 'utils';
import { formatDateTimeJP } from 'utils/datetime';
import { OrderCreateForm } from './orderForm';

import './style.scss';

export const OrderListScreen = observer(() => {
  const history = useHistory();

  const uiStore: UIStore = useStores().uiStore;
  const sessionStore: SessionStore = useStores().sessionStore;
  const notificationStore: NotificationStore = useStores().notificationStore;

  const [orders, setOrders] = useState<Array<Order>>([]);
  const [pagination, setPagination] = useState<PaginationData>({
    currentPage: 1,
    limit: 30,
    total: 0,
  });
  const [inited, setInited] = useState<boolean>(false);

  useEffect(() => {
    if (sessionStore.isInited) {
      getData(1).then(() => setInited(true));
    }
  }, [sessionStore.isInited]);

  useEffect(() => {
    if (inited) reloadData(); // reload data when new message arrived
  }, [notificationStore.specialCount]);

  const getData = async (page: number) => {
    const loading = uiStore.showLoading();
    const [res, err] = await toAwait(get('mama/orders', { page: page }));
    uiStore.hideLoading(loading);
    if (err) {
      console.log('error when list news: ', err);
    } else {
      setPagination({
        currentPage: page,
        limit: res.per_page,
        total: res.total,
      });

      let datas = res.data;
      const getUnreadCountTasks = [];
      for (let i = 0; i < datas.length; i++) {
        getUnreadCountTasks.push(
          getUnreadCountForOrder(datas[i].id, sessionStore.session!.id, true)
        );
      }

      Promise.all(getUnreadCountTasks)
        .then((unreadCounts) => {
          console.log('unreadCounts: ', unreadCounts);
          for (let i = 0; i < unreadCounts.length; i++) {
            datas[i].unread_count = unreadCounts[i];
          }
        })
        .finally(() => {
          setOrders(datas);
          uiStore.hideLoading(loading);
        });
    }
  };

  const registOrder = async (data: Order) => {
    const loading = uiStore.showLoading();
    const [res, err] = await toAwait(post('mama/orders/create', data));
    uiStore.hideLoading(loading);
    if (err) {
      console.log('error when regist order: ', err);
      return false;
    } else {
      await uiStore.confirm({
        title: 'スペシャルオーダーを送信しました。',
        cancelText: '閉じる',
      });
      reloadData();
      return true;
    }
  };

  const reloadData = () => {
    getData(pagination.currentPage);
  };

  const gotoOrderDetail = (order: Order) => {
    history.push('/mama/special/' + order.id);
  };

  const renderItem = (order: Order) => {
    const unreadCount = order.unread_count || 0;
    return (
      <Row key={order.id} onClick={() => gotoOrderDetail(order)}>
        <Col style={{ flex: 1 }} className="flex flex-column justify-center">
          <table>
            <tbody>
              <tr>
                <td>
                  <span>希望日</span>
                </td>
                <td>
                  <span>：{formatDateTimeJP(order.desired_datetime)}</span>
                </td>
              </tr>
              <tr>
                <td>
                  <span>希望デート利用時間</span>
                </td>
                <td>
                  <span>：{order.desired_time}時間</span>
                </td>
              </tr>
              <tr>
                <td>
                  <span>希望場所</span>
                </td>
                <td>
                  <span>：{order.desired_place}</span>
                </td>
              </tr>
              <tr>
                <td>
                  <span>希望金額</span>
                </td>
                <td>
                  <span>：{order.desired_price}円</span>
                </td>
              </tr>
            </tbody>
          </table>
          <div className="flex items-start">
            <div>
              <span className="d-block">{order.message}</span>
            </div>
          </div>
        </Col>
        <Col
          style={{ flex: 0 }}
          className={`d-flex flex-column justify-content-${unreadCount > 0 ? 'between' : 'end'}`}
        >
          {unreadCount > 0 && (
            <div className={`d-flex justify-content-center align-items-center unread-count`}>
              {unreadCount < 100 ? unreadCount : '99+'}
            </div>
          )}
          <img src={ICON3} alt="icon3" />
        </Col>
      </Row>
    );
  };

  return (
    <div className="sorder-page">
      <Helmet>
        <title>スペシャルオーダー</title>
      </Helmet>
      <Container>
        <div className="text-center top-title-h1">
          <span>SPECIAL ORDER</span>
        </div>
        <div className="sub-title">スペシャルオーダー</div>
        <p className="sub-content">
          スペシャルオーダーとはサービス提供側のすべての会員に一斉送信ができる機能となっています。
          <br />
          自分のやりたい事、叶えてみたい事、夢などを送信して叶えてくれるお相手を探してみませんか？
        </p>
        <Link
          to="/mama/special/conversations"
          className="d-flex justify-content-center align-items-center btn"
        >
          <div>返信メッセージはこちら</div>
        </Link>

        <OrderCreateForm onSubmitOrder={(data) => registOrder(data)} />

        <div className="sorder-page-inner message-list-inner">
          {orders.map((item) => renderItem(item))}
        </div>

        <Pagination pagination={pagination} onClickPage={(page) => getData(page)} />
      </Container>
    </div>
  );
});
