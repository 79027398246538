export const ENV = process.env.REACT_APP_ENV || 'local';

export const API_URL = process.env.REACT_APP_API_URL || 'https://api-dev.desire777.com/api/v1';

export const FACEBOOK_ID = process.env.REACT_APP_FACEBOOK_ID || '';

export const LINE_ID = process.env.REACT_APP_LINE_ID || '';

export const LINE_SECRET = process.env.REACT_APP_LINE_SECRET || '';

export const REDIRECT_URI = process.env.REACT_APP_REDIRECT_URI || '';

export const REDIRECT_LINK_URI = process.env.REACT_APP_REDIRECT_LINK_URI || '';

export const FIREBASE_API_KEY = process.env.REACT_APP_FIREBASE_API_KEY || '';

export const FIREBASE_AUTH_DOMAIN = process.env.REACT_APP_FIREBASE_AUTH_DOMAIN || '';

export const FIREBASE_PROJECT_ID = process.env.REACT_APP_FIREBASE_PROJECT_ID || '';

export const FIREBASE_STORAGE_BUCKET = process.env.REACT_APP_FIREBASE_STORAGE_BUCKET || '';

export const FIREBASE_MESSAGING_SENDER_ID =
  process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID || '';

export const FIREBASE_APP_ID = process.env.REACT_APP_FIREBASE_APP_ID || '';

export const FIREBASE_MEASUREMENT_ID = process.env.REACT_APP_FIREBASE_MEASUREMENT_ID || '';

export const FIREBASE_VAPID_KEY = process.env.REACT_APP_FIREBASE_VAPID_KEY || '';
