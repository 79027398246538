import { useState } from 'react';

import DefaultAvatar from 'assets/images/img_default_avatar.svg';
import { ProviderProfile } from 'modules/user/models';
import { getProviderService } from 'utils';

interface IProps {
  user: ProviderProfile;
}

export const AvatarContainer = ({ user }: IProps) => {
  const [mainAvatar, setMainAvatar] = useState({
    src: user.avatar,
    type: 0,
  });

  return (
    <div className="slider_wrap">
      <div className="gift_rec_wrap">
        {user.member_services.includes(1) && (
          <div className="gift_rec gift_first">{getProviderService(1)}</div>
        )}
        {user.member_services.includes(2) && (
          <div className="gift_rec gift_second">{getProviderService(2)}</div>
        )}
        {user.member_services.includes(3) && (
          <div className="gift_rec gift_four">{getProviderService(3)}</div>
        )}
      </div>
      {user.is_receive_gif && <div className="gift_third">物品提供 でも可</div>}
      <div className="slider_wrap_img">
        <div className="my_page_content_avatar_area" id="slide-1">
          {mainAvatar.type === 0 || !mainAvatar.src ? (
            <img src={mainAvatar.src || DefaultAvatar} style={{ objectFit: 'cover' }} />
          ) : (
            <video src={mainAvatar.src} autoPlay />
          )}
        </div>
      </div>
      <div className="my_page_content_avatar_option">
        <div
          className="my_page_content_avatar_option_area"
          onClick={() =>
            setMainAvatar({
              src: user.avatar,
              type: 0,
            })
          }
        >
          <img src={user.avatar} />
        </div>
        <div
          className="my_page_content_avatar_option_area"
          onClick={() =>
            setMainAvatar({
              src: user.avatar_1,
              type: 0,
            })
          }
        >
          <img src={user.avatar_1} />
        </div>
        <div
          className="my_page_content_avatar_option_area"
          onClick={() =>
            setMainAvatar({
              src: user.avatar_2,
              type: 0,
            })
          }
        >
          <img src={user.avatar_2} />
        </div>
        <div
          className="my_page_content_avatar_option_area"
          onClick={() => setMainAvatar({ src: user.video, type: 1 })}
        >
          {user.video ? (
            <video src={user.video || DefaultAvatar}></video>
          ) : (
            <img src={DefaultAvatar} alt="" />
          )}
        </div>
      </div>
    </div>
  );
};
