import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { Button, Card, Col, Container, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';

import IcBack from 'assets/icons/ic_back.svg';
import useLayout from 'hooks/use-layout';
import { useStores } from 'hooks/use-stores';
import { ChatPointPackage } from 'modules/user/models';
import { get, post } from 'services';
import LayoutStore from 'stores/layout';
import SessionStore from 'stores/session';
import UIStore from 'stores/ui';
import { formatCurrency, toAwait } from 'utils';

import './style.scss';

export const ChatPointPackageScreen = observer(() => {
  const history = useHistory();

  const uiStore: UIStore = useStores().uiStore;
  const sessionStore: SessionStore = useStores().sessionStore;
  const layoutStore: LayoutStore = useStores().layoutStore;

  useLayout();

  useEffect(() => {
    getPackageList();
    getProfileData();
  }, []);

  const [packageList, setPackageList] = useState<Array<ChatPointPackage>>([]);

  const getPackageList = async () => {
    const loading = uiStore.showLoading();
    let [res, err] = await toAwait(get('points/packages', {}));
    uiStore.hideLoading(loading);
    if (err) {
      console.log('error get chat point package list', err);
    } else {
      setPackageList(res.packages);
    }
  };

  const getProfileData = async () => {
    const loading = uiStore.showLoading();
    let [profile, err] = await toAwait(get('profile', []));
    uiStore.hideLoading(loading);
    if (err) {
      console.log('error get my profile', err);
      return;
    } else {
      sessionStore.updateProfile(profile);
    }
  };

  const purchasePackage = async (chatPointPackage: ChatPointPackage) => {
    const loading = uiStore.showLoading();
    const [res, err] = await toAwait(
      post('points/purchase', {
        package_id: chatPointPackage.id,
      })
    );
    uiStore.hideLoading(loading);
    if (err) {
      const { message } = err;
      let errMsg = message;
      uiStore.showAlert({
        type: 'error',
        message: errMsg,
        btnText: '閉じる',
      });
      return;
    }
    if (res.redirect_url) {
      window.location.href = res.redirect_url;
    }
  };

  return (
    <>
      <div className="header-with-back">
        <button onClick={() => history.goBack()}>
          <img src={IcBack} alt="" />
        </button>
      </div>

      <Helmet>
        <title></title>
      </Helmet>

      <Container className="chat-point-packages">
        <div className="chat-point-packages-top">
          <h3 className="text-center">現在の保有ポイント数</h3>
          <h1 className="text-center">{sessionStore.session?.point}P</h1>
        </div>
        <Row className="g-0 justify-between chat-point-packages-first">
          <Col lg={4}>
            <Card>
              <Card.Header className="text-center">
                送信可能数：{packageList[0]?.number_messages}件
              </Card.Header>
              <Card.Body className="flex flex-column justify-center">
                <div className="chat-point-packages-list">
                  <div className="chat-point-packages--item">
                    <div className="price justify-between">
                      <div>
                        <span>
                          {packageList[0]?.points}P: {formatCurrency(packageList[0]?.price) || '0'}
                          円
                        </span>
                      </div>
                    </div>
                    <div className="text-center">
                      <Button
                        variant="base"
                        className="white"
                        onClick={() => purchasePackage(packageList[0])}
                      >
                        購入する
                      </Button>
                      <div style={{ fontSize: '12px', marginTop: '10px' }}>
                        サービス利用料金の返金は、
                        <br />
                        サービスの性質上いかなる場合も行いません。
                      </div>
                    </div>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={4}>
            <Card>
              <Card.Header className="text-center">
                送信可能数：{packageList[1]?.number_messages}件
              </Card.Header>
              <Card.Body className="flex flex-column justify-center">
                <div className="chat-point-packages-list">
                  <div className="chat-point-packages--item">
                    <div className="price justify-between">
                      <div>
                        <span>
                          {packageList[1]?.points}P: {formatCurrency(packageList[1]?.price) || '0'}
                          円
                        </span>
                      </div>
                    </div>
                    <div className="text-center">
                      <Button
                        variant="base"
                        className="white"
                        onClick={() => purchasePackage(packageList[1])}
                      >
                        購入する
                      </Button>
                      <div style={{ fontSize: '12px', marginTop: '10px' }}>
                        サービス利用料金の返金は、
                        <br />
                        サービスの性質上いかなる場合も行いません。
                      </div>
                    </div>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={4}>
            <Card>
              <Card.Header className="text-center">
                送信可能数：{packageList[2]?.number_messages}件
              </Card.Header>
              <Card.Body className="flex flex-column justify-center">
                <div className="chat-point-packages-list">
                  <div className="chat-point-packages--item">
                    <div className="price justify-between">
                      <div>
                        <span>
                          {packageList[2]?.points}P: {formatCurrency(packageList[2]?.price) || '0'}
                          円
                        </span>
                      </div>
                    </div>
                    <div className="text-center">
                      <Button
                        variant="base"
                        className="white"
                        onClick={() => purchasePackage(packageList[2])}
                      >
                        購入する
                      </Button>
                      <div style={{ fontSize: '12px', marginTop: '10px' }}>
                        サービス利用料金の返金は、
                        <br />
                        サービスの性質上いかなる場合も行いません。
                      </div>
                    </div>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
});
