import { observer } from 'mobx-react';

import { Popup } from 'components/Popup';
import { useStores } from 'hooks/use-stores';
import UIStore from 'stores/ui';

import './styles.scss';

export const ConfirmModal = observer(() => {
  const uiStore: UIStore = useStores().uiStore;

  const onConfirm = () => {
    uiStore.confirmResult(true);
    uiStore.closeConfirmModal();
  };

  const onConfirm2 = () => {
    uiStore.confirmResult({ confirm2: true });
    uiStore.closeConfirmModal();
  };

  const onCancel = () => {
    uiStore.confirmResult(false);
    uiStore.closeConfirmModal();
  };

  const hasLongText = () => {
    if (uiStore.confirmModalData.confirmText) {
      return uiStore.confirmModalData.confirmText.length > 5;
    }
    if (uiStore.confirmModalData.cancelText) {
      return uiStore.confirmModalData.cancelText.length > 5;
    }
    return false;
  };

  return (
    <>
      {uiStore.isShowModalConfirm && (
        <Popup
          title={uiStore.confirmModalData.title}
          content={uiStore.confirmModalData.content}
          contentComponent={uiStore.confirmModalData.contentComponent}
          ButtonComponent={
            <div
              className={
                'button_confirm ' +
                (uiStore.confirmModalData.confirmText2 ? 'button_confirm_flex_col' : '')
              }
            >
              <div className="confirm_container">
                {uiStore.confirmModalData.confirmText && (
                  <button
                    className={`confirm ${hasLongText() ? 'longtext' : ''}`}
                    onClick={onConfirm}
                  >
                    {uiStore.confirmModalData.confirmText}
                  </button>
                )}
                {uiStore.confirmModalData.confirmText2 && (
                  <button
                    className={`confirm ${hasLongText() ? 'longtext' : ''}`}
                    onClick={onConfirm2}
                  >
                    {uiStore.confirmModalData.confirmText2}
                  </button>
                )}
              </div>
              <div className="cancel_container">
                {uiStore.confirmModalData.cancelText && (
                  <button
                    className={`cancel ${hasLongText() ? 'longtext' : ''}`}
                    onClick={onCancel}
                  >
                    {uiStore.confirmModalData.cancelText}
                  </button>
                )}
              </div>
            </div>
          }
          onClose={onCancel}
        />
      )}
    </>
  );
});
