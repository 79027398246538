import parse from 'html-react-parser';
import { observer } from 'mobx-react';

import { useStores } from 'hooks/use-stores';
import UIStore from 'stores/ui';

import styles from './styles.module.scss';

const InAppMessage = observer(() => {
  const uiStore: UIStore = useStores().uiStore;
  const { title, content, imgSrc } = uiStore.InAppMessage;

  const closeMessageHandler = (_event: any) => {
    uiStore.closeInAppMessage();
  };

  const contentClickHandler = (event: any) => {
    event.stopPropagation();
  };

  return (
    <>
      {uiStore.InAppMessage.isShow && (
        <div className={styles.backdrop} onClick={closeMessageHandler}>
          <div className={styles.content} onClick={contentClickHandler}>
            <button onClick={() => uiStore.closeInAppMessage()}>
              <p>X</p>
            </button>
            <div className={styles.contentContainer}>
              {imgSrc !== '' ? <img src={imgSrc} alt="" loading="lazy" /> : ''}
              <h2>{title}</h2>
              <div className={styles.contentText}>{parse(content)}</div>
              <p className={styles.signature}>Desire事務局</p>
            </div>
          </div>
        </div>
      )}
    </>
  );
});

export default InAppMessage;
