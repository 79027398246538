import { useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { NavLink, useHistory, useLocation } from 'react-router-dom';

import { SocialProvider, UserRole } from 'config/constants';
import { useStores } from 'hooks/use-stores';
import { FacebookLoginBtn } from 'modules/user/components/FacebookLoginBtn';
import { LineLoginBtn } from 'modules/user/components/LineLoginBtn';
import { SnsUserData } from 'modules/user/models/UserProfile';
import SessionStore from 'stores/session';
import UIStore from 'stores/ui';
import { toAwait } from 'utils';

import './styles.scss';

export const RegisterSnsScreen = () => {
  const location: any = useLocation();
  let role: UserRole = location.state.role;
  const history = useHistory();

  const uiStore: UIStore = useStores().uiStore;
  const sessionStore: SessionStore = useStores().sessionStore;

  useEffect(() => {
    if (sessionStore) {
      sessionStore.setRegisterProcess('registSns', role);
    }
  }, [sessionStore]);

  const goToRegistScreen = () => {
    let path = role === UserRole.Mama ? '/register/mama' : '/register/provider';
    history.push(path);
  };

  const handleLoginSns = async (
    provider: SocialProvider,
    err: Error | null,
    userData?: SnsUserData
  ) => {
    if (err) {
      console.log('err: ', err);
    }
    sessionStore?.setRegisterProcess('registSns', role, provider);

    if (!userData) return;
    const loading = uiStore.showLoading();
    let [_, loginErr] = await toAwait(sessionStore!.loginBySns(provider, userData));
    uiStore.hideLoading(loading);
    if (loginErr) {
      if (loginErr.error_code !== 401) {
        return;
      }

      // not yet create user
      let path = role === UserRole.Mama ? '/register/mama' : '/register/provider';
      history.push(path, {
        snsProvider: provider,
        userData: userData,
      });
      return;
    } else {
      history.push('/search');
    }
  };

  return (
    <div className="text-center register-method">
      <Helmet>
        <title>新規会員登録</title>
      </Helmet>
      <h1>新規会員登録</h1>
      <div>
        <Button variant="base" className="btn" onClick={goToRegistScreen}>
          登録
        </Button>
      </div>
      <div>
        <FacebookLoginBtn
          textBtn="Facebookで登録"
          loginResponse={(err, userData) => handleLoginSns(SocialProvider.Facebook, err, userData)}
        />
      </div>
      <div>
        <LineLoginBtn
          textBtn="LINEで登録"
          loginResponse={(err, userData) => handleLoginSns(SocialProvider.Line, err, userData)}
        />
      </div>
      <div>
        <NavLink to="/login" className="w-100">
          既にアカウントをお持ちの方
        </NavLink>
      </div>
    </div>
  );
};
