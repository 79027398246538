import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory, useLocation } from 'react-router-dom';

import IcBack from 'assets/icons/ic_back.svg';
import DefaultAvatar from 'assets/images/img_default_avatar.svg';
import { Pagination, PaginationData } from 'components/common/pagination';
import useLayout from 'hooks/use-layout';
import { useStores } from 'hooks/use-stores';
import { MamaProfile, ProviderProfile } from 'modules/user/models';
import { get } from 'services';
import mamaService from 'services/api/mama';
import LayoutStore from 'stores/layout';
import SessionStore from 'stores/session';
import UIStore from 'stores/ui';
import { getProviderService, toAwait } from 'utils';
import { formatDateTime } from 'utils/datetime';

import '../../provider/favorite/style.scss';
import './styles.scss';

export const PointManageScreen = observer(() => {
  const history = useHistory();
  const location: any = useLocation();
  let profile: MamaProfile = location.state ? location.state.profile : {};

  const uiStore: UIStore = useStores().uiStore;
  const sessionStore: SessionStore = useStores().sessionStore;
  const layoutStore: LayoutStore = useStores().layoutStore;

  const [users, setUsers] = useState<Array<ProviderProfile>>([]);
  const [pagination, setPagination] = useState<PaginationData>({
    currentPage: 1,
    limit: 30,
    total: 0,
  });

  useLayout();

  useEffect(() => {
    getData(1);
    getProfileData();
  }, []);

  const getData = async (page: number) => {
    const loading = uiStore.showLoading();
    const [res, err] = await toAwait(get('mama/providers/met', { page: page, meet_status: 2 }));
    uiStore.hideLoading(loading);
    if (err) {
      console.log('error when list met: ', err);
    }
    setPagination({
      currentPage: page,
      limit: res.per_page,
      total: res.total,
    });
    setUsers(res.data);
  };

  const getProfileData = async () => {
    const loading = uiStore.showLoading();
    let [profile, err] = await toAwait(mamaService.getMyProfile());
    uiStore.hideLoading(loading);
    if (err) {
      console.log('error get my profile', err);
      return;
    } else {
      sessionStore.updateProfile(profile);
    }
  };

  const goToProfile = (user: ProviderProfile) => {
    history.push(`/search/${user.id}`, {
      user: user,
      isMama: true,
    });
  };

  return (
    <div id="my_favorite" className="mama-point-screen">
      <Helmet>
        <title>保有ポイント数</title>
      </Helmet>

      <div className="header-with-back">
        <button onClick={() => history.goBack()}>
          <img src={IcBack} alt="" />
        </button>

        <div className="title">保有ポイント数</div>
      </div>

      <div className="list_favorite point-header">
        <div className="text-center title">
          {new Intl.NumberFormat().format(
            (sessionStore?.session as MamaProfile)?.point || profile.point || 0
          )}{' '}
          pt
        </div>
        <div className="text-center">
          <button onClick={() => history.push('/mama/point-transfer')} className="btn_transfer">
            振込申請
          </button>
          <button onClick={() => history.push('/mama/match-history')} className="btn_history">
            会った人リスト
          </button>
        </div>
        <div className="text-center sub-title">
          振込情報に銀行情報を登録すると翌月よりご登録の銀行宛に1pt＝1円にて換金の上、お振込をいたします。お振込は保有ポイントが10,000pt以上から対応いたします。
        </div>
      </div>

      {layoutStore.isShowHeader && (
        <div className="header_back point">
          <div className="title">ポイント取得履歴</div>
        </div>
      )}

      <div className="list_favorite">
        {users.map((user) => (
          <div key={user.id} className="favorite_item">
            <div
              className="favorite_item_detail user_avatar has-gift"
              onClick={() => goToProfile(user)}
              style={{ cursor: 'pointer' }}
            >
              <img src={user.avatar || DefaultAvatar} />
            </div>
            <div className="favorite_item_detail user_info">
              <div className="user_info_detail user_status">
                {user.member_services?.includes(1) && (
                  <span className="user_status_item btn-color-red">{getProviderService(1)}</span>
                )}
                {user.member_services?.includes(2) && (
                  <span className="user_status_item btn-color-yellow">{getProviderService(2)}</span>
                )}
                {user.member_services?.includes(3) && (
                  <span className="user_status_item btn-color-purple">{getProviderService(3)}</span>
                )}
                <span className="user_name">&nbsp;</span>
              </div>
              <div className="user_info_detail user_status">
                <span className="user_name">{user.nick_name}</span>
              </div>
              <div className="user_info_detail user_status">
                <span className="confirm_meet_at">
                  {formatDateTime((user as any).confirm_meet_at || new Date())}
                </span>
              </div>
            </div>
            <div className="flex items-end point">500pt</div>
          </div>
        ))}
      </div>

      <Pagination pagination={pagination} onClickPage={(page) => getData(page)} />
    </div>
  );
});
