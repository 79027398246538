import { Helmet } from 'react-helmet';

import './style.css';

export const PolicyScreen = () => {
  return (
    <div className="col-md-12 col-sm-12 col-xs-12 page-footer">
      <Helmet>
        <title>プライバシーポリシー</title>
      </Helmet>
      <h3>プライバシーポリシー</h3>
      <div className="content">
        当社（株式会社　侍）は個人情報保護の重要性について配慮し、個人情報の保護に関する法律（個人情報保護法）を厳守すると共に
        以下のプライバシーポリシーに従い、適切な取り扱い及び保護に努めます。なお、本プライバシーポリシーにおいて特別な定めが
        無い限り、本プライバシーポリシーにおける用語の定義は、個人情報保護法の定めに従います。
        <br />
        <br />
        <b>1，個人情報とは</b>
        <br />
        <br />
        本プライバシーポリシーにおいて、個人情報とは、個人情報保護法第２条第1項により定義される個人情報を意味するものとします。
        <br />
        <br />
        <b>２，個人情報の利用目的</b>
        <br />
        <br />
        1.当社の商品及びサービス（以下、総称して当社サービス）の提供の為
        <br />
        2.当社サービスに関する案内、お問い合わせ等への対応の為
        <br />
        3.当社サービスに関する当社の規約、ポリシー等（以下、規約等）に違反する行為に対する対応の為
        <br />
        4.当社サービスに関する規約等の変更などを通知する為
        <br />
        5.当社サービスの改善、修正、新機能の開発に役立てる為
        <br />
        6.当社サービスに関連して、個別を判別出来ない形式に加工した統計データ作成
        <br />
        7.その他、上記利用目的に付随する目的の為
        <br />
        <br />
        <b>3，個人情報利用目的の変更</b>
        <br />
        <br />
        当社は、個人情報の利用目的を関連性を有すると合理的に認められる範囲内において変更することがあり
        変更した場合には個人情報の主体である個人に通知しまたは公表します。
        <br />
        <br />
        <b>4，個人情報利用の制限</b>
        <br />
        <br />
        当社は、個人情報保護法その他の法令により許容される場合を除き、お客様の同意得ず利用目的の達成に必要な範囲を超えて
        個人情報を取り扱いません。
        <br />
        ただし、次の場合はこの限りではありません。
        <br />
        <br />
        1.法令に基づく場合
        <br />
        2.人の生命、身体または財産保護の為に必要がある場合であって、本人の同意を得ることが困難であるとき
        <br />
        3.国の機関もしくは地方公共団体又は、その委託を受けた者が法令の定める事務を遂行するする事に対して、協力する必要がある場合であって
        本人の同意を得ることにより当該事務の遂行に支障を及ぼす恐れがあるとき
        <br />
        <br />
        <br />
        <b>５．個人情報の適正な習得</b>
        <br />
        <br />
        当社は、適正に個人情報を取得し偽りその他不正の手段により取得しません。
        <br />
        <br />
        <br />
        <b>６．個人情報の安全管理</b>
        <br />
        <br />
        当社は個人情報の紛失、破壊、改ざん及び漏洩などのリスクに対して、個人情報の安全管理が図られるよう、当社の従業員に対し
        必要かつ適切な監督を行います。当社は、個人情報の取り扱いの全部又は一部を委託する場合は、委託先において個人情報の
        安全管理が図られるよう、必要かつ適切な監督を行います。
        <br />
        <br />
        <b>７．第三者提供</b>
        <br />
        <br />
        当社は、個人情報保護法その他の法令に基づき開示認められる場合を除くか、あらかじめお客様の同意を得ないで、個人情報を
        第三者に提供しません。
        <br />
        但し、次に掲げる場合は上記に定める第三者への提供には該当しません。
        <br />
        <br />
        1.利用目的の達成に必要な範囲内において個人情報の取り扱いの全部または一部を委託することに伴って個人情報を提供する場合
        <br />
        2.合併その他の事由による事業の継承に伴って個人情報が提供される場合
        <br />
        3.第８項の定めに従って共同利用する場合
        <br />
        <br />
        <br />
        <b>８．個人情報の開示</b>
        <br />
        <br />
        当社は、お客様から、個人情報保護法の定めに基づき個人情報の開示を求められた時は、お客様本人からの請求である事を確認の上で
        お客様に対し、延滞無く開示を行います（当該個人情報が存在しないときにはその旨を通知いたします）
        <br />
        但し、個人情報保護法その他の法令により、当社が開示の義務を負わない場合には、この限りではありません。
        <br />
        <br />
        <b>９．個人情報の訂正等</b>
        <br />
        <br />
        当社はお客様から、個人情報が真実でないという理由によって、個人情報保護法の定めに基づきその内容の訂正、追加または削除
        （以下、訂正）を求められた場合には、お客様ご本人からの請求であることを確認の上で、利用目的の範囲内において、延滞無く必要な
        調査を行い、その旨をお客様に通知します。（訂正等を行わない旨の決定をしたときは、お客様に対し、その旨を通知いたします）
        <br />
        但し、個人情報保護法その他の法令により、当社が訂正等の義務を負わない場合には、この限りではありません。
        <br />
        <br />
        <b>１０．個人情報の利用停止等</b>
        <br />
        <br />
        当社は、お客様から、お客様の個人情報が、あらかじめ公表された利用目的の範囲を超えて取り扱われているという理由又は
        その他不正の手段により取得されたものであるという理由により、個人情報保護法の定めに基づきその利用停止又は消去
        （以下、利用停止）を求められた場合において、そのご請求に理由があることが判明した場合には、お客様ご本人からの
        ご請求であることを確認の上で、延滞無く個人情報の利用停止等を行い、その旨をお客様に通知いたします。
        <br />
        但し、個人情報その他の法令により、当社が利用停止等の義務を負わない場合には、この限りではありません。
        <br />
        <br />
        <b>１１．Cookie(クッキー）その他技術の利用</b>
        <br />
        <br />
        当社のサービスは、クッキー及びこれに類する技術を利用することがあります。これらの技術は当社による当社のサービス
        の利用状況等の把握に役立ち、サービス向上に資するものです。
        <br />
        クッキーを無効化されたユーザーは、ウェヴブラウザの設定を変更することによりクッキーを無効化する事が出来ます。
        <br />
        但し、クッキーを無効化すると、当社のサービスの一部を利用できなくなります。
        <br />
        <br />
        <b>１２．お問い合わせ</b>
        <br />
        <br />
        開示等の申し出、ご意見、ご質問、苦情のお申し出その他個人情報の取り扱いに関するお問い合わせは下記窓口までお願いします。
        <br />
        Desireサポート
        <br />
        e-mail：info@desire777.com
        <br />
        <br />
        <br />
        <b>13．継続改善</b>
        <br />
        <br />
        当社は、個人情報の取り扱いに関する運用状況を適宣見直し、継続的な改善に努めるものとし、必要に応じて本プライバシーポリシー
        を変更することがあります。
      </div>
    </div>
  );
};
