import { useHistory } from 'react-router';

import IMG7 from 'assets/images/Vector01.svg';
import IMG8 from 'assets/images/Vector02.svg';
import IMG9 from 'assets/images/Vector03.svg';
import IMG10 from 'assets/images/Vector04.svg';
import IMG11 from 'assets/images/Vector05.svg';
import IMG1 from 'assets/images/img_1.jpeg';
import IMG2 from 'assets/images/img_2.jpeg';
import IMG3 from 'assets/images/img_3.jpeg';
import IMG4 from 'assets/images/img_4.jpeg';
import IMG5 from 'assets/images/img_5.jpeg';
import IMG6 from 'assets/images/img_6.jpeg';

import './style.css';

export function HomeScreen() {
  const history = useHistory();

  return (
    <div className="col-md-12 col-sm-12 col-xs-12">
      <div>
        <div className="image_content image_content_pc">
          <div className="box_content_top">
            <div className="text_first">
              <p className="hightlineText">
                「年下男性<span className="normalText">と結婚</span>
                <span className="hightlineText">」</span>
              </p>
            </div>
            <div className="text_second">叶えます。</div>
          </div>

          <div className="box_content">
            <div className="text_content__div1">
              好みのジャンルとつながる
              <br />
              メッセージ送り放題
              <br />
              公式ママ活・姉活・婚活サイト
            </div>
            {/* <div className="text_content__div2">
              Desireでママ活や姉活を始め、あなた好みの方と出会おう
            </div> */}
          </div>
        </div>
        <div className="image_content image_content_sp">
          <div className="box_content_top">
            <p className="hightlineText">
              年下男性と結婚
              <span className="normalText">、叶えます。</span>
            </p>
          </div>

          <div className="box_content">
            <div className="text_content__div1">
              好みのジャンルとつながる
              <br />
              メッセージ送り放題
              <br />
              公式ママ活・姉活・婚活サイト
            </div>
            {/* <div className="text_content__div2">
              Desireでママ活や姉活を始め、あなた好みの方と出会おう
            </div> */}
          </div>
        </div>{' '}
        <div className="session_home_intro">
          {/* <div className="home_content_intro__part1 text-wrap text-center">
            　18 歳以上の方のみ利用可能
            <br />
            １分で完了！かんたん無料登録
          </div>
          <div className="home_content_intro__part2">
            <div role="button" onClick={() => history.push('/register')}>
            新規会員登録はコチラ
            </div>
            <div role="button" onClick={() => history.push('/login')}>
            既に会員の方はコチラ
            </div>
          </div> */}

          <div className="box-content-mb-only">
            <p>公式ママ活・姉活・婚活サイト</p>
          </div>

          <div className="home_content_intro__part3">
            <p>
              『ママ注目』
              <br />
              最高のサービスを提供してくれる、
              <br />
              メンズワーカ、レズビアンワーカ様を確認した
              <br />
              い方は下のボタンより確認する事が可能です
            </p>
            <div role="button" onClick={() => history.push('/provider-check')}>
              活躍中のワーカーはコチラ
            </div>
          </div>
          <div className="home_content_intro__part4">Desireとは？</div>
          <div className="home_content_intro__part5">
            <p>
              Desireは、年上女性と年下男性の特別な関係を創り出せるマッチングサイトです。普段ではないような出会いに巡り合えます。
            </p>
            <button onClick={() => history.push('/mama-policies')}>
              <p>ママ登録も安心!!</p>
            </button>
          </div>
          <div>
            <div className="text-center">
              <div className="intro_item col-sm-12">
                <div className="d-flex flex-column align-items-center">
                  <img src={IMG3} className="image_intro_item" alt="" />
                  <div className="title_intro_item">お相手を探す</div>
                  <div className="text_intro_item text_intro_item_pc">
                    あなたの好みのお相手を
                    <br />
                    検索枠からさがして
                    <br />
                    見つけ出そう。
                  </div>
                  <div className="text_intro_item text_intro_item_sp">
                    あなたの好みのお相手を
                    <br />
                    検索枠から探して見つけ出そう。
                  </div>
                </div>
              </div>
              <div className="intro_item col-sm-12">
                <div className="d-flex flex-column align-items-center">
                  <img src={IMG2} className="image_intro_item" alt="" />
                  <div className="title_intro_item">メッセージでアピール</div>
                  <div className="text_intro_item text_intro_item_pc">
                    メッセージは送り放題なので
                    <br />
                    好みのお相手が見つかったら
                    <br />
                    どんどんアピールしましょう。
                  </div>
                  <div className="text_intro_item text_intro_item_sp">
                    メッセージは送り放題なので好みのお相手が
                    <br />
                    見つかったらどんどんアピールしましょう。
                  </div>
                </div>
              </div>
              <div className="intro_item col-sm-12">
                <div className="d-flex flex-column align-items-center">
                  <img src={IMG1} className="image_intro_item" alt="" />
                  <div className="title_intro_item">会う約束</div>
                  <div className="text_intro_item text_intro_item_pc">
                    返事が来たら、会話をおたのしみ
                    <br />
                    相手を知ろう。
                    <br />
                    相性がよかったら会うお約束も。
                  </div>
                  <div className="text_intro_item text_intro_item_sp">
                    返事が来たら、会話を楽しみ相手を知ろう。
                    <br />
                    相性が良かったら会うお約束も。
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>{' '}
        <div className="session_home_post">
          <div className="session_home_post__title">
            Desireでは多くの女性が
            <br />
            自由な関係を楽しんでいます
          </div>
          <div className="session_home_post__item">
            <div className="text-center bg-trans">
              <div className="post_item bg-trans">
                <div className="d-flex flex-column align-items-center bg-trans">
                  <div>
                    <img src={IMG4} className="image_post_item" alt="" />
                  </div>
                  <div className="title_post_item bg-trans">
                    スペシャルオーダーがすごいですね
                    <br />
                    平日昼間のデートですぺスペシャル<br />オーダーを利用したら40人ぐらい方<br />からメッセージいただきました。​
                    <br />
                  </div>
                  <div className="text_post_item bg-trans">J.W　32歳　会社経営​</div>
                  <div className="title_post_item bg-trans">目的や日時指定で条件に合う相手の<br />中から​好みの相手を選ぶだけ！​​</div>
                </div>
              </div>
              <div className="post_item bg-trans">
                <div className="d-flex flex-column align-items-center bg-trans">
                  <div>
                    <img src={IMG5} className="image_post_item" alt="" />
                  </div>
                  <div className="title_post_item bg-trans">
                    定期的に会える男性が
                    <br className="title_post_item_sp" />
                    見つかりました。
                  </div>
                  <div className="text_post_item bg-trans">R.Y　42歳　OL</div>
                </div>
              </div>
              <div className="post_item bg-trans">
                <div className="d-flex flex-column align-items-center bg-trans">
                  <div>
                    <img src={IMG6} className="image_post_item" alt="" />
                  </div>
                  <div className="title_post_item bg-trans">
                    バツイチの私も好みの​
                    <br />
                    年下男性男性がみつかりました。
                    <br />
                  </div>
                  <div className="text_post_item bg-trans">M.Y　29歳　主婦</div>
                  <div className="title_post_item bg-trans">
                    登録者数25,000人突破！！
                    <br />
                    ​だから必ず好みのお相手が
                    <br />
                    見つかります！！
                  ​</div>
                </div>
              </div>
            </div>
          </div>
          <div className="home_content_intro__part2">
            <div role="button" onClick={() => history.push('/register')}>
              今すぐ始めよう
            </div>
          </div>
          <div className="home_content_intro__part3 bg-trans">
            すでにアカウント登録済みの方は <a href="/login">こちら</a>
          </div>
        </div>{' '}
        <div className="session_home_service">
          <div className="title_home_service">
            安心・安全に使える
            <br />
            Desireの運営サポート
          </div>
          <div className="line"></div>
          <div>
            <div className="service_item">
              <div className="d-flex">
                <div>
                  <img src={IMG7} className="image_service_item" alt="" />
                </div>
                <div className="content_service_item">
                  安心・安全のために
                  <div className="content_service_item_small_txt">
                    {' '}
                    ママ活を安心・安全にご利用頂くために、様々な取り組みを行っています。
                  </div>
                </div>
              </div>
            </div>
            <div className="service_item">
              <div className="d-flex">
                <div>
                  <img src={IMG8} className="image_service_item" alt="" />
                </div>
                <div className="content_service_item">
                  ニックネーム登録制
                  <div className="content_service_item_small_txt">
                    プロフィールはニックネームを利用するため実名の登録は必要ありません。
                  </div>
                </div>
              </div>
            </div>
            <div className="service_item">
              <div className="d-flex">
                <div>
                  <img src={IMG9} className="image_service_item" alt="" />
                </div>
                <div className="content_service_item">
                  年齢確認
                  <div className="content_service_item_small_txt">
                    Desireでお相手の秘密のプロフィールを見るには、公的身分証明書での年齢確認が必要です。
                  </div>
                </div>
              </div>
            </div>
            <div className="service_item">
              <div className="d-flex">
                <div>
                  <img src={IMG10} className="image_service_item" alt="" />
                </div>
                <div className="content_service_item">
                  24時間365日の監視体制
                  <div className="content_service_item_small_txt">
                    安全にご利用頂くために悪質なユーザーがいないか常時チェックし ています。
                  </div>
                </div>
              </div>
            </div>
            <div className="service_item">
              <div className="d-flex">
                <div>
                  <img src={IMG11} className="image_service_item" alt="" />
                </div>
                <div className="content_service_item">
                  個人情報の保護
                  <div className="content_service_item_small_txt">
                    個人情報の適切な管理に努めています。
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>{' '}
        <div className="session_contact_post">
          <div className="title bg-trans">Desire</div>
          <div className="text_ bg-trans">あなたにあったいい出会いを。</div>
          <div className="comp_button_red">
            <div className="home_content_intro__part2">
              <div role="button" onClick={() => history.push('/register')}>
                今すぐ始めよう
              </div>
            </div>
            <div className="home_content_intro__part3 bg-trans">
              すでにアカウント登録済みの方は <a href="/login">こちら</a>
            </div>
          </div>
        </div>{' '}
      </div>
    </div>
  );
}
