import styles from './styles.module.scss';

const ImageView = ({ imageUrl, onClose }: { imageUrl: string; onClose: () => void }) => {
  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
        zIndex: 99999,
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
          position: 'relative',
        }}
      >
        <img src={imageUrl} alt="" className={styles.previewImg} />
        <div
          style={{ position: 'absolute', top: 20, right: 20, cursor: 'pointer' }}
          onClick={onClose}
        >
          <div className={styles.cross} />
        </div>
      </div>
    </div>
  );
};

export default ImageView;
