import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory, useLocation } from 'react-router-dom';

import imgMama from 'assets/images/register_img_1.jpeg';
import imgProvider from 'assets/images/register_img_2.png';
import { UserRole } from 'config/constants';
import { useStores } from 'hooks/use-stores';
import SessionStore from 'stores/session';

import './styles.scss';

export const RegisterTypeScreen = () => {
  const history = useHistory();
  const location: any = useLocation();
  let params: any = {};
  if (location.state) {
    params.snsProvider = location.state.snsProvider;
    params.userData = location.state.userData;
    console.log('params register: ', params);
  }

  const sessionStore: SessionStore = useStores().sessionStore;

  useEffect(() => {
    if (sessionStore && sessionStore.session) {
      if (sessionStore.session.token) {
        history.push('/search');
      }
    }
  }, [sessionStore]);

  const goToRegistScreen = (role: UserRole) => {
    sessionStore?.setRegisterProcess('registRole', role);
    if (!params.snsProvider) {
      history.push('/register/sns', { role: role });
    } else {
      let path = role === UserRole.Mama ? '/register/mama' : '/register/provider';
      history.push(path, params);
    }
  };

  return (
    <div className="cbx">
      <Helmet>
        <title>新規会員登録</title>
      </Helmet>
      <div className="session_register">
        <div className="title_register">新規会員登録</div>
        <button onClick={() => history.push('/mama-policies')}>
          <p>ママ登録も安心!!</p>
        </button>
        <div className="text_register">どちらの会員として新規登録をされますか？</div>
        <div className="user_group_option">
          <div className="justify-content-center user_group_option_box">
            <div
              className="nav-link image_register"
              style={{ padding: 0 }}
              onClick={() => goToRegistScreen(UserRole.Mama)}
            >
              <img src={imgMama}></img>
              <div className="content_image_register">
                <div className="text_image_register">ママとして登録</div>
              </div>
            </div>
            <div
              className="nav-link image_register"
              style={{ padding: 0 }}
              onClick={() => goToRegistScreen(UserRole.Provider)}
            >
              <img src={imgProvider}></img>
              <div className="content_image_register">
                <div className="text_image_register">
                  <br />
                  サービス提供者として登録
                </div>
                <div className="text_image_register">
                  女性もレズビアンのサービス提供者としてご登録いただけます
                </div>
              </div>
            </div>
          </div>
          <div className="nav-link" style={{ padding: 0 }} onClick={() => history.push('/login')}>
            <div className="link_register">既にアカウントをお持ちの方</div>
          </div>
        </div>
      </div>
    </div>
  );
};
