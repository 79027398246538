import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import { Container } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import { UserRole } from 'config/constants';
import { useStores } from 'hooks/use-stores';
import { News } from 'modules/news/models';
import { get } from 'services';
import NotificationStore from 'stores/notification';
import SessionStore from 'stores/session';
import UIStore from 'stores/ui';
import { toAwait } from 'utils';
import { formatDate } from 'utils/datetime';

import './styles.scss';

export const NotifyHeader = observer(() => {
  const history = useHistory();

  const notificationStore: NotificationStore = useStores().notificationStore;
  const sessionStore: SessionStore = useStores().sessionStore;
  const uiStore: UIStore = useStores().uiStore;
  const lastNews = notificationStore.lastNews;

  const handleClickNews = async (item: News) => {
    if (sessionStore.session && item.user_news_id) {
      const url =
        sessionStore.session.role === UserRole.Mama
          ? 'mama/notifications/'
          : 'provider/notifications/';
      const loading = uiStore.showLoading();
      await toAwait(get(url + item.user_news_id, {}));
      notificationStore.getUnreadCount();
      uiStore.hideLoading(loading);
    }

    if (!item.name) item.name = item.title;
    history.push('/news-detail', { data: item });
  };

  return (
    <div className="flex items-center header-sub" style={lastNews ? {} : { height: '3px' }}>
      <Container>
        {lastNews ? (
          <div
            className="flex justify-start"
            onClick={() => handleClickNews(toJS(lastNews))}
            style={{ cursor: 'pointer' }}
          >
            <span style={{ marginRight: '1em' }}>{formatDate(lastNews.created_at)}</span>
            <span className="head-text-sp">{lastNews.name || lastNews.title}</span>
            <span className="head-text-pc">{lastNews.name || lastNews.title}</span>
          </div>
        ) : null}
      </Container>
    </div>
  );
});
