import { computed, makeAutoObservable, observable, runInAction } from 'mobx';
import { toast } from 'react-toastify';

import { UserRole } from 'config/constants';
import { News } from 'modules/news/models';
import { get } from 'services';
import { detachUnreadListeners, listenerUnreadMessage } from 'services/firebase';
import { toAwait } from 'utils';

class NotificationStore {
  @observable messageCount: number = 0;
  @observable orderCount: number = 0;
  @observable orderMessageCount: number = 0;
  @observable newsCount: number = 0;
  @observable lastNews: News | null = null;
  intervalFetchNotify: any;
  intervalFetchLastNew: any;
  isNotify: boolean = true;
  userRole: UserRole = UserRole.Provider;
  rootHistory: any;
  isLogout: boolean = false;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  @computed get specialCount() {
    return this.orderCount + this.orderMessageCount;
  }

  startFetchNotify = async (userId: number, userRole: UserRole) => {
    this.userRole = userRole;
    this.isLogout = false;

    if (!this.intervalFetchNotify) {
      const error = await this.getUnreadCount();
      if (error) throw error;
      this.intervalFetchNotify = setInterval(() => {
        this.getUnreadCount();
      }, 30000);
    }

    listenerUnreadMessage(userId, userRole, ([messageCount, specialCount]: [number, number]) => {
      runInAction(() => {
        this.messageCount = messageCount;
        this.orderMessageCount = specialCount;
      });
    });
  };

  clearFetchNotify = () => {
    if (this.intervalFetchNotify) clearInterval(this.intervalFetchNotify);
    this.intervalFetchNotify = null;
    this.isLogout = true;
    detachUnreadListeners();
    this.startFetchLastNews();
  };

  startFetchLastNews = () => {
    if (!this.intervalFetchLastNew) {
      this.getLastNews();
      this.intervalFetchLastNew = setInterval(() => {
        this.getLastNews();
      }, 30000);
    }
  };

  clearFetchLastNews = () => {
    if (this.intervalFetchLastNew) clearInterval(this.intervalFetchLastNew);
    this.intervalFetchLastNew = null;
  };

  getUnreadCount = async () => {
    let [res, err] = await toAwait(get('views/unread', {}));
    if (err) {
      return err;
    } else if (res) {
      if (res.special_count && res.special_count > this.orderCount) {
        this.showNotify('new_special');
      }
      if (res.news_count && res.news_count > this.newsCount) {
        this.showNotify('new_news');
      }
      runInAction(() => {
        if (res.special_count || res.special_count === 0) this.orderCount = res.special_count;
        if (res.news_count || res.news_count === 0) this.newsCount = res.news_count;
      });
      return null;
    }
  };

  getLastNews = async () => {
    const [res, err] = await toAwait(get('notifications', {}));
    if (err) {
      console.log('cannot get last news : ', err);
    } else if (res) {
      runInAction(() => {
        if (res.data && res.data[0]) this.lastNews = res.data[0];
      });
    }
  };

  settingNotify = (status: boolean) => {
    this.isNotify = status;
  };

  settingHistory = (history: any) => {
    this.rootHistory = history;
  };

  showNotify = (type: string) => {
    if (this.isLogout || !this.isNotify) return;

    let message = '';
    let path = '';
    if (type == 'new_message') {
      message = '新着メッセージがあります！';
      path = '/messages';
    }
    if (type == 'new_special') {
      message = '新着スペシャルオーダーがあります！';
      path = this.userRole === UserRole.Provider ? '/provider/special' : '/mama/special';
    }
    if (type == 'new_news') {
      message = '新着お知らせがあります！';
      path = '/news';
    }

    toast(message, {
      position: 'top-right',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
      onClick: () => {
        this.rootHistory.push(path);
      },
    });
  };
}

export default NotificationStore;
