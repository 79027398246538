import { Helmet } from 'react-helmet';

import './style.css';

export const CompanyScreen = () => {
  return (
    <div className="col-md-12 col-sm-12 col-xs-12 page-footer">
      <Helmet>
        <title>会社概要</title>
      </Helmet>
      <h3 className="company">会社概要</h3>
      <p className="content company">
        <b>社名</b>
        <br />
        <br />
        株式会社　侍
        <br />
        <br />
        <b>事業内容</b>
        <br />
        <br />
        マッチングサイト運営
        <br />
        <br />
        <b>設立</b>
        <br />
        <br />
        令和３年７月
        <br />
        <br />
        <b>所在地</b>
        <br />
        <br />
        〒532-0003　大阪市淀川区宮原1-16-35
        <br />
        <br />
        <b>連絡先</b>
        <br />
        <br />
        e-mail: info@desire777.com
        <br />
        <br />
        <b>各届け出</b>
        <br />
        <br />
        電気通信事業者　届け出番号　E-03-04766
        <br />
        <br />
        インターネット異性紹介　届け出番号　6221-0015-000
      </p>
    </div>
  );
};
