import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Pagination, PaginationData } from 'components/common/pagination';
import { UserRole } from 'config/constants';
import { useStores } from 'hooks/use-stores';
import { MamaProfile, ProviderProfile } from 'modules/user/models';
import { get } from 'services';
import mamaService from 'services/api/mama';
import providerService from 'services/api/provider';
import SessionStore from 'stores/session';
import UIStore from 'stores/ui';
import { toAwait } from 'utils';
import { Filter } from './components/Filter';
import { UserList } from './components/UserList';

import './styles.scss';

export const UserListScreen = observer(() => {
  const history = useHistory();

  const sessionStore: SessionStore = useStores().sessionStore;
  const uiStore: UIStore = useStores().uiStore;
  const isMama = sessionStore.session
    ? sessionStore.session.role === UserRole.Mama
      ? true
      : false
    : null;

  const [users, setUsers] = useState<Array<MamaProfile | ProviderProfile>>([]);
  const [pagination, setPagination] = useState<PaginationData>({
    currentPage: 1,
    limit: 30,
    total: 0,
  });
  const [filter, setFilter] = useState<any>({});

  useEffect(() => {
    if (isMama !== null) {
      fetchData(1);
      updateProfileData();
    }
  }, [isMama]);

  /**
   * Check if there is a message from CMS
   */
  useEffect(() => {
    if (
      uiStore.InAppMessage.title !== '' ||
      uiStore.InAppMessage.content !== '' ||
      uiStore.InAppMessage.imgSrc !== ''
    ) {
      uiStore.showInAppMessage();
    }

    return () => {
      uiStore.closeInAppMessage();
    };
  }, []);

  useEffect(() => {
    if (sessionStore.isInited) {
      if (!sessionStore.isLogin) {
        history.replace('/');
      }
    }
  }, [history, sessionStore, sessionStore.isLogin, sessionStore.session]);

  const fetchData = async (page: number, filter = {}) => {
    const loadingId = uiStore.showLoading();
    const url = isMama ? 'mama/providers/search' : 'provider/mama/search';
    const res = await get(url, { page: page, ...filter });
    console.log('search providers res: ', res);
    if (res) {
      setPagination({
        currentPage: page,
        limit: res.per_page,
        total: res.total,
      });
      setUsers(res.data);
    }
    uiStore.hideLoading(loadingId);
  };

  const updateProfileData = async () => {
    let profile, err: any;
    if (isMama) {
      [profile, err] = await toAwait(mamaService.getMyProfile());
    } else {
      [profile, err] = await toAwait(providerService.getMyProfile());
    }
    if (err) {
      console.log('error update profile', err);
    } else {
      sessionStore.updateProfile(profile);
    }
  };

  const handleSearchClick = async (filter: any) => {
    setFilter(filter);
    await fetchData(1, filter);
  };

  return (
    <div className="mama">
      {isMama && (
        <div className="text-center flex items-center justify-center header-sub point">
          10,000pt 達成で 5,000pt 還元キャンペーン実施中
        </div>
      )}
      <Filter isMama={isMama || false} onSearch={handleSearchClick} />
      <UserList users={users} isMama={isMama || false} filter={filter} />
      <Pagination pagination={pagination} onClickPage={(page) => fetchData(page, filter)} />
    </div>
  );
});
