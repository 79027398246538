import { Button, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';

interface IConfirmEmailProps {
  onSubmitEmail: (email: string) => any;
}

export const ConfirmEmailScreen = ({ onSubmitEmail }: IConfirmEmailProps) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data: any) => {
    console.log('data: ', data);
    onSubmitEmail(data.email);
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <small className="d-block pwd-note">登録済みのメールアドレスを入力してください。</small>
      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label className="label-base required mb-3">メールアドレス</Form.Label>
        <input
          type="email"
          className="input-base"
          placeholder="メールアドレスを入力してください"
          {...register('email', { required: true, pattern: /^\S+@\S+$/i })}
        />
        {errors.email && errors.email.type === 'required' && (
          <span className="input-base" style={{ color: 'red' }}>
            メールアドレスが必須項目です。
          </span>
        )}
        {errors.email && errors.email.type === 'pattern' && (
          <span className="input-base" style={{ color: 'red' }}>
            ※メール形式が正しくありません。
          </span>
        )}
      </Form.Group>

      <div className="text-center">
        <Button variant="base" type="submit">
          認証コードを送信
        </Button>
      </div>
    </Form>
  );
};
