import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';

import AddressImg from 'assets/icons/address.png';
import IcBack from 'assets/icons/ic_back.svg';
import JobImg from 'assets/icons/job.png';
import DefaultAvatar from 'assets/images/img_default_avatar.svg';
import { Pagination, PaginationData } from 'components/common/pagination';
import useLayout from 'hooks/use-layout';
import { useStores } from 'hooks/use-stores';
import { MamaProfile } from 'modules/user/models';
import { get, post } from 'services';
import UIStore from 'stores/ui';
import { getGender, getGeneration, getPrefecture, getPurpose, toAwait } from 'utils';

import './style.scss';
import styles from './styles.module.scss';

export function ProviderFavoriteScreen() {
  const history = useHistory();

  const uiStore: UIStore = useStores().uiStore;

  const [users, setUsers] = useState<Array<MamaProfile>>([]);
  const [pagination, setPagination] = useState<PaginationData>({
    currentPage: 1,
    limit: 30,
    total: 0,
  });

  useLayout();

  useEffect(() => {
    getData(1);
  }, []);

  const getData = async (page: number) => {
    const loading = uiStore.showLoading();
    const [res, err] = await toAwait(get('provider/favorites', { page: page }));
    uiStore.hideLoading(loading);
    if (err) {
      console.log('error when list favorite: ', err);
    }
    setPagination({
      currentPage: page,
      limit: res.per_page,
      total: res.total,
    });
    setUsers(res.data);
  };

  const unFavorite = async (favorite_id: number) => {
    const loading = uiStore.showLoading();
    const [res, err] = await toAwait(post('provider/favorites/remove', { mama_id: favorite_id }));
    uiStore.hideLoading(loading);
    if (err) {
      console.log('error when unfavorite: ', err);
    }
    reloadData();
  };

  const reloadData = () => {
    getData(pagination.currentPage);
  };

  const goToProfile = (user: MamaProfile) => {
    history.push(`/search/${user.id}`, {
      user: user,
      isMama: false,
    });
  };

  return (
    <div id="my_favorite">
      <Helmet>
        <title>お気に入り一覧</title>
      </Helmet>

      <div className={`header-with-back ${styles.header}`}>
        <button onClick={() => history.goBack()}>
          <img src={IcBack} alt="" />
        </button>

        <div className="title">お気に入り一覧</div>
      </div>

      <div className="list_favorite">
        {users.map((user) => (
          <div key={user.id} className="favorite_item">
            <div
              className="favorite_item_detail user_avatar"
              onClick={() => goToProfile(user)}
              style={{ cursor: 'pointer' }}
            >
              <div></div>
              <img src={user.avatar || DefaultAvatar} />
            </div>
            <div className="favorite_item_detail user_info">
              <div className="user_info_detail user_status">
                <span className="user_name">{user.nick_name}</span>
              </div>
              <div className="user_info_detail user_age">
                <span className="user_age_img">
                  <img src={JobImg} alt="" />
                </span>
                <span className="user_age_txt">
                  {getGeneration(user.birthday)}代　{getGender(user.gender)}　
                  {getPurpose(user.purpose_id)}
                </span>
              </div>
              <div className="user_info_detail user_address">
                <span className="user_address_img">
                  <img src={AddressImg} alt="" />
                </span>
                <span className="user_address_txt">{getPrefecture(user.prefecture_id)}</span>
              </div>
            </div>
            <div className="favorite_item_detail user_action">
              <button onClick={() => unFavorite(user.id)} className="btn_un_favorite">
                お気に入りを解除
              </button>
            </div>
          </div>
        ))}
      </div>

      <Pagination pagination={pagination} onClickPage={(page) => getData(page)} />
    </div>
  );
}
