import { MamaProfile } from 'modules/user/models';
import { get } from 'services';

const getMyProfile = async (): Promise<MamaProfile> => {
  let res = await get('mama/profile', {});
  if (res) {
    return res;
  } else {
    throw new Error('Empty response data!');
  }
};

export default {
  getMyProfile,
};
