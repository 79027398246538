import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';

import { Popup } from 'components/Popup';
import { useStores } from 'hooks/use-stores';
import SessionStore from 'stores/session';
import UIStore from 'stores/ui';

import './styles.scss';

export const AlertModal = observer(() => {
  const history = useHistory();

  const uiStore: UIStore = useStores().uiStore;
  const sessionStore: SessionStore = useStores().sessionStore;

  const onClose = async () => {
    uiStore.closeAlert();
  };

  const onConfirm = async () => {
    if (uiStore.alertData.linkTogo) {
      if (uiStore.alertData.linkTogo === '/login') {
        await sessionStore.logout();
        history.replace('/login');
      } else {
        history.push(uiStore.alertData.linkTogo);
      }
    }
    uiStore.closeAlert();
  };

  return (
    <>
      {uiStore.isShowAlert && (
        <Popup
          title={uiStore.alertData.title || uiStore.alertData.message}
          alertType={uiStore.alertData.type}
          notShowIcon={uiStore.alertData.notShowIcon}
          content={uiStore.alertData.title ? uiStore.alertData.message : undefined}
          ButtonComponent={
            <div className="button_confirm">
              {uiStore.alertData.type === 'error' && (
                <button
                  className={`confirm ${uiStore.alertData.btnText.length > 5 ? 'longtext' : ''}`}
                  onClick={onConfirm}
                >
                  {uiStore.alertData.btnText}
                </button>
              )}
              {uiStore.alertData.type === 'alert' && (
                <button
                  className={`cancel ${uiStore.alertData.btnText.length > 5 ? 'longtext' : ''}`}
                  onClick={onConfirm}
                >
                  {uiStore.alertData.btnText}
                </button>
              )}
            </div>
          }
          onClose={onClose}
        />
      )}
    </>
  );
});
