import { observer } from 'mobx-react';
import { useEffect, useRef, useState } from 'react';
import { Button, Container } from 'react-bootstrap';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import IcBack from 'assets/icons/ic_back.svg';
import { MeetStatus, MessageType, PlanType, UserRole } from 'config/constants';
import useLayout from 'hooks/use-layout';
import { useStores } from 'hooks/use-stores';
import { Message } from 'modules/message/models';
import { Order } from 'modules/special-order/models';
import { MamaProfile, Plan, ProviderProfile } from 'modules/user/models';
import { get, post, put, uploadImage } from 'services';
import {
  deleteMessage,
  detachMessageListeners,
  getAllMessages,
  sendMessage,
  setupMessageListener,
  updateUnread,
} from 'services/firebase';
import LayoutStore from 'stores/layout';
import SessionStore from 'stores/session';
import UIStore from 'stores/ui';
import { filterBadWords, toAwait } from 'utils';
import { InputField } from './components/InputField';
import { MyMessage } from './components/MyMessage';
import { OrderContent } from './components/OrderContent';
import { OtherMessage } from './components/OtherMessage';

import './styles.scss';

export const ChatScreen = observer(() => {
  const history = useHistory();
  let order: Order | null = null;
  let conversationId: number | null = null;
  const location: any = useLocation();
  if (location.state) {
    conversationId = location.state.conversationId || null;
    order = location.state.order || null;
  }

  const uiStore: UIStore = useStores().uiStore;
  const layoutStore: LayoutStore = useStores().layoutStore;
  const sessionStore: SessionStore = useStores().sessionStore;
  const isMama = sessionStore.session
    ? sessionStore.session.role === UserRole.Mama
      ? true
      : false
    : null;
  const myPlan: Plan | null =
    sessionStore.session && !isMama ? (sessionStore.session as any).member_plan : null;

  const { partnerId } = useParams<{ partnerId: string }>();
  const [messages, setMessages] = useState<Array<Message>>([]);
  const [badwords, setBadwords] = useState<Array<string>>([]);
  const [partner, setPartner] = useState<MamaProfile | ProviderProfile>({} as any);
  const [conversation, setConversation] = useState<number>(0);

  let bottomRef = useRef<HTMLDivElement | null>(null);

  useLayout();

  useEffect(() => {
    layoutStore.hideFooter();

    getBadWords();

    if (!location.state) {
      history.goBack();
    }

    return () => {
      detachMessageListeners();
    };
  }, []);

  useEffect(() => {
    getProfileData();
    if (sessionStore.session?.id) {
      getPartnerProfile();
    }
  }, [sessionStore.session?.id]);

  const scrollToBottom = () => {
    if (bottomRef) {
      bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const getBadWords = async () => {
    let [res, err] = await toAwait(get('bad_words', {}));
    if (err) {
      console.log('error get bad words', err);
    } else {
      setBadwords(res);
    }
  };

  const getPartnerProfile = async () => {
    if (isMama === null) return;
    const url = isMama ? 'mama/providers/' : 'provider/mama/';
    let [res, err] = await toAwait(get(url + partnerId, {}));
    if (err) {
      if (
        err.errors &&
        (err.errors.id === 'このユーザーは存在しません。' || err.errors.id === 'Not found!')
      ) {
        if (sessionStore.session && conversationId) {
          await updateUnread(
            sessionStore.session.id,
            sessionStore.session.role,
            conversationId.toString()
          );
        }

        setTimeout(() => {
          history.goBack();
        }, 1500);
      }
    } else {
      setPartner(res);
      fetchAllMessages();
    }
  };

  const getProfileData = async () => {
    const loading = uiStore.showLoading();
    let [profile, err] = await toAwait(get('profile', []));
    uiStore.hideLoading(loading);
    if (err) {
      console.log('error get my profile', err);
      return;
    } else {
      sessionStore.updateProfile(profile);
    }
  };

  const canShowName = () => {
    const permissionNeed = order ? PlanType.Gold : 0;
    if (!isMama && myPlan && myPlan.type < permissionNeed) return false;
    return true;
  };

  const fetchAllMessages = async () => {
    console.log('Fetch all messages: ', sessionStore.session?.id, conversationId);
    const loadingId = uiStore.showLoading();
    if (sessionStore.session?.id && conversationId) {
      const cId: number = conversationId;
      if (!conversation) setConversation(cId);
      try {
        await getAllMessages(
          conversationId,
          (msgs: any) => {
            setMessages(msgs);
            scrollToBottom();
            updateReadStatus(cId);
          },
          sessionStore.session.id,
          isMama ? sessionStore.session.id : parseInt(partnerId),
          !isMama ? sessionStore.session.id : parseInt(partnerId)
        );
      } catch (err) {
        console.log('Get messages from firebase error: ', err);
      }
    } else {
      console.log('No ID or no conversation id');
    }
    uiStore.hideLoading(loadingId);
  };

  const confirmSendMessage = async (type: number, text?: string, imageFiles?: Array<File>) => {
    if (!isMama) {
      const permissionNeed = order ? PlanType.Gold : PlanType.Standard;

      if (!myPlan || myPlan.type < permissionNeed) {
        if (sessionStore.session?.point && sessionStore.session.point >= 50) {
          const confirm = await uiStore.confirm({
            title: 'ポイントを使用して、<br/>メッセージを送信する',
            content: '現在のポイント数：' + sessionStore.session?.point + 'ｐ',
            confirmText: '送信',
            confirmText2: '戻る',
          });

          if (confirm) {
            await handleSendMessage(type, text, imageFiles);
            history.push(order ? '/provider/special' : '/messages');
          }
        } else if (permissionNeed == PlanType.Gold) {
          const confirm = await uiStore.confirm({
            title: 'メッセージを返すにはゴールド会員になる必要があります',
            content:
              'スペシャルオーダーとはお相手の会員様からのリクエストメッセージが送信されてくる機能となっております。<br />こちらのメッセージの送信者のプロフィールを確認し、返信するためにはゴールド会員になる必要があります。',
            confirmText: 'ゴールド会員になる',
            cancelText: '閉じる',
          });

          if (confirm) history.push('/provider/plan');
        } else {
          const confirm = await uiStore.confirm({
            title:
              'メッセージを開くためには有料会員になるか、<br/>ポイントを使用をする必要があります',
            content:
              '無料会員としてはお相手のプロフィールを確認することができます。その他お気に入り（相手に通知はされません）やブロック、通報なども可能です。実際に連絡を取ることなどはできません。連絡を取るためには有料会員になる、またはポイントを使用する必要がございます。',
            confirmText: '有料会員になる',
            confirmText2: 'ポイント購入する',
            cancelText: '閉じる',
          });

          if ((confirm as any).confirm2) {
            history.push('/chat-point/packages');
          } else if (confirm == true) {
            history.push('/provider/plan');
          }
        }
      } else {
        handleSendMessage(type, text, imageFiles);
      }
    } else {
      handleSendMessage(type, text, imageFiles);
    }
  };

  const handleSendMessage = async (type: number, text?: string, imageFiles?: Array<File>) => {
    if (partner.is_blocked) {
      uiStore.showAlert({
        type: 'alert',
        message: 'ブロック中のためメッセージは送れません。',
        btnText: '閉じる',
      });
      return;
    }

    const myId = sessionStore.session?.id;
    if (!myId) return;

    const loading = uiStore.showLoading();
    let imageUrls: Array<string> = [];
    if (imageFiles && imageFiles.length > 0) {
      try {
        imageUrls = await uploadImage(imageFiles);
      } catch (err) {
        console.log('Upload file error: ', err);
        uiStore.hideLoading(loading);
        return;
      }
    }
    let res, err: any;

    if (imageUrls.length > 0) {
      const messageImages = {
        provider_id: isMama ? parseInt(partnerId) : myId,
        mama_id: isMama ? myId : parseInt(partnerId),
        order_id: order ? order.id : undefined,
        type: MessageType.Images,
        message: JSON.stringify(imageUrls),
      };
      [res, err] = await toAwait(post(`message/create/${partnerId}`, messageImages));
      if (err) {
        console.log('cannot send message via API: ', err);
      } else {
        const msg = {
          sender_id: myId,
          receiver_id: parseInt(partnerId),
          type: MessageType.Images,
          image_urls: imageUrls,
          is_read: 0,
          members: [myId, parseInt(partnerId)],
        };
        let [_, err] = await toAwait(
          sendMessage(
            conversationId ? conversationId.toString() : res.conversation_id.toString(),
            res.id.toString(),
            msg,
            isMama ? myId : parseInt(partnerId),
            isMama ? parseInt(partnerId) : myId,
            order ? order.id : undefined
          )
        );
        if (err) {
          console.log('cannot send message via firebase: ', err);
        }
      }
    }

    if (text) {
      text = filterBadWords(text, badwords);
      const messageText = {
        provider_id: isMama ? parseInt(partnerId) : myId,
        mama_id: isMama ? myId : parseInt(partnerId),
        order_id: order ? order.id : undefined,
        type: MessageType.Text,
        message: text,
      };
      [res, err] = await toAwait(post(`message/create/${partnerId}`, messageText));
      if (err) {
        console.log('cannot send message via API: ', err);
      } else {
        const msg = {
          sender_id: myId,
          receiver_id: parseInt(partnerId),
          type: MessageType.Text,
          text: text,
          is_read: 0,
          members: [myId, parseInt(partnerId)],
        };
        let [_, err] = await toAwait(
          sendMessage(
            conversationId ? conversationId.toString() : res.conversation_id.toString(),
            res.id.toString(),
            msg,
            isMama ? myId : parseInt(partnerId),
            isMama ? parseInt(partnerId) : myId,
            order ? order.id : undefined
          )
        );
        if (err) {
          console.log('cannot send message via firebase: ', err);
        }
      }
    }

    if (messages.length == 0 && res) {
      const cId: number = res.conversation_id;
      if (!conversation) setConversation(cId);
      setupMessageListener(
        conversationId ? conversationId : cId,
        (msgs: any) => {
          setMessages(msgs);
          scrollToBottom();
          updateReadStatus(cId);
        },
        myId
      );
    }
    uiStore.hideLoading(loading);
  };

  const updateReadStatus = async (conversationId: number) => {
    if (conversationId && sessionStore.session) {
      updateUnread(sessionStore.session.id, sessionStore.session.role, conversationId.toString());
    }

    const body = {
      conversation_id: conversationId,
    };
    const [_, err] = await toAwait(put('message/update-read', body));
    if (err) {
      console.log('update read err: ', err);
      return false;
    }
    return true;
  };

  const renderMeetBtn = () => {
    if (!isMama) return null;

    const user: ProviderProfile = partner as ProviderProfile;
    if (user?.meet_status === MeetStatus.Met) {
      return <Button variant="purple">マッチ済</Button>;
    }

    return (
      <Button
        variant="purple"
        onClick={async () => {
          const loadingId = uiStore.showLoading();
          if (user?.meet_status === MeetStatus.Confirming) {
            await uiStore.confirm({
              title: `${user.nick_name}さんに確認中です`,
              contentComponent: (
                <p>
                  お会いしたお相手の{user.nick_name}さんに確認中です。
                  <br />
                  {user.nick_name}さんからの確認が完了し次第、ポイントが追加されます。
                </p>
              ),
              cancelText: '閉じる',
            });
          } else {
            const confirm = await uiStore.confirm({
              title: 'この方と直接お会いしましたか？',
              contentComponent: (
                <p>
                  このボタンはこのアプリでお約束をした方と直接お会いできた際に押すボタンです。無事アプリ上でお約束した方とお会いできた際にはあなたに換金可能な500ptを付与致します。
                  <br />
                  <br />
                  ※換金は1pt =
                  1円で換算されます。また、換金は10,000pt以上から可能となります。現在の保有ポイントはマイページの「保有ポイント数」からご確認いただけます。
                </p>
              ),
              confirmText: 'はい',
              cancelText: 'いいえ',
            });
            if (confirm) {
              const success = await requestMeet();
              if (success) {
                await uiStore.confirm({
                  title: `${user.nick_name}さんに確認中です`,
                  content: `お会いしたお相手の${user.nick_name}さんに確認中です。${user.nick_name}さんからの確認が完了し次第、ポイントが追加されます。`,
                  cancelText: '閉じる',
                });
              }
              getPartnerProfile();
            }
          }
          uiStore.hideLoading(loadingId);
        }}
      >
        マッチ確認
      </Button>
    );
  };

  const requestMeet = async () => {
    const [_, err] = await toAwait(post('mama/provider/confirm-met/' + partner.id, {}));
    if (err) {
      console.log('request meet error: ', err);
      return false;
    }
    return true;
  };

  const handleDelete = async (message: Message) => {
    let confirm = await uiStore.confirm({
      title: 'このメッセージを削除します。よろしいですか？',
      confirmText: 'はい',
      cancelText: 'いいえ',
    });
    if (!confirm) return;

    const loadingId = uiStore.showLoading();
    const [_, err] = await toAwait(get(`message/delete/${message.id}/${conversation}`, {}));
    if (err) {
      console.log('delete message error: ', err);
      uiStore.hideLoading(loadingId);
      return;
    }

    deleteMessage(conversation.toString(), message.id.toString());
    uiStore.hideLoading(loadingId);
  };

  return (
    <main className="message-detail">
      <div className="header-with-back">
        <button onClick={() => history.goBack()}>
          <img src={IcBack} alt="" />
        </button>

        <div>
          {canShowName()
            ? partner?.nick_name
            : 'ゴールド会員になると相手のプロフィールが確認できます'}
          {renderMeetBtn()}
        </div>
      </div>

      <Container>
        <div className={`message-detail-content ratio${sessionStore.fontRatioSetting}`}>
          {order && (
            <OrderContent
              order={order}
              isMama={!!isMama}
              userPlan={myPlan?.type}
              permissionNeed={order ? PlanType.Gold : PlanType.Standard}
            />
          )}
          {messages.map((message) =>
            message.sender_id === sessionStore.session?.id ? (
              <MyMessage
                key={message.id}
                message={message}
                onDelete={() => handleDelete(message)}
              />
            ) : (
              <OtherMessage
                key={message.id}
                message={message}
                partner={partner}
                userPlan={myPlan?.type}
                permissionNeed={order ? PlanType.Gold : PlanType.Standard}
              />
            )
          )}
          <div ref={bottomRef}></div>
        </div>
        <InputField onSend={confirmSendMessage} meetBtn={renderMeetBtn()} />
      </Container>
    </main>
  );
});
