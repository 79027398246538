import { Button, Col, Form, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useForm } from 'react-hook-form';

import { useStores } from 'hooks/use-stores';
import { post } from 'services';
import UIStore from 'stores/ui';
import { toAwait } from 'utils';

import './style.css';

export const InquiryScreen = () => {
  const uiStore: UIStore = useStores().uiStore;

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data: any) => {
    const loading = uiStore.showLoading();
    const [res, err] = await toAwait(
      post('inquiry', {
        name: data.name,
        email: data.email,
        content: data.inquiry,
      })
    );
    uiStore.hideLoading(loading);
    if (err) {
      console.log('error when send email inquiry: ', err);
      return;
    }
    uiStore.showAlert({
      type: 'alert',
      message: 'お問い合わせ内容を送信しました。',
      btnText: '閉じる',
    });
    reset();
  };

  return (
    <div className="col-md-12 col-sm-12 col-xs-12 page-footer">
      <Helmet>
        <title>お問い合わせ</title>
      </Helmet>
      <Form className="inquiry-form" autoComplete="off">
        <h1 className="text-center top-title-h1">
          <span>INQUIRY</span>
        </h1>
        <h3>お問い合わせ</h3>
        <p className="content">
          お問い合わせフォームでのお問い合わせにつきましては通常１～３営業日内ご返信いたします。多くのお問い合わせをいただいている場合、通常よりお時間をいただきますが、順番にご返信いたしますので、お待ち頂けますと幸いです。（返信はご記載のメールアドレス宛にお送りいたします。）
        </p>
        <div className="form">
          <Row>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>
                  <div>
                    <span className="title_form">氏名</span>
                  </div>
                </Form.Label>
                <input
                  className="btnx form-control"
                  type="text"
                  placeholder="氏名を入力してください"
                  {...register('name', { required: true })}
                />
                {errors.name && errors.name.type === 'required' && (
                  <span className="title_form" style={{ color: 'red' }}>
                    氏名が必須項目です。
                  </span>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>
                  <div>
                    <span className="title_form">メールアドレス</span>
                  </div>
                </Form.Label>
                <input
                  className="btnx form-control"
                  type="text"
                  placeholder="メールアドレスを入力してください"
                  {...register('email', { required: true, pattern: /^\S+@\S+$/i })}
                />
                {errors.email && errors.email.type === 'required' && (
                  <span className="title_form" style={{ color: 'red' }}>
                    メールアドレスが必須項目です。
                  </span>
                )}
                {errors.email && errors.email.type === 'pattern' && (
                  <span className="title_form" style={{ color: 'red' }}>
                    ※メール形式が正しくありません。
                  </span>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>
                  <div>
                    <span className="title_form">お問い合わせ内容</span>
                  </div>
                </Form.Label>
                <textarea
                  className="textarea form-control"
                  placeholder="お問い合わせ内容を入力してください"
                  {...register('inquiry', { required: true })}
                />
                {errors.inquiry && errors.inquiry.type === 'required' && (
                  <span className="title_form" style={{ color: 'red' }}>
                    お問い合わせ内容が必須項目です。
                  </span>
                )}
              </Form.Group>
            </Col>
          </Row>
          <div className="text-center">
            <Button onClick={handleSubmit(onSubmit)}>送信</Button>
          </div>
        </div>
      </Form>
    </div>
  );
};
