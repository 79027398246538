import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import { NavLink, useHistory } from 'react-router-dom';

import LogoMobile from 'assets/images/logo-mobile.svg';
import Logo from 'assets/images/logo.svg';
import { UserRole } from 'config/constants';
import { useStores } from 'hooks/use-stores';
import NotificationStore from 'stores/notification';
import SessionStore, { SessionUser } from 'stores/session';
import UIStore from 'stores/ui';
import { formatUnread } from 'utils';

import './header.scss';

export const PrivateHeader = observer(({ user }: { user: SessionUser }) => {
  const history = useHistory();

  const sessionStore: SessionStore = useStores().sessionStore;
  const uiStore: UIStore = useStores().uiStore;
  const notificationStore: NotificationStore = useStores().notificationStore;

  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    if (expanded) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [expanded]);

  const logout = async () => {
    let confirm = await uiStore.confirm({
      title: 'ログアウトしますか？',
      confirmText: 'はい',
      cancelText: 'いいえ',
    });
    if (confirm) {
      const loading = uiStore.showLoading();
      await sessionStore.logout();
      uiStore.hideLoading(loading);
      history.replace('/');
    }
  };

  const gotoGuideMobile = () => {
    const ua = navigator.userAgent;
    if (/android/i.test(ua)) {
      window.location.href = 'https://youtu.be/--4pargmz7M';
    } else if (/iPad|iPhone|iPod/.test(ua)) {
      window.location.href = 'https://youtu.be/imeQ-7AGfuY';
    }
  };

  return (
    <div className="header">
      <NavLink to="/" exact className="navbar-brand">
        <img src={Logo} alt="desire" className="logo-dt" />
        <img src={LogoMobile} alt="desire" className="logo-mb" />
      </NavLink>

      <div className="menu-header">
        <NavLink to="/search" className="nav-link">
          ホーム
        </NavLink>
        <NavLink to={'/news'} className="nav-link">
          <div className="icon">
            {notificationStore.newsCount ? (
              <span>{formatUnread(notificationStore.newsCount)}</span>
            ) : null}
          </div>
          お知らせ
        </NavLink>
        <NavLink to="/messages" className="nav-link">
          <div className="icon">
            {notificationStore.messageCount ? (
              <span>{formatUnread(notificationStore.messageCount)}</span>
            ) : null}
          </div>
          メッセージ
        </NavLink>
        <NavLink
          to={user.role === UserRole.Mama ? '/mama/special' : '/provider/special'}
          className="nav-link"
        >
          <div className="icon">
            {notificationStore.specialCount ? (
              <span>{formatUnread(notificationStore.specialCount)}</span>
            ) : null}
          </div>
          スペシャルオーダー
        </NavLink>
        <NavLink
          to={user.role === UserRole.Mama ? '/mama/mypage' : '/provider/mypage'}
          className="nav-link"
        >
          マイページ
        </NavLink>
        <a className="nav-link" onClick={() => logout()} style={{ cursor: 'pointer' }}>
          ログアウト
        </a>
      </div>

      <div className="guide-mobile" onClick={gotoGuideMobile}>
        ホーム画面への
        <br />
        追加方法はこちら
      </div>

      <div className="menu-mobile">
        <Navbar expand="lg" expanded={expanded}>
          <Navbar.Toggle
            aria-controls="basic-navbar-nav"
            onClick={() => setExpanded(expanded ? false : true)}
          />
          <Navbar.Collapse id="basic-navbar-nav" style={{ flex: 0 }}>
            <span className="mama-close" onClick={() => setExpanded(false)}>
              ×
            </span>
            <Nav className="me-auto">
              <NavLink to={'/company'} className="nav-link" onClick={() => setExpanded(false)}>
                会社概要
              </NavLink>
              <NavLink to={'/rules'} className="nav-link" onClick={() => setExpanded(false)}>
                利用規約
              </NavLink>
              <NavLink to={'/inquiry'} className="nav-link" onClick={() => setExpanded(false)}>
                広告希望企業様募集中
              </NavLink>
              <NavLink to={'/policy'} className="nav-link" onClick={() => setExpanded(false)}>
                プライバシーポリシー
              </NavLink>
              <NavLink to={'/display'} className="nav-link" onClick={() => setExpanded(false)}>
                特定商取引に基づく表示
              </NavLink>
              <Nav.Link
                className="nav-link"
                onClick={() => {
                  logout();
                  setExpanded(false);
                }}
              >
                ログアウト
              </Nav.Link>
              <NavLink to={'/inquiry'} className="nav-link" onClick={() => setExpanded(false)}>
                お問い合わせ
              </NavLink>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
    </div>
  );
});
