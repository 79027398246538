import { DatePickerInput } from '@mantine/dates';
import { useEffect, useState } from 'react';
import { Button, Col, FloatingLabel, Form, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { Controller, useForm } from 'react-hook-form';

import {
  ANNUAL_INCOMES,
  EXPECTED_HOURLY_RATES,
  GENDERS,
  OCCUPATIONS,
  PREFECTURES,
  PROVIDER_TYPES,
  PURPOSES,
  SERVICES,
} from 'config/constants';
import { MamaProfile, ProviderProfile } from 'modules/user/models';
import { SnsUserData } from 'modules/user/models/UserProfile';
import { FileSelectorPopup } from './FilePopup';
import { ImageSelectorPopup } from './ImagePopup';
import { VideoSelectorPopup } from './VideoPopup';

import './styles.scss';

interface IProps {
  isMama: boolean;
  onSubmit: (data: any) => void;
  snsUser?: SnsUserData;
  userData?: MamaProfile | ProviderProfile;
}

export const RegisterScreenTemplate = ({ isMama, onSubmit, snsUser, userData }: IProps) => {
  const {
    control,
    register,
    handleSubmit,
    getValues,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    register('birthday', { required: true });
    register('avatar_file');
    register('avatar_file_1');
    register('avatar_file_2');
    register('video_file');
    if (!userData) {
      register('identity_verification_documents', { required: true });
    } else {
      register('identity_verification_documents');
    }
  }, [register]);

  useEffect(() => {
    if (snsUser) {
      if (snsUser.email) setValue('email', snsUser.email);
      if (snsUser.birthday) setValue('birthday', new Date(snsUser.birthday));
      if (snsUser.name) setValue('nick_name', snsUser.name);
      if (snsUser.first_name) setValue('first_name', snsUser.first_name);
      if (snsUser.last_name) setValue('last_name', snsUser.last_name);
    }
  }, [snsUser]);

  const [oldMedia, setOldMedia] = useState<any>({});
  useEffect(() => {
    if (userData) {
      if (userData.email) setValue('email', userData.email);
      if (userData.nick_name) setValue('nick_name', userData.nick_name);
      if (userData.first_name) setValue('first_name', userData.first_name);
      if (userData.last_name) setValue('last_name', userData.last_name);
      if (userData.birthday) setValue('birthday', new Date(userData.birthday));
      if (userData.prefecture_id) setValue('prefecture_id', userData.prefecture_id);
      setValue('gender', userData.gender);
      if (userData.hobby) setValue('hobby', userData.hobby);
      if (userData.special_skills) setValue('special_skills', userData.special_skills);
      if (userData.profession_id) setValue('profession_id', userData.profession_id);
      if (userData.self_introduction) setValue('self_introduction', userData.self_introduction);

      if (isMama) {
        let user = userData as MamaProfile;
        if (user.annual_income_id) setValue('annual_income_id', user.annual_income_id);
        if (user.purpose_id) setValue('purpose_id', user.purpose_id);
      } else {
        let user = userData as ProviderProfile;
        if (user.member_type_id) setValue('member_type_id', user.member_type_id);
        if (user.expect_salary_per_hour)
          setValue('expect_salary_per_hour', user.expect_salary_per_hour);
        if (user.is_receive_gif) setValue('is_receive_gif', user.is_receive_gif);
        if (user.member_services) {
          user.member_services.map((id) => setValue('service_' + id, true));
        }
      }

      setOldMedia({
        avatar_file: userData.avatar,
        avatar_file_1: userData.avatar_1,
        avatar_file_2: userData.avatar_2,
        video_file: userData.video,
        identity_verification_documents: userData.identity_verification_documents,
      });
    }
  }, [userData]);

  const onError = (errors: any) => {
    console.log('Submit error: ', errors);
  };

  const onSubmitData = (data: any) => {
    if (data.password != data.password_confirmation) {
      setError(
        'password_confirmation',
        {
          type: 'manual',
          message: 'not match',
        },
        { shouldFocus: true }
      );
      return;
    }

    if (!isMama) {
      let member_services: Array<number> = [];
      if (data.service_1) member_services.push(1);
      if (data.service_2) member_services.push(2);
      if (data.service_3) member_services.push(3);
      if (member_services.length === 0) {
        setError(
          'member_services',
          {
            type: 'required',
            message: 'data is empty',
          },
          { shouldFocus: true }
        );
        return;
      }

      data.member_services = member_services;
      delete data.service_1;
      delete data.service_2;
      delete data.service_3;
      data.is_receive_gif = data.is_receive_gif ? 1 : 0;
    }

    data.gender = data.gender.toString();
    console.log('Submit data: ', data);
    onSubmit(data);
  };

  const checkValueServices = (current: number) => {
    const values = getValues(['service_1', 'service_2', 'service_3']);
    values[current - 1] = !values[current - 1];
    const hasValue = values[0] || values[1] || values[2];
    setValue('member_services', hasValue);
    if (hasValue) {
      clearErrors('member_services');
    } else {
      setError(
        'member_services',
        {
          type: 'required',
          message: 'data is empty',
        },
        { shouldFocus: true }
      );
    }
  };

  return (
    <Form className="register-form">
      <Helmet>
        <title>{userData ? 'プロフィール編集' : '新規会員登録'}</title>
      </Helmet>
      <div className="container container-x">
        <div className="session_register_mama">
          <div className="title_register">プロフィール写真設定</div>
          <div className="text_register w-100">
            {isMama
              ? '※他女性会員（サービスを受ける側）同士の閲覧はできません。'
              : '※他男性会員（サービスを提供する側、レズビアンも含む）同士の閲覧はできません。'}
          </div>
          <div className="session_images_round">
            <div className="d-flex justify-content-between">
              {['avatar_file', 'avatar_file_1', 'avatar_file_2'].map((fieldName, index) => (
                <ImageSelectorPopup
                  key={index}
                  onFileChange={(file: File) => setValue(fieldName, file)}
                  oldValue={oldMedia[fieldName]}
                />
              ))}
            </div>
          </div>
        </div>
        <div className="session_register_mama">
          <div className="title_register">プロフィール動画設定</div>
          <div className="text_register">※動画は25MBまでのものが登録できます</div>
          <VideoSelectorPopup
            onFileChange={(file: File) => setValue('video_file', file)}
            oldValue={oldMedia['video_file']}
          />
        </div>

        <div className="session_register_mama">
          <div className="form_register_mama">
            <Row className="pb_form_register">
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>
                    <div>
                      <span className="require_form">必須</span>
                      <span className="title_form">姓</span>
                      <span className="small_title_form">※非公開</span>
                    </div>
                  </Form.Label>
                  <input
                    className="btnx form-control"
                    type="text"
                    placeholder="姓を入力してください"
                    {...register('first_name', { required: true, maxLength: 20 })}
                  />
                  {errors.first_name && errors.first_name.type === 'required' && (
                    <span style={{ color: 'red' }}> 姓が必須項目です。</span>
                  )}
                  {errors.first_name && errors.first_name.type === 'maxLength' && (
                    <span style={{ color: 'red' }}>※20文字以内で入力してください。</span>
                  )}
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>
                    <div>
                      <span className="require_form">必須</span>
                      <span className="title_form">名</span>
                      <span className="small_title_form">※非公開</span>
                    </div>
                  </Form.Label>
                  <input
                    className="btnx form-control"
                    type="text"
                    placeholder="名を入力してください"
                    {...register('last_name', { required: true, maxLength: 20 })}
                  />
                  {errors.last_name && errors.last_name.type === 'required' && (
                    <span style={{ color: 'red' }}>名が必須項目です。</span>
                  )}
                  {errors.last_name && errors.last_name.type === 'maxLength' && (
                    <span style={{ color: 'red' }}>※20文字以内で入力してください。</span>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row className="pb_form_register">
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>
                    <div>
                      <span className="require_form">必須</span>
                      <span className="title_form">ニックネーム</span>
                    </div>
                  </Form.Label>
                  <input
                    className="btnx form-control"
                    type="text"
                    placeholder="ニックネームを入力してください"
                    {...register('nick_name', { required: true, maxLength: 20 })}
                  />
                  {errors.nick_name && errors.nick_name.type === 'required' && (
                    <span style={{ color: 'red' }}>ニックネームが必須項目です。</span>
                  )}
                  {errors.nick_name && errors.nick_name.type === 'maxLength' && (
                    <span style={{ color: 'red' }}>※20文字以内で入力してください。</span>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row className="pb_form_register">
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>
                    <div>
                      <span className="require_form">必須</span>
                      <span className="title_form">メールアドレス</span>
                      <span className="small_title_form">※非公開</span>
                    </div>
                  </Form.Label>
                  <input
                    className="btnx form-control"
                    type="email"
                    placeholder="メールアドレスを入力してください"
                    {...register('email', { required: true, pattern: /^\S+@\S+$/i })}
                  />
                  {errors.email && errors.email.type === 'required' && (
                    <span style={{ color: 'red' }}>メールアドレスが必須項目です。</span>
                  )}
                  {errors.email && errors.email.type === 'pattern' && (
                    <span style={{ color: 'red' }}>※メール形式が正しくありません。</span>
                  )}
                </Form.Group>
              </Col>
            </Row>
            {!userData && (
              <Row className="pb_form_register">
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      <div>
                        <span className="require_form">必須</span>
                        <span className="title_form">パスワード</span>
                        <span className="small_title_form">※非公開</span>
                      </div>
                    </Form.Label>
                    <input
                      className="btnx form-control"
                      type="password"
                      placeholder="半角英数字を含む8文字以上32文字以内で作成してください。"
                      {...register('password', { required: true, minLength: 8, maxLength: 32 })}
                    />
                    {errors.password && errors.password.type === 'required' && (
                      <span style={{ color: 'red' }}>パスワードが必須項目です。</span>
                    )}
                    {errors.password &&
                      (errors.password.type === 'minLength' ||
                        errors.password.type === 'maxLength') && (
                        <span style={{ color: 'red' }}>
                          ※８文字以上32文字以内で入力してください。
                        </span>
                      )}
                  </Form.Group>
                </Col>
              </Row>
            )}
            {!userData && (
              <Row className="pb_form_register">
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      <div>
                        <span className="require_form">必須</span>
                        <span className="title_form">パスワード（確認）</span>
                        <span className="small_title_form">※非公開</span>
                      </div>
                    </Form.Label>
                    <input
                      className="btnx form-control"
                      type="password"
                      placeholder="半角英数字を含む8文字以上32文字以内で作成してください。"
                      {...register('password_confirmation', {
                        required: true,
                        minLength: 8,
                        maxLength: 32,
                      })}
                    />
                    {errors.password_confirmation &&
                      errors.password_confirmation.type === 'required' && (
                        <span style={{ color: 'red' }}>パスワード（確認）が必須項目です。</span>
                      )}
                    {errors.password &&
                      (errors.password.type === 'minLength' ||
                        errors.password.type === 'maxLength') && (
                        <span style={{ color: 'red' }}>
                          ※８文字以上32文字以内で入力してください。
                        </span>
                      )}
                    {errors.password_confirmation &&
                      errors.password_confirmation.type === 'manual' && (
                        <span style={{ color: 'red' }}>※パスワードが一致しませんでした。</span>
                      )}
                  </Form.Group>
                </Col>
              </Row>
            )}
            <Row className="pb_form_register">
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>
                    <div>
                      <span className="require_form">必須</span>
                      <span className="title_form">生年月日</span>
                      <span className="small_title_form">※年代が公開</span>
                    </div>
                  </Form.Label>
                  <FloatingLabel label="">
                    <Controller
                      control={control}
                      name="birthday"
                      render={({ field }) => (
                        <DatePickerInput
                          locale="ja"
                          value={field.value}
                          onChange={(date: Date) => {
                            console.log('select date: ', date);
                            field.onChange(date);
                          }}
                          maxDate={new Date()}
                          valueFormat="YYYY/MM/DD"
                          placeholder="生年月日を選択してください"
                        />
                      )}
                    />
                  </FloatingLabel>
                  {errors.birthday && errors.birthday.type === 'required' && (
                    <span style={{ color: 'red' }}>生年月日が必須項目です。</span>
                  )}
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>
                    <div>
                      <span className="require_form">必須</span>
                      <span className="title_form">地域</span>
                    </div>
                  </Form.Label>
                  {/* <FloatingLabel label=""> */}
                  <Form.Select
                    className="btnx"
                    defaultValue={''}
                    {...register('prefecture_id', { required: true })}
                  >
                    <option value="" disabled>
                      地域を選択してください
                    </option>
                    {Object.keys(PREFECTURES).map((key) => (
                      <option key={key} value={parseInt(key)}>
                        {PREFECTURES[parseInt(key)]}
                      </option>
                    ))}
                  </Form.Select>
                  {/* </FloatingLabel> */}
                  {errors.prefecture_id && errors.prefecture_id.type === 'required' && (
                    <span style={{ color: 'red' }}>地域が必須項目です。</span>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row className="pb_form_register">
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>
                    <div>
                      <span className="require_form">必須</span>
                      <span className="title_form">性別</span>
                    </div>
                  </Form.Label>
                  {/* <FloatingLabel label=""> */}
                  <Form.Select
                    className="btnx"
                    defaultValue=""
                    {...register('gender', { required: true })}
                  >
                    <option value="" disabled>
                      性別を選択してください
                    </option>
                    {GENDERS.map((gender) => (
                      <option key={gender.value} value={gender.value}>
                        {gender.label}
                      </option>
                    ))}
                  </Form.Select>
                  {/* </FloatingLabel> */}
                  {errors.gender && errors.gender.type === 'required' && (
                    <span style={{ color: 'red' }}>性別が必須項目です。</span>
                  )}
                </Form.Group>
              </Col>
              {isMama && (
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      <div>
                        <span className="require_form">必須</span>
                        <span className="title_form">年収</span>
                      </div>
                    </Form.Label>
                    {/* <FloatingLabel label=""> */}
                    <Form.Select
                      className="btnx"
                      defaultValue=""
                      aria-label="Floating label select example"
                      {...register('annual_income_id', { required: true })}
                    >
                      <option value="" disabled>
                        年収を選択してください
                      </option>
                      {Object.keys(ANNUAL_INCOMES).map((key) => (
                        <option key={key} value={parseInt(key)}>
                          {ANNUAL_INCOMES[parseInt(key)]}
                        </option>
                      ))}
                    </Form.Select>
                    {/* </FloatingLabel> */}
                    {errors.annual_income_id && errors.annual_income_id.type === 'required' && (
                      <span style={{ color: 'red' }}>年収が必須項目です。</span>
                    )}
                  </Form.Group>
                </Col>
              )}
              {!isMama && (
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      <div>
                        <span className="require_form">必須</span>
                        <span className="title_form">系統</span>
                      </div>
                    </Form.Label>
                    {/* <FloatingLabel label=""> */}
                      <Form.Select
                        className="btnx"
                        defaultValue=""
                        {...register('member_type_id', { required: true })}
                      >
                        <option value="" disabled>
                          系統を選択してください
                        </option>
                        {Object.keys(PROVIDER_TYPES).map((key) => (
                          <option key={key} value={key}>
                            {PROVIDER_TYPES[parseInt(key)]}
                          </option>
                        ))}
                      </Form.Select>
                    {/* </FloatingLabel> */}
                    {errors.member_type_id && errors.member_type_id.type === 'required' && (
                      <span style={{ color: 'red' }}>系統が必須項目です。</span>
                    )}
                  </Form.Group>
                </Col>
              )}
            </Row>
            <Row className="pb_form_register">
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>
                    <div>
                      <span className="title_form">趣味</span>
                    </div>
                  </Form.Label>
                  <input
                    className="btnx form-control"
                    type="text"
                    placeholder="趣味を入力してください"
                    {...register('hobby')}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>
                    <div>
                      <span className="title_form">特技</span>
                    </div>
                  </Form.Label>
                  <input
                    className="btnx form-control"
                    type="text"
                    placeholder="特技を入力してください"
                    {...register('special_skills')}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="pb_form_register">
              <Col md={isMama ? 6 : 12}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    <div>
                      <span className="title_form">職業</span>
                    </div>
                  </Form.Label>
                  {/* <FloatingLabel label=""> */}
                  <Form.Select className="btnx" defaultValue="" {...register('profession_id')}>
                    <option value="" disabled>
                      職業を選択してください
                    </option>
                    {Object.keys(OCCUPATIONS).map((key) => (
                      <option key={key} value={parseInt(key)}>
                        {OCCUPATIONS[parseInt(key)]}
                      </option>
                    ))}
                  </Form.Select>
                  {/* </FloatingLabel> */}
                </Form.Group>
              </Col>
              {isMama && (
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      <div>
                        <span className="require_form">必須</span>
                        <span className="title_form">目的</span>
                      </div>
                    </Form.Label>
                    {/* <FloatingLabel label=""> */}
                    <Form.Select
                      className="btnx"
                      defaultValue=""
                      {...register('purpose_id', { required: true })}
                    >
                      <option value="" disabled>
                        目的を選択してください
                      </option>
                      {Object.keys(PURPOSES).map((key) => (
                        <option key={key} value={parseInt(key)}>
                          {PURPOSES[parseInt(key)]}
                        </option>
                      ))}
                    </Form.Select>
                    {/* </FloatingLabel> */}
                    {errors.purpose_id && errors.purpose_id.type === 'required' && (
                      <span style={{ color: 'red' }}>目的が必須項目です。</span>
                    )}
                  </Form.Group>
                </Col>
              )}
            </Row>
            {!isMama && (
              <Row className="pb_form_register">
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      <div>
                        <span className="require_form">必須</span>
                        <span className="title_form">希望時給</span>
                      </div>
                    </Form.Label>
                    <Form.Select
                      id="abc1"
                      className="btnx"
                      defaultValue=""
                      aria-label="Floating label select example"
                      {...register('expect_salary_per_hour', { required: true })}
                    >
                      <option value="" disabled>
                        希望時給を選択してください
                      </option>
                      {Object.keys(EXPECTED_HOURLY_RATES).map((key) => (
                        <option key={key} value={key}>
                          {EXPECTED_HOURLY_RATES[key]}
                        </option>
                      ))}
                    </Form.Select>
                    {errors.expect_salary_per_hour &&
                      errors.expect_salary_per_hour.type === 'required' && (
                        <span style={{ color: 'red' }}>希望時給が必須項目です。</span>
                      )}
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicCheckbox">
                    <Form.Check
                      className="checkbox_type flex items-center"
                      type="checkbox"
                      label="物品報酬でも可能な場合はこちらにチェックしてください"
                      {...register('is_receive_gif')}
                    />
                  </Form.Group>
                </Col>
              </Row>
            )}
            {!isMama && (
              <Row className="pb_form_register">
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      <div>
                        <span className="require_form">必須</span>
                        <span className="title_form">対応可能サービス</span>
                        <span className="small_title_form">※複数選択可能</span>
                      </div>
                    </Form.Label>
                  </Form.Group>
                  <Row>
                    <Col>
                      <Form.Group className="mb-3" controlId="formBasicCheckbox1">
                        <Form.Check
                          className="checkbox_type flex items-center"
                          type="checkbox"
                          label={SERVICES[1]}
                          {...register('service_1', {
                            onChange: (e) => {
                              checkValueServices(1);
                            },
                          })}
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className="mb-3" controlId="formBasicCheckbox2">
                        <Form.Check
                          className="checkbox_type flex items-center"
                          type="checkbox"
                          label={SERVICES[2]}
                          {...register('service_2', {
                            onChange: (e) => {
                              checkValueServices(2);
                            },
                          })}
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className="mb-3" controlId="formBasicCheckbox3">
                        <Form.Check
                          className="checkbox_type flex items-center"
                          type="checkbox"
                          label={SERVICES[3]}
                          {...register('service_3', {
                            onChange: (e) => {
                              checkValueServices(3);
                            },
                          })}
                        />
                      </Form.Group>
                    </Col>
                    <input
                      style={{ height: 0 }}
                      type="checkbox"
                      {...register('member_services', { required: userData ? false : true })}
                    />
                    {errors.member_services && errors.member_services.type === 'required' && (
                      <span style={{ color: 'red' }}>対応可能サービスが必須項目です。</span>
                    )}
                  </Row>
                </Col>
              </Row>
            )}
            <Row className="pb_form_register avaiable-service">
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>
                    <div>
                      <span className="title_form">自己紹介</span>
                    </div>
                  </Form.Label>
                  <textarea
                    className="form-control"
                    maxLength={200}
                    rows={8}
                    placeholder="200文字以内で自己紹介文を入力してください"
                    {...register('self_introduction')}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="avaiable-service">
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>
                    <div>
                      <span className="require_form">必須</span>
                      <span className="title_form">本人確認書類</span>
                      <span className="small_title_form">※非公開</span>
                    </div>
                    <div>
                      <span className="pc_small_title_form">※ お名前、生年月日部分の塗り潰しがある場合は、会員登録をお受けできない場合がございます。</span>
                    </div>
                    <div>
                      <span className="pc_small_title_form">※ 加工されてると思われる箇所がある場合は、会員登録をお受けできない場合がございます。</span>
                    </div>
                    <div>
                      <p className="mb_small_title_form">※ お名前、生年月日部分の塗り潰しがある場合は、<br />会員登録をお受けできない場合がございます。</p>
                    </div>
                    <div>
                      <p className="mb_small_title_form">※ 加工されてると思われる箇所がある場合は、<br />会員登録をお受けできない場合がございます。</p>
                    </div>
                  </Form.Label>
                  <FileSelectorPopup
                    onFileChange={(file: File) => setValue('identity_verification_documents', file)}
                    oldValue={oldMedia['identity_verification_documents']}
                    isMama={isMama ? true : false}
                  />
                  {errors.identity_verification_documents &&
                    errors.identity_verification_documents.type === 'required' && (
                      <span style={{ color: 'red' }}>本人確認書類が必須項目です。</span>
                    )}
                </Form.Group>
              </Col>
            </Row>
            <div className="note_form">
              ※
              本サービスは18歳以上（もしくは高校生は除く）の方のみが利用可能です。本人確認書類が承認されてからサービスが利用できるようになります。
            </div>

            <div className="p_btn_submit">
              <Button className="submit_btn d-inline" onClick={handleSubmit(onSubmitData, onError)}>
                登録
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Form>
  );
};
