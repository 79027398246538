import { useEffect, useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';

import { Popup } from 'components/Popup';
import { MeetStatus, PlanType, UserRole } from 'config/constants';
import { useStores } from 'hooks/use-stores';
import { MamaProfile, Plan, ProviderProfile } from 'modules/user/models';
import { get, post } from 'services';
import SessionStore from 'stores/session';
import UIStore from 'stores/ui';
import {
  getAnnualIncome,
  getGender,
  getJob,
  getPrefecture,
  getProviderType,
  getPurpose,
  getSalaryPerHour,
  toAwait,
} from 'utils';
import { AvatarContainer } from './components/AvatarContainer';

import './styles.scss';

interface CustomState {
  user: MamaProfile | ProviderProfile;
  isMama: boolean;
  meetStatus?: MeetStatus;
}

export const UserDetailScreen = () => {
  const history = useHistory();
  const location = useLocation();
  const state = location.state as CustomState;
  const user = state.user;
  const isMama = state.isMama;

  const uiStore: UIStore = useStores().uiStore;
  const sessionStore: SessionStore = useStores().sessionStore;
  const myPlan: Plan | null =
    sessionStore.session && sessionStore.session.role !== UserRole.Mama
      ? (sessionStore.session as any).member_plan
      : null;

  const [profile, setProfile] = useState<MamaProfile | ProviderProfile | null>(null);
  const [showReport, setShowReport] = useState(false);
  const [textReport, setTextReport] = useState('');
  const [showReportDone, setShowReportDone] = useState(false);

  useEffect(() => {
    fetchData(state.meetStatus);
  }, []);

  const fetchData = async (meetStatus?: MeetStatus) => {
    const url = isMama ? `mama/providers/${user.id}` : `provider/mama/${user.id}`;
    const res = await get(url, {});
    console.log('get profile res: ', res);
    if (res) {
      setProfile(res);
      if (!isMama && res.meet_status == MeetStatus.Confirming) {
        confirmMeet();
        return;
      }
      if (isMama && meetStatus) {
        const title =
          res.meet_status == MeetStatus.Met
            ? `${user.nick_name}さんとお会いした確認が完了しました`
            : `${user.nick_name}さんとお会いした確認が出来ませんでした`;
        const content =
          res.meet_status == MeetStatus.Met
            ? 'ポイントが500ptが追加されました。<br />マイページの保有ポイント数からご確認ください。'
            : 'こうたさんに確認をしたところ、お会いした確認が取れませんでした。実際にお会いしたのにもかかわらず承認されなかった場合はお問い合わせより運営にご連絡ください。';
        await uiStore.confirm({
          title: title,
          content: content,
          cancelText: '閉じる',
        });
      }
    }
  };

  const confirmMeet = async () => {
    const confirm = await uiStore.confirm({
      title: `${user.nick_name}さんと直接お会いしましたか？`,
      content: `このボタンはお会いした方に対しての評価になりますのでお会いした後は必ず押してください。`,
      confirmText: 'はい',
      cancelText: 'いいえ',
    });

    if (myPlan && myPlan.type < PlanType.Standard) {
      const confirmPlan = await uiStore.confirm({
        title: 'スタンダード会員以上が対象となります。',
        confirmText: '有料会員になる',
        cancelText: '閉じる',
      });
      if (confirmPlan) history.push('/provider/plan');
      return;
    }

    if (confirm) {
      const [_, err] = await toAwait(post(`provider/mama/confirm-met/${user.id}`, {}));
      if (err) {
        console.log('confirm meet error: ', err);
      }
    } else {
      const [_, err] = await toAwait(post(`provider/mama/reject-met/${user.id}`, {}));
      if (err) {
        console.log('reject meet error: ', err);
      }
    }
  };

  const block = async () => {
    const url = isMama ? `mama/blocks/add` : `provider/blocks/add`;
    const data = isMama
      ? {
          provider_id: user.id,
        }
      : { mama_id: user.id };
    const res = await post(url, data);
    if (res) {
      return true;
    }
    return false;
  };

  const addToFavList = async () => {
    const url = isMama ? `mama/favorites/add` : `provider/favorites/add`;
    const data = isMama
      ? {
          provider_id: user.id,
        }
      : { mama_id: user.id };
    const res = await post(url, data);
    if (res) return true;
    return false;
  };

  const removeFromFavList = async () => {
    const url = isMama ? `mama/favorites/remove` : `provider/favorites/remove`;
    const data = isMama
      ? {
          provider_id: user.id,
        }
      : { mama_id: user.id };
    const res = await post(url, data);
    if (res) return true;
    return false;
  };

  const requestMeet = async () => {
    const [_, err] = await toAwait(post('mama/provider/confirm-met/' + user.id, {}));
    if (err) {
      console.log('request meet error: ', err);
      return false;
    }
    return true;
  };

  const report = async () => {
    const loadingId = uiStore.showLoading();
    const [_, err] = await toAwait(
      post('report', {
        user_id: user.id,
        message: textReport,
      })
    );
    if (err) {
      console.log('request report error: ', err);
    } else {
      setShowReport(false);
      setTextReport('');
      setShowReportDone(true);
    }
    uiStore.hideLoading(loadingId);
  };

  const goToMessage = async () => {
    if (!isMama) {
      const userPlan = myPlan?.type;
      if (
        ((userPlan && userPlan < PlanType.Standard) || !myPlan) &&
        sessionStore.session &&
        sessionStore.session?.point < 50
      ) {
        const confirm = await uiStore.confirm({
          title:
            'メッセージを開くためには有料会員になるか、<br/>ポイントを使用をする必要があります',
          content:
            '無料会員としてはお相手のプロフィールを確認することができます。その他お気に入り（相手に通知はされません）やブロック、通報なども可能です。実際に連絡を取ることなどはできません。連絡を取るためには有料会員になる、またはポイントを使用する必要がございます。',
          confirmText: '有料会員になる',
          confirmText2: 'ポイント購入する',
          cancelText: '閉じる',
        });
        if ((confirm as any).confirm2) {
          history.push('/chat-point/packages');
        } else if (confirm == true) {
          history.push('/provider/plan');
        }
        return;
      }
    }
    history.push(`/messages/${profile?.id}`, {
      conversationId: profile?.conversation ? parseInt(profile?.conversation.cid) : null,
    });
  };

  return (
    <>
      <main className="detail">
        <AvatarContainer user={user} />
        <div className="detail-highlight text-center">
          <div className="name">{user.nick_name}</div>
          <div className="detail-highlight--inner">
            <Container className="g-0 flex justify-center items-center">
              <span>
                <span className="detail-pc">年齢　</span>
                {isMama
                  ? `${profile?.age}歳`
                  : profile
                  ? `${Math.floor(profile.age / 10) * 10}代`
                  : ''}
              </span>
              <span className="sep"></span>
              <span>
                <span className="detail-pc">地域　</span>
                {`${getPrefecture(user.prefecture_id)}`}
              </span>
              <span className="sep"></span>
              <span>
                <span className="detail-pc">性別　</span>
                {`${getGender(user.gender)}`}
              </span>
              <span className="sep"></span>
              {isMama ? (
                <span>
                  <span className="detail-pc">系統　</span>
                  {`${getProviderType((user as ProviderProfile).member_type_id)}`}
                </span>
              ) : (
                <span>
                  <span className="detail-pc">系統　</span>
                  {`${getPurpose((user as MamaProfile).purpose_id)}`}
                </span>
              )}
            </Container>
          </div>
        </div>
        <Container className="detail-table">
          <div className="flex flex-column items-center mb-3">
            <Button variant="base" className="white mb-2" onClick={() => goToMessage()}>
              メッセージを送る
            </Button>
            {profile && profile.is_favorite ? (
              <Button
                variant="base"
                className="white"
                onClick={async () => {
                  const loadingId = uiStore.showLoading();
                  const success = await removeFromFavList();
                  if (success) {
                    await fetchData();
                  }
                  uiStore.hideLoading(loadingId);
                }}
              >
                お気に入りを解除
              </Button>
            ) : (
              <Button
                variant="base"
                onClick={async () => {
                  const loadingId = uiStore.showLoading();
                  const success = await addToFavList();
                  if (success) {
                    await fetchData();
                  } else console.error('block error');
                  uiStore.hideLoading(loadingId);
                }}
              >
                お気に入りに追加
              </Button>
            )}
            {isMama && (profile as ProviderProfile)?.meet_status !== MeetStatus.Met && (
              <Button
                variant="purple"
                className="mt-2"
                onClick={async () => {
                  const loadingId = uiStore.showLoading();
                  if ((profile as ProviderProfile)?.meet_status === MeetStatus.Confirming) {
                    await uiStore.confirm({
                      title: `${user.nick_name}さんに確認中です`,
                      contentComponent: (
                        <p>
                          お会いしたお相手の{user.nick_name}さんに確認中です。
                          <br />
                          {user.nick_name}さんからの確認が完了し次第、ポイントが追加されます。
                        </p>
                      ),
                      cancelText: '閉じる',
                    });
                  } else {
                    const confirm = await uiStore.confirm({
                      title: 'この方と直接お会いしましたか？',
                      contentComponent: (
                        <p>
                          このボタンはこのアプリでお約束をした方と直接お会いできた際に押すボタンです。無事アプリ上でお約束した方とお会いできた際にはあなたに換金可能な500ptを付与致します。
                          <br />
                          <br />
                          ※換金は1pt =
                          1円で換算されます。また、換金は10,000pt以上から可能となります。現在の保有ポイントはマイページの「保有ポイント数」からご確認いただけます。
                        </p>
                      ),
                      confirmText: 'はい',
                      cancelText: 'いいえ',
                    });
                    if (confirm) {
                      const success = await requestMeet();
                      if (success) {
                        await uiStore.confirm({
                          title: `${user.nick_name}さんに確認中です`,
                          content: `お会いしたお相手の${user.nick_name}さんに確認中です。${user.nick_name}さんからの確認が完了し次第、ポイントが追加されます。`,
                          cancelText: '閉じる',
                        });
                      }
                      await fetchData();
                    }
                  }
                  uiStore.hideLoading(loadingId);
                }}
              >
                <span className="d-block">実際にお会いした場合押して下さい</span>
                <small>
                  実際にこの方とお会いしたらこのボタンを押してください。お相手に確認が取れ次第あなたに換金可能なポイントが
                  500pt 付与されます。
                </small>
              </Button>
            )}
            {isMama && (profile as ProviderProfile)?.meet_status === MeetStatus.Met && (
              <Button
                variant="purple"
                className="mt-2"
                style={{
                  backgroundColor: 'white',
                }}
              >
                <span className="d-block" style={{ color: 'black' }}>
                  既にお会いしました
                </span>
                <small style={{ color: 'black' }}>
                  この方とは実際に一度お会いしています。
                  <br /> ポイントを獲得できるのは1度だけとなります。
                </small>
              </Button>
            )}
          </div>
          <div className="text-center">
            <label
              className="d-block mb-2"
              style={{ cursor: 'pointer' }}
              onClick={() => setShowReport(true)}
            >
              通報する
            </label>
            <label
              className="d-block"
              style={{ cursor: 'pointer' }}
              onClick={async () => {
                const confirm = await uiStore.confirm({
                  title: '本当にブロックしますか？',
                  content: `${user.nick_name}さんには知らされません。`,
                  confirmText: 'はい',
                  cancelText: 'いいえ',
                });
                if (confirm) {
                  const loadingId = uiStore.showLoading();
                  const success = await block();
                  uiStore.hideLoading(loadingId);
                  if (success) {
                    history.goBack();
                  } else console.error('block error');
                }
              }}
            >
              ブロックする
            </label>
          </div>
          <div className="detail-table--inner">
            <div>
              <Row className="mx-auto items-center">
                <Col lg={6} md={6} xs={6}>
                  <i> {isMama ? '希望時間給' : '年収'}</i>
                </Col>
                <Col lg={6} md={6} xs={6}>
                  <label>
                    {isMama
                      ? getSalaryPerHour((profile as ProviderProfile)?.expect_salary_per_hour)
                      : getAnnualIncome((profile as MamaProfile)?.annual_income_id)}
                  </label>
                </Col>
              </Row>
            </div>
            <div>
              <Row className="mx-auto items-center">
                <Col lg={6} md={6} xs={6}>
                  <i>趣味</i>
                </Col>
                <Col lg={6} md={6} xs={6}>
                  <label>{profile?.hobby}</label>
                </Col>
              </Row>
            </div>
            <div>
              <Row className="mx-auto items-center">
                <Col lg={6} md={6} xs={6}>
                  <i>特技</i>
                </Col>
                <Col lg={6} md={6} xs={6}>
                  <label>{profile?.special_skills}</label>
                </Col>
              </Row>
            </div>
            <div>
              <Row className="mx-auto items-center">
                <Col lg={6} md={6} xs={6}>
                  <i>職業</i>
                </Col>
                <Col lg={6} md={6} xs={6}>
                  <label>{getJob(profile?.profession_id)}</label>
                </Col>
              </Row>
            </div>
            <div>
              <Row className="mx-auto">
                <Col lg={6} md={6} xs={6}>
                  <i>自己紹介</i>
                </Col>
                <Col lg={6} md={6} xs={6}>
                  <label>{profile?.self_introduction}</label>
                </Col>
              </Row>
            </div>
          </div>
        </Container>
      </main>
      {showReport && (
        <Popup
          title="通報理由を入力してください"
          contentComponent={
            <>
              <p className="sub-title">{user.nick_name}さんには知らされません。</p>
              <p className="sub-content">
                お客様に安心してご利用いただけますよう、不正利用を取り締まっております。
                <br />
                お寄せ頂いた情報をもとに、調査・警告・利用停止等の対応を行います。
                <br />
                ※通報する前にご確認ください。
                <br />
                →次のような通報は対応いたしかねます。
                <br />
                ・約束を守らない、返信が来ない
                <br />
                ・当サービス外でのトラブル
                <br />
                ・言い争い等、コミュニケーションのトラブル
                <br />
                ・メッセージなどしつこいアプローチ
                <br />
                ※通報後の返答および通報後の対応に関するご質問にはお答えしておりません。
                <br />
                ※イタズラや虚偽の通報と判断した場合は、通報者に対して利用制限等の対応をさせていただく場合がございます。
              </p>
              <p className="text-center sub-content">
                <textarea
                  className="form-content-report"
                  placeholder="通報理由を200文字以内で入力してください"
                  value={textReport}
                  onChange={(event) => {
                    setTextReport(event.target.value);
                  }}
                ></textarea>
              </p>
            </>
          }
          contentStyle={{ textAlign: 'left', position: 'relative', paddingTop: '15px' }}
          ButtonComponent={
            <div className="button_confirm">
              <button className="confirm" onClick={report}>
                送信
              </button>
            </div>
          }
          onClose={() => setShowReport(false)}
        />
      )}
      {showReportDone && (
        <Popup
          title="通報が完了しました"
          contentComponent={
            <>
              <p className="sub-title">{user.nick_name}さんには知らされません。</p>
              <p className="sub-content">
                お客様に安心してご利用いただけますよう、不正利用を取り締まっております。
                <br />
                お寄せ頂いた情報をもとに、調査・警告・利用停止等の対応を行います。
                <br />
                ※通報する前にご確認ください。
                <br />
                →次のような通報は対応いたしかねます。
                <br />
                ・約束を守らない、返信が来ない
                <br />
                ・当サービス外でのトラブル
                <br />
                ・言い争い等、コミュニケーションのトラブル
                <br />
                ・メッセージなどしつこいアプローチ
                <br />
                ※通報後の返答および通報後の対応に関するご質問にはお答えしておりません。
                <br />
                ※イタズラや虚偽の通報と判断した場合は、通報者に対して利用制限等の対応をさせていただく場合がございます。
              </p>
            </>
          }
          contentStyle={{ textAlign: 'left', position: 'relative', paddingTop: '15px' }}
          ButtonComponent={
            <div className="button_confirm">
              <button className="cancel" onClick={() => setShowReportDone(false)}>
                閉じる
              </button>
            </div>
          }
          onClose={() => setShowReportDone(false)}
        />
      )}
    </>
  );
};
