import { observer } from 'mobx-react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';

import IcBack from 'assets/icons/ic_back.svg';
import useLayout from 'hooks/use-layout';
import { useStores } from 'hooks/use-stores';
import { post } from 'services';
import LayoutStore from 'stores/layout';
import UIStore from 'stores/ui';
import { toAwait } from 'utils';
import { ChangePassComponent } from './change-pass';

import './style.scss';

export const ChangePasswordScreen = observer(() => {
  const history = useHistory();

  const uiStore: UIStore = useStores().uiStore;
  const layoutStore: LayoutStore = useStores().layoutStore;

  useLayout();

  const changePassword = async (oldPass: string, newPass: string) => {
    const loading = uiStore.showLoading();
    const [res, err] = await toAwait(
      post('change_password', {
        old_password: oldPass,
        new_password: newPass,
        password_confirmation: newPass,
      })
    );
    uiStore.hideLoading(loading);
    if (err) {
      console.log('cannot reset password: ', err);
      return;
    }

    await uiStore.confirm({
      title: 'パスワードの変更が完了しました',
      cancelText: '閉じる',
    });
    history.goBack();
  };

  return (
    <>
      <div className="header-with-back">
        <button onClick={() => history.goBack()}>
          <img src={IcBack} alt="" />
        </button>

        {!layoutStore.isShowHeader && <div className="title">パスワード変更</div>}
      </div>

      <Container className="pwdpage">
        <Helmet>
          <title>パスワード変更</title>
        </Helmet>
        <h3 className="mb-3 title">パスワード変更</h3>
        <ChangePassComponent onSubmitPassword={changePassword} />
      </Container>
    </>
  );
});
