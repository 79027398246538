import LogoMobile from 'assets/images/logo-mobile.svg';
import Logo from 'assets/images/logo.svg';
import { useEffect, useState } from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import './header.scss';

export const PublicHeader = ({ showLoginBtn, showRegisterBtn }: any) => {
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    if (expanded) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [expanded]);

  const gotoGuideMobile = () => {
    const ua = navigator.userAgent;
    if (/android/i.test(ua)) {
      window.location.href = 'https://youtu.be/--4pargmz7M';
    } else if (/iPad|iPhone|iPod/.test(ua)) {
      window.location.href = 'https://youtu.be/imeQ-7AGfuY';
    }
  };

  return (
    <div className="header">
      <NavLink to="/" exact className="navbar-brand">
        <img src={Logo} alt="desire" className="logo-dt" />
        <img src={LogoMobile} alt="desire" className="logo-mb" />
      </NavLink>

      <div className="menu-header">
        {showLoginBtn && (
          <NavLink to="/register" className="nav-link">
            <label>新規会員登録はこちら</label>
          </NavLink>
        )}
        {showRegisterBtn && (
          <NavLink to="/login" className="nav-link">
            <label>既存会員はこちら</label>
          </NavLink>
        )}
      </div>

      <div className="guide-mobile" onClick={gotoGuideMobile}>
        ホーム画面への
        <br />
        追加方法はこちら
      </div>

      <div className="menu-mobile">
        <Navbar expand="lg" expanded={expanded}>
          <Navbar.Toggle
            aria-controls="basic-navbar-nav"
            onClick={() => setExpanded(expanded ? false : true)}
          />
          <Navbar.Collapse id="basic-navbar-nav" style={{ flex: 0 }}>
            <span className="mama-close" onClick={() => setExpanded(false)}>
              ×
            </span>
            <Nav className="me-auto">
              <NavLink to={'/company'} className="nav-link" onClick={() => setExpanded(false)}>
                会社概要
              </NavLink>
              <NavLink to={'/rules'} className="nav-link" onClick={() => setExpanded(false)}>
                利用規約
              </NavLink>
              <NavLink to={'/inquiry'} className="nav-link" onClick={() => setExpanded(false)}>
                広告希望企業様募集中
              </NavLink>
              <NavLink to={'/policy'} className="nav-link" onClick={() => setExpanded(false)}>
                プライバシーポリシー
              </NavLink>
              <NavLink to={'/display'} className="nav-link" onClick={() => setExpanded(false)}>
                特定商取引に基づく表示
              </NavLink>
              <NavLink to={'/inquiry'} className="nav-link" onClick={() => setExpanded(false)}>
                お問い合わせ
              </NavLink>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
    </div>
  );
};
