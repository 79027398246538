import { useRef, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';

import IconSend from 'assets/icons/ic_send.svg';
import { MessageType } from 'config/constants';

interface IProps {
  onSend: (type: number, text?: string, imageFiles?: Array<File>) => void;
  meetBtn?: any;
}

export const InputField = ({ onSend, meetBtn }: IProps) => {
  const inputRef = useRef<any>(null);

  //   const [files, setFiles] = useState<Array<File>>([]);
  const [text, setText] = useState('');

  const handleSendClick = () => {
    // const type = files.length > 0 ? MessageType.Images : MessageType.Text;
    onSend(MessageType.Text, text, []);
    setText('');
    // setFiles([]);
  };

  return (
    <>
      <div className="message-detail-chat col-lg-12 col-md-12 col-xs-12">
        <textarea
          className="form-control"
          placeholder="メッセージを入力してください。"
          value={text}
          onChange={(event) => {
            setText(event.target.value);
          }}
        ></textarea>
        <Row className="g-0">
          <Col lg={3}>
            <Button
              variant="base"
              className="file"
              onClick={() => {
                inputRef.current?.click();
              }}
            >
              ファイルの選択
            </Button>
          </Col>
          <Col style={{ flex: 1 }}>
            <Button variant="link" className="text-start">
              ファイルが選択されていません。
            </Button>
          </Col>
          {meetBtn && (
            <Col lg={2} style={{ marginRight: '10px' }}>
              {meetBtn}
            </Col>
          )}
          <Col lg={2}>
            <Button
              variant="base"
              className="send"
              disabled={text === ''}
              onClick={handleSendClick}
            >
              送信
            </Button>
          </Col>
        </Row>
      </div>
      <div className="message-detail-chat-mobile">
        <div>
          <Button
            variant="base"
            className="file"
            onClick={() => {
              inputRef.current?.click();
            }}
          >
            ファイルの選択
          </Button>
          <textarea
            className="form-control"
            value={text}
            placeholder="メッセージを入力してください。"
            onChange={(event) => {
              setText(event.target.value);
            }}
          ></textarea>
          <Button variant="base" className="send" disabled={text === ''} onClick={handleSendClick}>
            <img src={IconSend} alt="" />
          </Button>
        </div>
      </div>
      <input
        className="d-none"
        type="file"
        accept=".png,.jpg,.gif"
        ref={inputRef}
        onChange={(event) => {
          if (event.target.files && event.target.files?.length > 0) {
            // setFiles([...files, event.target.files[0]]);
            onSend(MessageType.Images, '', [event.target.files[0]]);
          }
        }}
      ></input>
    </>
  );
};
