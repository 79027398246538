import { action, makeObservable, observable } from 'mobx';

class LayoutStore {
  @observable isShowHeader: boolean = true;
  @observable isShowFooter: boolean = true;

  constructor() {
    makeObservable(this);
  }

  @action
  showHeader = () => {
    this.isShowHeader = true;
  };

  @action
  hideHeader = () => {
    this.isShowHeader = false;
  };

  @action
  showFooter = () => {
    this.isShowFooter = true;
  };

  @action
  hideFooter = () => {
    this.isShowFooter = false;
  };
}

export default LayoutStore;
