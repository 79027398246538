import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import IconCompass from 'assets/icons/ic_compass_small.svg';
import IconHoldHand from 'assets/icons/ic_hold_hand.svg';
import IconMoneyBag from 'assets/icons/ic_money_bag.svg';
import IconProviderType from 'assets/icons/ic_provider_type.svg';
import DefaultAvatar from 'assets/images/img_default_avatar.svg';
import { useStores } from 'hooks/use-stores';
import { MamaProfile, ProviderProfile } from 'modules/user/models';
import SessionStore from 'stores/session';
import {
  getAnnualIncome,
  getPrefecture,
  getProviderService,
  getProviderType,
  getPurpose,
  getSalaryPerHour,
} from 'utils';

interface IProps {
  user: MamaProfile | ProviderProfile;
  isMama: boolean;
}

export const UserItem = ({ user, isMama }: IProps) => {
  const sessionStore: SessionStore = useStores().sessionStore;

  const renderProviderItem = () => (
    <Col className="item">
      <div className="row-item">
        <div className="position-relative">
          <div className="mb-1 mx-auto position-relative img-wrapper">
            <div className="gift_rec_wrap">
              {(user as ProviderProfile).member_services?.includes(1) && (
                <span className="mypage--lbl-status status-red">{getProviderService(1)}</span>
              )}
              {(user as ProviderProfile).member_services?.includes(2) && (
                <span className="mypage--lbl-status status-orange">{getProviderService(2)}</span>
              )}
              {(user as ProviderProfile).member_services?.includes(3) && (
                <span className="mypage--lbl-status status-purple">{getProviderService(3)}</span>
              )}
            </div>
            {(user as ProviderProfile).is_receive_gif ? (
              <span className="mypage--lbl-status-bottom">物品提供 でも可</span>
            ) : null}
            <img src={user.avatar || DefaultAvatar} className="avatar" alt="Not found" />
          </div>
        </div>
        <div className="text-center">
          <span className="name">{user.nick_name}</span>
          {(user as ProviderProfile).member_services?.includes(1) && (
            <span className="mypage--lbl-status status-red mobile">{getProviderService(1)}</span>
          )}
          {(user as ProviderProfile).member_services?.includes(2) && (
            <span className="mypage--lbl-status status-orange mobile">{getProviderService(2)}</span>
          )}
          {(user as ProviderProfile).member_services?.includes(3) && (
            <span className="mypage--lbl-status status-purple mobile">{getProviderService(3)}</span>
          )}
        </div>
        <Row>
          <Col lg="auto" className="sub-item">
            <img src={IconHoldHand} alt="Not found" /> <label>{`${user.age}歳`}</label>
          </Col>
          <Col lg="auto" className="sub-item">
            <img src={IconCompass} alt="Not found" />
            <label>{getPrefecture(user.prefecture_id)}</label>
          </Col>
        </Row>
        <Row>
          <Col lg="auto" className="sub-item">
            <img src={IconMoneyBag} alt="Not found" />
            <label>{`${getSalaryPerHour(
              (user as ProviderProfile).expect_salary_per_hour
            )} / 時`}</label>
          </Col>
        </Row>
        <Row>
          <Col lg="auto" className="sub-item">
            <img src={IconProviderType} alt="Not found" />
            <label>{getProviderType((user as ProviderProfile).member_type_id)}</label>
          </Col>
        </Row>
      </div>
    </Col>
  );

  const renderMamaItem = () => (
    <Col md={4} lg="auto" className="col-lg-2p4 item">
      <div className="row-item">
        <div className="provider-search--items text-center">
          <img src={user.avatar || DefaultAvatar} className="mb-1 mx-auto avatar" alt="" />

          <div>
            <span className="name">{user.nick_name}</span>
          </div>
          <Row>
            <Col lg="auto" className="sub-item sub-item-first">
              <img src={IconHoldHand} alt="" />{' '}
              <label>{`${Math.floor(user.age / 10) * 10}代`}</label>
            </Col>
            <Col lg="auto" className="sub-item sub-item-first">
              <img src={IconCompass} alt="" /> <label>{getPrefecture(user.prefecture_id)}</label>
            </Col>
          </Row>
          <Row>
            <Col lg="auto" className="sub-item sub-item-second">
              <img src={IconMoneyBag} alt="" />
              <label>{getAnnualIncome((user as MamaProfile).annual_income_id)}</label>
            </Col>
          </Row>
          <Row>
            <Col lg="auto" className="sub-item sub-item-second">
              <img src={IconProviderType} alt="" />
              <label>{getPurpose((user as MamaProfile).purpose_id)}</label>
            </Col>
          </Row>
        </div>
      </div>
    </Col>
  );

  return (
    <Link
      to={{
        pathname: sessionStore.session ? `/search/${user.id}` : `/register`,
        state: { user, isMama },
      }}
    >
      {isMama ? renderProviderItem() : renderMamaItem()}
    </Link>
  );
};
