import { observer } from 'mobx-react';
import { Spinner } from 'react-bootstrap';

import { useStores } from 'hooks/use-stores';
import UIStore from 'stores/ui';

import './style.scss';

export const LoadingSpinner = observer(() => {
  const uiStore: UIStore = useStores().uiStore;

  return (
    <div id="loading" className={uiStore.isShowLoading ? 'show' : ''}>
      <Spinner animation="border" />
    </div>
  );
});
