import * as React from 'react';

export class VideoInputButton extends React.Component<any, any> {
  fileRef = React.createRef<HTMLInputElement>();

  onClick = (e: any) => {
    e.preventDefault();
    if (this.fileRef.current) this.fileRef.current.click();
  };

  onChanged = (e: any) => {
    if (!!this.fileRef.current?.files?.length) {
      this.setState({
        src: URL.createObjectURL(this.fileRef.current?.files[0]),
      });
      this.props.onChange(this.fileRef.current.files[0]);
    }
  };

  render() {
    return (
      <>
        <button onClick={this.onClick}>動画をアップロード</button>
        <input
          type="file"
          name="avatar"
          accept="video/*"
          onChange={this.onChanged}
          className="d-none"
          ref={this.fileRef}
        />
      </>
    );
  }
}
