import { MantineProvider } from '@mantine/core';
import 'dayjs/locale/ja';
import { Provider } from 'mobx-react';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { AlertModal } from 'components/common/alertModal';
import { ConfirmModal } from 'components/common/confirmModal';
import { LoadingSpinner } from 'components/common/loading';
import InAppMessage from 'components/in-app-message';
import { DefaultLayoutRoute } from 'components/layouts/default';
import { ScrollToTop } from 'components/layouts/scrollToTop';
import { CompanyScreen } from 'screens/about/company';
import { DisplayScreen } from 'screens/about/display';
import { InquiryScreen } from 'screens/about/inquiry';
import { NotfoundScreen } from 'screens/about/notfound';
import { PolicyScreen } from 'screens/about/policy';
import { RuleScreen } from 'screens/about/rules';
import { ChangePasswordScreen } from 'screens/change-password';
import { ChatScreen } from 'screens/chat';
import { ChatPointPackageScreen } from 'screens/chat-point/packages';
import { FaqScreen } from 'screens/faq';
import { ForgotPasswordScreen } from 'screens/forgot-password';
import { HomeScreen } from 'screens/home';
import { LoginScreen } from 'screens/login';
import MamaPolicy from 'screens/mama-safety-policies';
import { MamaBlockScreen } from 'screens/mama/block';
import { MamaEditScreen } from 'screens/mama/edit-profile';
import { MamaFavoriteScreen } from 'screens/mama/favorite';
import { MatchHistoryScreen } from 'screens/mama/match-history';
import { MamaMypageScreen } from 'screens/mama/mypage';
import { PointManageScreen } from 'screens/mama/point';
import { PointTransferScreen } from 'screens/mama/point-transfer';
import { OrderListScreen } from 'screens/mama/special-order';
import { SpecialOrderConversationListScreen } from 'screens/mama/special-order-conversations';
import { OrderDetailScreen } from 'screens/mama/special-order-detail';
import { MessageListScreen } from 'screens/message-list';
import { NewsDetailScreen } from 'screens/news/detail';
import { NewsListScreen } from 'screens/news/list';
import { ProviderCheckScreen } from 'screens/provider-check';
import { ProviderBlockScreen } from 'screens/provider/block';
import { ProviderEditScreen } from 'screens/provider/edit-profile';
import { ProviderFavoriteScreen } from 'screens/provider/favorite';
import { ProviderMypageScreen } from 'screens/provider/mypage';
import { PlanScreen } from 'screens/provider/plan';
import { ProviderOrderListScreen } from 'screens/provider/special-order';
import { MamaRegisterScreen } from 'screens/register/Mama';
import { ProviderRegisterScreen } from 'screens/register/Provider';
import { RegisterSnsScreen } from 'screens/register/RegisterSnsScreen';
import { RegisterTypeScreen } from 'screens/register/RegisterTypeScreen';
import { UserDetailScreen } from 'screens/user-details';
import { UserListScreen } from 'screens/user-list';
import {
  askForPermissionToReceiveNotifications,
  isSupportedPush,
  listenToNotify,
  setFCMToken,
} from 'services/firebase/messaging';
import stores from 'stores';

import 'assets/styles/main.scss';
import 'react-toastify/dist/ReactToastify.css';

const App = () => {
  useEffect(() => {
    isSupportedPush()
      .then((isSupport) => {
        if (isSupport) {
          getFCMToken();
        }
      })
      .catch((error) => console.warn(error));
  }, []);

  const getFCMToken = async () => {
    try {
      const token = await askForPermissionToReceiveNotifications();
      setFCMToken(token);
      stores.sessionStore.saveFCMToken();
      listenToNotify((messagePayload) => {
        stores.notificationStore.showNotify('new_message');
      });
    } catch (error) {
      console.warn(error);
    }
  };

  return (
    <MantineProvider withGlobalStyles withNormalizeCSS>
      <Provider {...stores}>
        <Helmet>
          <title>Desire</title>
        </Helmet>

        <Router>
          <ScrollToTop>
            <Switch>
              <DefaultLayoutRoute path="/login" hideFooter={true}>
                <LoginScreen />
              </DefaultLayoutRoute>
              <DefaultLayoutRoute
                path="/register/mama"
                hideFooter
                showLoginBtn={false}
                showRegisterBtn={false}
              >
                <MamaRegisterScreen />
              </DefaultLayoutRoute>
              <DefaultLayoutRoute path="/register/provider" hideFooter>
                <ProviderRegisterScreen />
              </DefaultLayoutRoute>
              <DefaultLayoutRoute path="/register/sns" hideFooter>
                <RegisterSnsScreen />
              </DefaultLayoutRoute>
              <DefaultLayoutRoute path="/register" hideFooter>
                <RegisterTypeScreen />
              </DefaultLayoutRoute>
              <DefaultLayoutRoute path="/provider/mypage" private>
                <ProviderMypageScreen />
              </DefaultLayoutRoute>
              <DefaultLayoutRoute path="/provider-check">
                <ProviderCheckScreen />
              </DefaultLayoutRoute>
              <DefaultLayoutRoute path="/mama/mypage" private>
                <MamaMypageScreen />
              </DefaultLayoutRoute>
              <DefaultLayoutRoute path="/provider/edit" private>
                <ProviderEditScreen />
              </DefaultLayoutRoute>
              <DefaultLayoutRoute path="/mama/edit" private>
                <MamaEditScreen />
              </DefaultLayoutRoute>
              <DefaultLayoutRoute path="/company" showLoginBtn={false} showRegisterBtn={false}>
                <CompanyScreen />
              </DefaultLayoutRoute>
              <DefaultLayoutRoute path="/faq">
                <FaqScreen />
              </DefaultLayoutRoute>
              <DefaultLayoutRoute path="/display">
                <DisplayScreen />
              </DefaultLayoutRoute>
              <DefaultLayoutRoute path="/policy" showLoginBtn={false} showRegisterBtn={false}>
                <PolicyScreen />
              </DefaultLayoutRoute>
              <DefaultLayoutRoute path="/rules" showLoginBtn={false} showRegisterBtn={false}>
                <RuleScreen />
              </DefaultLayoutRoute>
              <DefaultLayoutRoute path="/inquiry">
                <InquiryScreen />
              </DefaultLayoutRoute>
              <DefaultLayoutRoute path="/forgot-password">
                <ForgotPasswordScreen />
              </DefaultLayoutRoute>
              <DefaultLayoutRoute path="/change-password" private>
                <ChangePasswordScreen />
              </DefaultLayoutRoute>
              <DefaultLayoutRoute path="/news" private>
                <NewsListScreen />
              </DefaultLayoutRoute>
              <DefaultLayoutRoute path="/news-detail" private>
                <NewsDetailScreen />
              </DefaultLayoutRoute>
              <DefaultLayoutRoute path="/search/:id" private>
                <UserDetailScreen />
              </DefaultLayoutRoute>
              <DefaultLayoutRoute path="/search" private>
                <UserListScreen />
              </DefaultLayoutRoute>
              <DefaultLayoutRoute path="/provider/blocks" private>
                <ProviderBlockScreen />
              </DefaultLayoutRoute>
              <DefaultLayoutRoute path="/provider/favorites" private>
                <ProviderFavoriteScreen />
              </DefaultLayoutRoute>
              <DefaultLayoutRoute path="/mama/blocks" private>
                <MamaBlockScreen />
              </DefaultLayoutRoute>
              <DefaultLayoutRoute path="/mama/favorites" private>
                <MamaFavoriteScreen />
              </DefaultLayoutRoute>
              <DefaultLayoutRoute path="/messages" exact private>
                <MessageListScreen />
              </DefaultLayoutRoute>
              <DefaultLayoutRoute path="/messages/:partnerId" private>
                <ChatScreen />
              </DefaultLayoutRoute>
              <DefaultLayoutRoute path="/mama/special" exact private>
                <OrderListScreen />
              </DefaultLayoutRoute>
              <DefaultLayoutRoute path="/mama/special/conversations" exact private>
                <SpecialOrderConversationListScreen />
              </DefaultLayoutRoute>
              <DefaultLayoutRoute path="/mama/special/:orderId" private>
                <OrderDetailScreen />
              </DefaultLayoutRoute>
              <DefaultLayoutRoute path="/provider/special" private>
                <ProviderOrderListScreen />
              </DefaultLayoutRoute>
              <DefaultLayoutRoute path="/mama/point" private>
                <PointManageScreen />
              </DefaultLayoutRoute>
              <DefaultLayoutRoute path="/mama/match-history" private>
                <MatchHistoryScreen />
              </DefaultLayoutRoute>
              <DefaultLayoutRoute path="/mama/point-transfer" private>
                <PointTransferScreen />
              </DefaultLayoutRoute>
              <DefaultLayoutRoute path="/provider/plan" private>
                <PlanScreen />
              </DefaultLayoutRoute>
              <DefaultLayoutRoute path="/chat-point/packages" private>
                <ChatPointPackageScreen />
              </DefaultLayoutRoute>
              <DefaultLayoutRoute exact path="/">
                <HomeScreen />
              </DefaultLayoutRoute>
              <Route path="/mama-policies">
                <MamaPolicy />
              </Route>

              <DefaultLayoutRoute path="*">
                <NotfoundScreen />
              </DefaultLayoutRoute>
            </Switch>
          </ScrollToTop>

          <ConfirmModal />
          <AlertModal />
        </Router>
        <InAppMessage />
        <LoadingSpinner />
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss={false}
          draggable
          pauseOnHover={false}
        />
      </Provider>
    </MantineProvider>
  );
};

export default App;
