import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';

import verifiedDocumentTemplate from '../../../assets/images/verified_document_template.png';
import { FileInputButton } from '../../../components/FileInputButton';
import { Popup } from '../../../components/Popup';

interface IProps {
  onFileChange: any;
  oldValue?: string;
  isMama?: boolean;
}

export const FileSelectorPopup = ({ onFileChange, oldValue, isMama }: IProps) => {
  const [src, setSrc] = useState<any>(null);
  const [popup, setPopup] = useState(false);
  const [confirm, setConfirm] = useState(false);

  useEffect(() => {
    if (oldValue) setSrc(oldValue);
  }, [oldValue]);

  let bgStyle = !!src
    ? {
        backgroundImage: `url(${src})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
      }
    : {};

  return (
    <div className="bg_upload_form" style={bgStyle}>
      {/**
       * this component is used in both Provider & Mama register form.
       * only show if the user is a Mama
       */}
      {isMama
        ? !src && (
            <>
              <p className="notes">名前、生年月日が見えればOK</p>
              <img className="verified-template-image" src={verifiedDocumentTemplate} alt="" />
            </>
          )
        : ''}
      <Button
        className="upload_btn"
        style={{
          zIndex: 1,
        }}
        onClick={(e) => {
          e.preventDefault();
          if (oldValue) {
            setConfirm(true);
          } else {
            setPopup(true);
          }
        }}
      >
        アップロード
      </Button>
      {confirm && (
        <Popup
          title="本人確認書類"
          contentComponent={
            <>
              <div className="preview_old_identity" style={bgStyle}></div>
              <p>
                本人確認書類の内容が変更した際は再度申請をお願いいたします。
                <br />
                再申請中はアカウントは一時停止いたしますがデータは削除されません。
              </p>
            </>
          }
          ButtonComponent={
            <div className="button_confirm">
              <button
                className="confirm"
                onClick={(e) => {
                  e.preventDefault();
                  setConfirm(false);
                  setPopup(true);
                }}
              >
                再申請
              </button>
              <button className="cancel" onClick={() => setConfirm(false)}>
                閉じる
              </button>
            </div>
          }
          onClose={() => setConfirm(false)}
        />
      )}
      {popup && (
        <Popup
          title="本人確認書類をアップロードしてください"
          contentComponent={
            <p>
              本サービスは18歳以上の方のみご利用いただけます。以下の本人確認書類から１つお選びいただいた上で写真のアップロードをお願いいたします。
              <br />
              ＊運転免許証
              <br />
              ＊健康保険証
              <br />
              ＊パスポート
              <br />
              ＊マイナンバーカード
              <br />
              ＊その他本人確認が可能な書類（在留カードなど）
            </p>
          }
          contentStyle={{ textAlign: 'left' }}
          ButtonComponent={
            <FileInputButton
              onChange={(file: File) => {
                setSrc(URL.createObjectURL(file));
                setPopup(false);
                onFileChange(file);
              }}
            />
          }
          onClose={() => setPopup(false)}
        />
      )}
    </div>
  );
};
