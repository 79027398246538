export const FIREBASE_COLLECTION_CONVERSATIONS = 'conversations';
export const FIREBASE_COLLECTION_MESSAGES = 'messages';

export enum UserRole {
  Mama = 1,
  Provider = 2,
}

export enum SocialProvider {
  Facebook = 'facebook',
  Line = 'line',
}

export enum Gender {
  Female = 0,
  Male = 1,
  Other = 2,
}

export const PREFECTURES: { [key: number]: string } = {
  1: '東京都',
  2: '神奈川県',
  3: '大阪府',
  4: '愛知県',
  5: '埼玉県',
  6: '千葉県',
  7: '兵庫県',
  8: '北海道',
  9: '福岡県',
  10: '静岡県',
  11: '茨城県',
  12: '広島県',
  13: '京都府',
  14: '新潟県',
  15: '宮城県',
  16: '長野県',
  17: '岐阜県',
  18: '群馬県',
  19: '栃木県',
  20: '福島県',
  21: '岡山県',
  22: '三重県',
  23: '熊本県',
  24: '鹿児島県',
  25: '山口県',
  26: '愛媛県',
  27: '長崎県',
  28: '滋賀県',
  29: '沖縄県',
  30: '奈良県',
  31: '青森県',
  32: '岩手県',
  33: '大分県',
  34: '石川県',
  35: '山形県',
  36: '宮崎県',
  37: '富山県',
  38: '秋田県',
  39: '和歌山県',
  40: '香川県',
  41: '山梨県',
  42: '佐賀県',
  43: '福井県',
  44: '徳島県',
  45: '高知県',
  46: '島根県',
  47: '鳥取県',
};

export const ANNUAL_INCOMES: { [key: number]: string } = {
  1: '50万円～200万円',
  2: '201万円～400万円',
  3: '401万円～600万円',
  4: '600万円以上',
};

export const GENDERS = [
  {
    label: '女',
    value: Gender.Female,
  },
  {
    label: '男',
    value: Gender.Male,
  },
  {
    label: 'その他',
    value: Gender.Other,
  },
];

export const OCCUPATIONS: { [key: number]: string } = {
  1: '会社経営',
  2: '会社役員',
  3: '投資家',
  4: '自営業',
  5: '高収入サラリーマン',
  6: '医師',
  7: '弁護士',
  8: 'フリーター',
  9: '公務員',
  10: '金融機関',
  11: '不動産関連',
  12: '不労所得',
  13: 'その他',
};

export const PURPOSES: { [key: number]: string } = {
  1: '食事',
  2: '飲酒',
  3: 'ショッピング',
  4: '旅行',
  5: 'マッサージ',
};

export const PROVIDER_TYPES: { [key: string]: string } = {
  1: '草食系',
  2: '肉食系',
  3: '体育会系',
  4: 'インテリ系',
};

export const EXPECTED_HOURLY_RATES: { [key: string]: string } = {
  1: '1,000円',
  2: '2,000円',
  3: '3,000円',
  4: '4,000円',
  5: '5,000円',
};

export const SERVICES: { [key: string]: string } = {
  1: 'レンタル彼氏',
  2: 'マッサージ',
  3: 'レズビアン',
};

export enum MeetStatus {
  Confirming = 1,
  Met = 2,
  NotMet = 3,
}

export enum MessageType {
  Text = 1,
  Images = 2,
  Video = 3,
  Other = 4,
}

export enum NewsType {
  RequestMeet = 1,
  ConfirmMeet = 2,
  RejectMeet = 3,
  System = 4,
}

export enum PlanType {
  Free = 1,
  Standard = 2,
  Gold = 3,
}

export const PlanName = {
  [PlanType.Free]: "無料",
  [PlanType.Standard]: "スタンダード",
  [PlanType.Gold]: "ゴールド",
}