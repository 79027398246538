import { useEffect, useState } from 'react';

import './style.scss';

export type PaginationData = {
  currentPage: number;
  limit: number;
  total: number;
};

interface IPaginationProps {
  pagination: PaginationData;
  onClickPage: (page: number) => any;
}

export const Pagination = ({ pagination, onClickPage }: IPaginationProps) => {
  const [pageRange, setRange] = useState<Array<number>>([]);
  useEffect(() => {
    setRange(generatePageRange());
  }, [pagination]);

  const generatePageRange = (): Array<number> => {
    const { currentPage, limit, total } = pagination;
    let maxPage = Math.ceil(total / limit);

    let pages: Array<number> = [currentPage];
    if (pages[0] > 1) {
      pages.unshift(pages[0] - 1);
    } else {
      if (pages[pages.length - 1] < maxPage) pages.push(pages[pages.length - 1] + 1);
      else return pages;
    }

    if (pages[pages.length - 1] < maxPage) {
      pages.push(pages[pages.length - 1] + 1);
    } else {
      if (pages[0] > 1) pages.unshift(pages[0] - 1);
      else return pages;
    }

    if (pages[pages.length - 1] < maxPage) {
      pages.push(pages[pages.length - 1] + 1);
    } else {
      if (pages[0] > 1) pages.unshift(pages[0] - 1);
      else return pages;
    }
    return pages;
  };

  return (
    <div id="pagination">
      <div className="pagination_area">
        {pagination.currentPage > 1 && (
          <p onClick={() => onClickPage(pagination.currentPage - 1)}>&laquo;</p>
        )}
        {pageRange.map((page) => (
          <p
            key={page}
            className={page === pagination.currentPage ? 'pagination_selected' : ''}
            onClick={() => onClickPage(page)}
          >
            {page}
          </p>
        ))}
        {pagination.currentPage < Math.ceil(pagination.total / pagination.limit) && (
          <p onClick={() => onClickPage(pagination.currentPage + 1)}>&raquo;</p>
        )}
      </div>
    </div>
  );
};
