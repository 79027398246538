import { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';

import { Pagination, PaginationData } from 'components/common/pagination';
import { NewsType, UserRole } from 'config/constants';
import { useStores } from 'hooks/use-stores';
import { News } from 'modules/news/models';
import { get } from 'services';
import NotificationStore from 'stores/notification';
import SessionStore from 'stores/session';
import UIStore from 'stores/ui';
import { toAwait } from 'utils';
import { formatDateTime } from 'utils/datetime';

import './style.scss';

export function NewsListScreen() {
  const history = useHistory();

  const uiStore: UIStore = useStores().uiStore;
  const sessionStore: SessionStore = useStores().sessionStore;
  const notificationStore: NotificationStore = useStores().notificationStore;

  const [news, setNews] = useState<Array<News>>([]);
  const [pagination, setPagination] = useState<PaginationData>({
    currentPage: 1,
    limit: 30,
    total: 0,
  });

  useEffect(() => {
    if (sessionStore.session) {
      getData(1);
    }
  }, [sessionStore]);

  const getData = async (page: number) => {
    const user = sessionStore.session;
    const loading = uiStore.showLoading();
    const url = user?.role === UserRole.Mama ? 'mama/notifications' : 'provider/notifications';
    const [res, err] = await toAwait(get(url, { page: page }));
    uiStore.hideLoading(loading);
    if (err) {
      console.log('error when list news: ', err);
    } else {
      setPagination({
        currentPage: page,
        limit: res.per_page,
        total: res.total,
      });
      setNews(res.data);
    }
  };

  const handleClickNews = async (item: News) => {
    const loading = uiStore.showLoading();
    const url =
      sessionStore.session?.role === UserRole.Mama
        ? 'mama/notifications/'
        : 'provider/notifications/';
    await toAwait(get(url + item.id, {}));
    notificationStore.getUnreadCount();
    if (item.type === NewsType.RequestMeet) {
      const mamaId = item.object_id;
      const [res, err] = await toAwait(get(`provider/mama/${mamaId}`, {}));
      uiStore.hideLoading(loading);
      if (err) {
        console.log('get mama profile err: ', err);
      } else {
        history.push('/search/' + mamaId, { user: res, isMama: false, meetStatus: item.type });
      }
    } else if (item.type === NewsType.ConfirmMeet || item.type === NewsType.RejectMeet) {
      const providerId = item.object_id;
      const [res, err] = await toAwait(get(`mama/providers/${providerId}`, {}));
      uiStore.hideLoading(loading);
      if (err) {
        console.log('get provider profile err: ', err);
      } else {
        history.push('/search/' + providerId, { user: res, isMama: true, meetStatus: item.type });
      }
    } else {
      uiStore.hideLoading(loading);
      history.push('/news-detail', { data: item });
    }
  };

  return (
    <div className="news-list">
      <Helmet>
        <title>お知らせ</title>
      </Helmet>
      <Container>
        <div className="text-center top-title-h1">
          <span>NEWS</span>
        </div>
        <div className="sub-title">お知らせ</div>
        <div className="news-list-inner">
          {news?.map((item) => (
            <Row key={item.id}>
              <Col
                className="flex flex-column justify-center"
                onClick={() => handleClickNews(item)}
              >
                <p className="time">{formatDateTime(item.created_at)}</p>
                <div>
                  <p className="title">{item.name}</p>
                </div>
              </Col>
            </Row>
          ))}
        </div>

        <Pagination pagination={pagination} onClickPage={(page) => getData(page)} />
      </Container>
    </div>
  );
}
