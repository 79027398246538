import * as React from 'react';

export class ImageInputButton extends React.Component<any, any> {
  fileRef = React.createRef<HTMLInputElement>();

  onClick = (e: any) => {
    e.preventDefault();
    if (this.fileRef.current) this.fileRef.current.click();
  };

  onChanged = (e: any) => {
    if (!!this.fileRef.current?.files?.length) {
      this.setState({
        src: URL.createObjectURL(this.fileRef.current?.files[0]),
      });
      this.props.onChange(this.fileRef.current.files[0]);
    }
  };

  render() {
    return (
      <>
        <button onClick={this.onClick}>写真をアップロード</button>
        <input
          type="file"
          name="avatar"
          accept="image/png, image/jpeg"
          onChange={this.onChanged}
          className="d-none"
          ref={this.fileRef}
        />
      </>
    );
  }
}
