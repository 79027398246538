import { useEffect, useState } from 'react';

import { Popup } from '../../../components/Popup';
import { VideoInputButton } from '../../../components/VideoInputButton';

interface IProps {
  onFileChange: any;
  oldValue?: string;
}

export const VideoSelectorPopup = ({ onFileChange, oldValue }: IProps) => {
  const [src, setSrc] = useState<any>(null);
  const [popup, setPopup] = useState(false);

  useEffect(() => {
    if (oldValue) setSrc(oldValue);
  }, [oldValue]);

  return (
    <div className="session_images_round">
      <div className="d-flex">
        <div className="round_register">
          <div className="content_round_register">
            {!!src && (
              <video
                src={src}
                style={{
                  position: 'absolute',
                  width: '100%',
                  height: '100%',
                  zIndex: 0,
                  borderRadius: '50%',
                }}
              />
            )}
            <button
              style={{ border: 0, zIndex: 1 }}
              className="text_round_register"
              onClick={(e: any) => {
                e.preventDefault();
                setPopup(true);
              }}
            >
              ファイルの選択
            </button>
          </div>
          {popup && (
            <Popup
              title={oldValue ? '' : '動画をアップロードしてください'}
              content=""
              contentComponent={
                oldValue ? (
                  <>
                    <video src={src} className="preview_old_profile" controls />
                    <p>プロフィール動画を変更する場合は写真をアップロードしなおしてください。</p>
                    <p>動画は25MBまでのものが登録できます</p>
                  </>
                ) : (
                  <p>動画は25MBまでのものが登録できます</p>
                )
              }
              ButtonComponent={
                <VideoInputButton
                  onChange={(file: File) => {
                    setSrc(URL.createObjectURL(file));
                    setPopup(false);
                    onFileChange(file);
                  }}
                />
              }
              onClose={() => setPopup(false)}
            />
          )}
        </div>
      </div>
    </div>
  );
};
