import './style.scss';

interface IProps {
  pre?: string;
  className?: string;
  children: any;
}

export const Paragraph = (props: IProps) => {
  return (
    <p className={`${props.className} custom-paragraph`}>
      {props.pre && <span>{props.pre}</span>}
      <span>{props.children}</span>
    </p>
  );
};
