import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { Route, useHistory } from 'react-router-dom';

import { useStores } from 'hooks/use-stores';
import LayoutStore from 'stores/layout';
import NotificationStore from 'stores/notification';
import SessionStore from 'stores/session';
import { Footer } from './footer';
import { PrivateHeader } from './header/privateHeader';
import { PublicHeader } from './header/publicHeader';
import { NotifyHeader } from './notify';

const DefaultLayout = observer((props: any) => {
  const history = useHistory();

  const layoutStore: LayoutStore = useStores().layoutStore;
  const sessionStore: SessionStore = useStores().sessionStore;
  const notificationStore: NotificationStore = useStores().notificationStore;
  notificationStore.settingHistory(history);

  useEffect(() => {
    if (sessionStore.isInited) {
      if (props.isPrivate && !sessionStore.isLogin) {
        history.replace('/');
      }
    }
  }, [sessionStore, sessionStore.isLogin]);

  const renderHeader = () => {
    if (!layoutStore.isShowHeader) return null;
    return sessionStore.isLogin && sessionStore.session ? (
      <PrivateHeader user={sessionStore.session} />
    ) : (
      <PublicHeader showLoginBtn={props.showLoginBtn} showRegisterBtn={props.showRegisterBtn} />
    );
  };

  return (
    <div className="app">
      {renderHeader()}
      {layoutStore.isShowHeader && <NotifyHeader />}
      {props.children}
      {!props.hideFooter && layoutStore.isShowFooter && <Footer />}
    </div>
  );
});

export const DefaultLayoutRoute = ({ children, ...rest }: any) => {
  let { showLoginBtn, showRegisterBtn } = rest;
  if (showLoginBtn == null) showLoginBtn = true;
  if (showRegisterBtn == null) showRegisterBtn = true;
  return (
    <Route
      {...rest}
      render={(props) => (
        <DefaultLayout
          hideFooter={rest.hideFooter}
          isPrivate={rest.private}
          showLoginBtn={showLoginBtn}
          showRegisterBtn={showRegisterBtn}
        >
          {children}
        </DefaultLayout>
      )}
    />
  );
};
