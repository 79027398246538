import { LineLogin } from 'components/common/LoginByLine';
import { LINE_ID, LINE_SECRET, REDIRECT_LINK_URI } from 'config';
import { makeUuid } from 'utils';
import { SnsUserData } from '../models/UserProfile';

interface LineLinkBtnProps {
  loginResponse: (err: Error | null, userData?: SnsUserData) => any;
  textBtn: string;
  provider: string;
}

export const LineLinkBtn = ({ loginResponse, textBtn, provider }: LineLinkBtnProps) => {
  let userData: SnsUserData = {} as SnsUserData;

  const setUserPayload = (payload: any) => {
    console.log(payload);
    if (payload.access_token) {
      userData.accessToken = payload.access_token;
    }
  };

  const setUserInfo = (response: any) => {
    if (!userData.accessToken) {
      loginResponse(new Error('Login line failed'));
    } else {
      userData.userID = response.sub;
      userData.email = response.email;
      userData.name = response.name;
      loginResponse(null, userData);
    }
  };

  let redirectLink = '';
  if (provider == 'mama') {
    redirectLink = REDIRECT_LINK_URI.replace('provider', 'mama');
  } else {
    redirectLink = REDIRECT_LINK_URI;
  }

  console.log("redirectLink: ", redirectLink);

  return (
    <LineLogin
      clientID={LINE_ID}
      clientSecret={LINE_SECRET}
      state={makeUuid(20)}
      nonce={makeUuid(20)}
      redirectURI={redirectLink}
      scope="profile openid email"
      setPayload={setUserPayload}
      setIdToken={setUserInfo}
      textBtn={textBtn}
    />
  );
};
