import { observer } from 'mobx-react';
import moment from 'moment';
import { useMemo, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';

import { useStores } from 'hooks/use-stores';
import UIStore from 'stores/ui';
import { SnsParamsData } from '.';
import { UserRole } from '../../config/constants';
import { getAbsPath, postFormData } from '../../services';
import { RegisterScreenTemplate } from './components/RegisterScreenTemplate';

const useQuery = () => {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
};

export const MamaRegisterScreen = observer(() => {
  const location: any = useLocation();
  let params: SnsParamsData = {};
  if (location.state) {
    params.snsProvider = location.state.snsProvider;
    params.userData = location.state.userData;
    console.log('data register: ', params);
  }

  const history = useHistory();
  const query = useQuery();
  const uiStore: UIStore = useStores().uiStore;

  const [isLoading, setIsLoading] = useState(false);
  const [modal, setModal] = useState(false);

  const onSubmit = async (data: any) => {
    // setIsLoading(true);
    uiStore.isShowLoading = true;
    const formData = new FormData();
    formData.append('role', UserRole.Mama.toString());
    const newData = { ...data, birthday: moment(data.birthday).format('YYYY/MM/DD') };
    for (let key in newData) {
      if (newData[key]) {
        if (Array.isArray(newData[key])) {
          newData[key].map((item: number | string) => {
            formData.append(key + '[]', item + '');
          });
        } else {
          formData.append(key, newData[key]);
        }
      }
    }

    if (params.snsProvider && params.userData) {
      formData.append('social_provider', params.snsProvider);
      formData.append('social_member_id', params.userData.userID);
    }

    try {
      const res = await postFormData('register', formData);
      if (query.get('bbid')) {
        try {
          await getAbsPath('https://track.bannerbridge.net/lead.php', {
            bbid: query.get('bbid'),
            orderid: res.id,
          });
        } catch (err) {
          console.log('Banner bridge error: ', err);
        }
      }
      setModal(true);
    } catch (errors: any) {
      let errStr = '';
      for (let err in errors) {
        errStr = errStr.concat(`${errors[err][0]}\n`);
      }
    } finally {
      // setIsLoading(false);
      uiStore.isShowLoading = false;
    }
  };

  const renderModal = () => {
    return (
      <Modal show={modal} centered size="lg">
        <Modal.Body className="p-5 text-center">
          <h3 className="mb-3">プロフィールが登録されました</h3>
          <h5>アカウントは本人確認が完了し次第ご利用頂けるようになります。</h5>
          <div className="d-flex w-100 justify-content-center mt-3">
            <Button
              className="px-4"
              style={{ backgroundColor: 'black' }}
              onClick={() => history.push('/')}
            >
              閉じる
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    );
  };

  return (
    <>
      <RegisterScreenTemplate isMama onSubmit={onSubmit} snsUser={params.userData} />
      {renderModal()}
    </>
  );
});
