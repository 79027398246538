import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { makePersistable } from 'mobx-persist-store';

import { SocialProvider, UserRole } from 'config/constants';
import { SnsUserData, UserProfile } from 'modules/user/models/UserProfile';
import { del, get, post, setAuthorization } from 'services';
import { getFCMToken } from 'services/firebase/messaging';
import NotificationStore from 'stores/notification';

export type SessionUser = UserProfile & {
  token: string;
};

export type LoginRememberData = {
  saveEmail?: boolean;
  savePassword?: boolean;
  email?: string;
  password?: string;
};

export type registProcessData = {
  registStep: string;
  data: {
    role?: UserRole;
    snsType?: SocialProvider;
  };
};

export type forgotPassData = {
  email: string;
  token: string;
};

class SessionStore {
  @observable session?: SessionUser;
  @observable loginRemember?: LoginRememberData;
  @observable registProcess?: registProcessData;
  @observable forgotPassProcess?: forgotPassData;
  @observable fontRatioSetting?: number;
  @observable isInited: boolean = false;
  notificationStore: NotificationStore;

  constructor(notificationStore: NotificationStore) {
    this.notificationStore = notificationStore;
    makeObservable(this);
    makePersistable(this, {
      name: 'Session',
      properties: [
        'session',
        'loginRemember',
        'registProcess',
        'forgotPassProcess',
        'fontRatioSetting',
      ],
      storage: window.localStorage,
    }).then(
      action(() => {
        this.isInited = true;
        setAuthorization(this.session?.token || '');
        this.saveFCMToken();

        this.notificationStore.startFetchLastNews();
        if (this.session?.token) {
          this.notificationStore
            .startFetchNotify(this.session.id, this.session.role)
            .catch((error) => {
              if (error && error.message === 'Token is Expired') {
                this.logout();
              }
            });
          this.notificationStore.settingNotify(this.session.noti_status ? true : false);
        }
      })
    );
  }

  @computed get isLogin() {
    return this.session && this.session.token;
  }

  async login(email: string, password: string): Promise<UserProfile> {
    let resLogin = await post('login', { email, password });

    if (resLogin) {
      runInAction(() => {
        this.session = { token: resLogin.token, ...resLogin.user };
      });
      setAuthorization(resLogin.token);
      this.saveFCMToken();
      this.notificationStore.startFetchNotify(resLogin.user.id, resLogin.user.role);
      return resLogin.user;
    } else {
      throw new Error('Empty response data!');
    }
  }

  async loginBySns(snsProvider: string, snsUser: SnsUserData): Promise<UserProfile> {
    let resLogin = await post('login-sns', {
      sns_provider: snsProvider,
      social_member_id: snsUser.userID,
      social_token: snsUser.accessToken,
    });

    if (resLogin) {
      runInAction(() => {
        this.session = { token: resLogin.token, ...resLogin.user };
      });
      setAuthorization(resLogin.token);
      this.saveFCMToken();
      this.notificationStore.startFetchNotify(resLogin.user.id, resLogin.user.role);
      return resLogin.user;
    } else {
      throw new Error('Empty response data!');
    }
  }

  @action
  setRememberLogin = (loginData: any) => {
    if (!this.loginRemember) this.loginRemember = {};

    this.loginRemember.saveEmail = loginData.saveEmail;
    this.loginRemember.email = loginData.saveEmail ? loginData.email : '';
    this.loginRemember.savePassword = loginData.savePassword;
    this.loginRemember.password = loginData.savePassword ? loginData.password : '';
  };

  @action
  setRegisterProcess = (step: string, role?: UserRole, snsType?: SocialProvider) => {
    this.registProcess = {
      registStep: step,
      data: { role, snsType },
    };
  };

  @action
  setForgotPassProcess = (email: string, token?: string) => {
    this.forgotPassProcess = {
      email: email,
      token: token || '',
    };
  };

  async logout(): Promise<boolean> {
    let resLogout = await get('logout', {});

    if (resLogout) {
      runInAction(() => {
        this.session = undefined;
      });
      setAuthorization('');
      this.notificationStore.clearFetchNotify();
      return true;
    } else {
      throw new Error('Empty response data!');
    }
  }

  async forgotPass(email: string): Promise<{ email: string; token: string }> {
    let res = await post('forgot_password', { email });
    if (res) {
      return res;
    } else {
      throw new Error('Empty response data!');
    }
  }

  async checkCode(email: string, code: string): Promise<{ email: string; token: string }> {
    let res = await post('forgot_password/check_code', { email, token: code });
    if (res) {
      return res;
    } else {
      throw new Error('Empty response data!');
    }
  }

  async resetPass(
    email: string,
    token: string,
    password: string
  ): Promise<{ email: string; token: string }> {
    let res = await post('reset_password', {
      email,
      token,
      password,
      password_confirmation: password,
    });
    if (res) {
      return res;
    } else {
      throw new Error('Empty response data!');
    }
  }

  async deleteAccount(): Promise<boolean> {
    let resDelete = await del('account');

    if (resDelete) {
      runInAction(() => {
        this.session = undefined;
      });
      setAuthorization('');
      this.notificationStore.clearFetchNotify();
      return true;
    } else {
      throw new Error('Empty response data!');
    }
  }

  @action
  updateProfile = (profileData: any) => {
    this.session = { ...this.session, ...profileData };
  };

  @action
  settingFontRatio = (ratio: number) => {
    this.fontRatioSetting = ratio;
  };

  async saveFCMToken(): Promise<boolean> {
    if (!this.session?.token) return false;

    const fcm = getFCMToken();
    if (!fcm) return false;

    let res = await post('members/fcm', { token: fcm });
    if (res) {
      return true;
    } else {
      return false;
    }
  }
}

export default SessionStore;
