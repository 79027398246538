import { observer } from 'mobx-react';
import { Col, Container, Row } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

import AlertIcon from 'assets/images/alert-icon.svg';
import HomeIcon from 'assets/images/home-icon.svg';
import LoginIcon from 'assets/images/login-icon.svg';
import MessageIcon from 'assets/images/message-icon.svg';
import MypageIcon from 'assets/images/mypage-icon.svg';
import RegisterIcon from 'assets/images/register-icon.svg';
import SpecialIcon from 'assets/images/special-icon.svg';
import { UserRole } from 'config/constants';
import { useStores } from 'hooks/use-stores';
import NotificationStore from 'stores/notification';
import SessionStore from 'stores/session';
import { formatUnread } from 'utils';

import './styles.scss';

export const Footer = observer(() => {
  const sessionStore: SessionStore = useStores().sessionStore;
  const notificationStore: NotificationStore = useStores().notificationStore;
  const user = sessionStore.session;

  return (
    <div className="session_footer">
      <div className="pc">
        <Container fluid>
          <Row>
            <Col className="item">
              <a className="link_footer" href="/company">
                会社概要
              </a>
            </Col>
            <Col className="item">
              <a className="link_footer" href="/policy">
                プライバシーポリシー
              </a>
            </Col>
            <Col className="item">
              <a className="link_footer" href="/inquiry">
                お問い合わせ
              </a>
            </Col>
          </Row>
          <Row>
            <Col className="item">
              <a className="link_footer" href="/rules">
                利用規約
              </a>
            </Col>
            <Col className="item">
              <a className="link_footer" href="/display">
                特定商取引に基づく表示
              </a>
            </Col>
            <Col className="item">
              <a className="link_footer" href="https://youtu.be/imeQ-7AGfuY">
                スマホのホーム画面への追加方法(iPhone)
              </a>
            </Col>
          </Row>
          <Row>
            <Col className="item"></Col>
            <Col className="item">
              <a className="link_footer" href="/inquiry">
                広告希望企業様募集中
              </a>
            </Col>
            <Col className="item">
              <a className="link_footer" href="https://youtu.be/--4pargmz7M">
                スマホのホーム画面への追加方法(Android)
              </a>
            </Col>
          </Row>
          <Row>
            <Col className="item"></Col>
            <Col className="item">© desire 2021 All right reserved</Col>
            <Col className="item"></Col>
          </Row>
        </Container>
      </div>

      {!sessionStore.isLogin && (
        <div className="mobile public">
          <NavLink to="/login" className="nav-link">
            <img src={LoginIcon} />
            <label>既存会員の方はこちら</label>
          </NavLink>
          <NavLink to="/register" className="nav-link">
            <img src={RegisterIcon} />
            <label>新規会員登録はこちら</label>
          </NavLink>
        </div>
      )}

      {sessionStore.isLogin && user && (
        <div className="mobile private">
          <NavLink to="/search" className="nav-link">
            <div className="icon">
              <img src={HomeIcon} />
            </div>
            ホーム
          </NavLink>
          <NavLink to="/messages" className="nav-link">
            <div className="icon">
              <img src={MessageIcon} />
              {notificationStore.messageCount ? (
                <span>{formatUnread(notificationStore.messageCount)}</span>
              ) : null}
            </div>
            メッセージ
          </NavLink>
          <NavLink
            to={user.role === UserRole.Mama ? '/mama/special' : '/provider/special'}
            className="nav-link"
          >
            <div className="icon">
              <img src={SpecialIcon} />
              {notificationStore.specialCount ? (
                <span>{formatUnread(notificationStore.specialCount)}</span>
              ) : null}
            </div>
            スペシャルオーダー
          </NavLink>
          <NavLink to={'/news'} className="nav-link">
            <div className="icon">
              <img src={AlertIcon} />
              {notificationStore.newsCount ? (
                <span>{formatUnread(notificationStore.newsCount)}</span>
              ) : null}
            </div>
            お知らせ
          </NavLink>
          <NavLink
            to={user.role === UserRole.Mama ? '/mama/mypage' : '/provider/mypage'}
            className="nav-link"
          >
            <div className="icon">
              <img src={MypageIcon} />
            </div>
            マイページ
          </NavLink>
        </div>
      )}
    </div>
  );
});
