import { action, makeAutoObservable, observable, runInAction } from 'mobx';

import { makeUuid } from 'utils';

export type ConfirmModalData = {
  title: string;
  content?: string;
  contentComponent?: JSX.Element;
  confirmText?: string;
  confirmText2?: string;
  cancelText?: string;
};

export type AlertModalData = {
  type: string; // "error" || "alert"
  message: string;
  btnText: string;
  linkTogo?: string;
  title?: string;
  notShowIcon?: boolean;
};

class UIStore {
  @observable isShowModalConfirm: boolean = false;
  @observable confirmModalData: ConfirmModalData = { title: '' };
  confirmResult: any;

  @observable isShowAlert: boolean = false;
  @observable alertData: AlertModalData = { type: 'alert', message: '', btnText: '閉じる' };

  @observable isShowLoading: boolean = false;
  loadingStack: Array<{ id: string; timeout: NodeJS.Timeout }> = [];

  @observable InAppMessage: { isShow: boolean; title: string; content: string; imgSrc: string } = {
    isShow: false,
    title: '',
    content: '',
    imgSrc: '',
  };

  inboxList: Array<any> = [];
  inboxScrollPosition: string = '';

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  confirm = (data: ConfirmModalData) => {
    return new Promise<boolean>((resolve, reject) => {
      runInAction(() => {
        this.openConfirmModal();
        this.confirmModalData = data;
        this.confirmResult = resolve;
      });
    });
  };

  @action
  openConfirmModal = () => {
    this.isShowModalConfirm = true;
  };
  @action
  closeConfirmModal = () => {
    this.isShowModalConfirm = false;
  };

  @action
  showAlert = (data: AlertModalData) => {
    this.isShowAlert = true;
    this.alertData = data;
  };
  @action
  closeAlert = () => {
    this.isShowAlert = false;
  };

  @action
  showLoading = (duration?: number): string => {
    this.isShowLoading = true;

    const loadingId = makeUuid(10);
    const timeout = setTimeout(() => {
      this.hideLoading(loadingId);
    }, duration || 10000);
    this.loadingStack.push({ id: loadingId, timeout });

    return loadingId;
  };
  @action
  hideLoading = (loadingId: string) => {
    const index = this.loadingStack.findIndex((item) => item.id === loadingId);
    if (index > -1) {
      clearTimeout(this.loadingStack[index].timeout);
      this.loadingStack.splice(index, 1);
    }

    if (this.loadingStack.length === 0) {
      this.isShowLoading = false;
    }
  };

  @action
  hasMessage = (title: string, content: string, imgSrc: string) => {
    this.InAppMessage.title = title;
    this.InAppMessage.content = content;
    this.InAppMessage.imgSrc = imgSrc;
  };

  @action
  showInAppMessage = () => {
    this.InAppMessage.isShow = true;
  };

  @action
  closeInAppMessage = () => {
    this.InAppMessage.isShow = false;
    this.InAppMessage.title = '';
    this.InAppMessage.content = '';
    this.InAppMessage.imgSrc = '';
  };

  @action
  setInboxList = (list: Array<any>) => {
    this.inboxList = list;
  };

  @action
  setInboxScrollPosition = (pos: string) => {
    this.inboxScrollPosition = pos;
  };
}

export default UIStore;
