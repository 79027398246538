import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { Button, Card, Col, Container, Row, Table } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';

import IcBack from 'assets/icons/ic_back.svg';
import ICON_BOOK from 'assets/icons/icon-book.svg';
import ICON_CAR from 'assets/icons/icon-car.svg';
import ICON_CROWN from 'assets/icons/icon-crown.svg';
import ICON_HAND from 'assets/icons/icon-hand.svg';
import ICON_MAIL from 'assets/icons/icon-mail.svg';
import ICON_STAR from 'assets/icons/icon-star.svg';
import { PlanName, PlanType } from 'config/constants';
import useLayout from 'hooks/use-layout';
import { useStores } from 'hooks/use-stores';
import { Plan } from 'modules/user/models';
import { get, post } from 'services';
import providerService from 'services/api/provider';
import LayoutStore from 'stores/layout';
import SessionStore from 'stores/session';
import UIStore from 'stores/ui';
import { formatCurrency, toAwait } from 'utils';
import { formatDateJP } from 'utils/datetime';

import './style.scss';

export const PlanScreen = observer(() => {
  const history = useHistory();

  const uiStore: UIStore = useStores().uiStore;
  const layoutStore: LayoutStore = useStores().layoutStore;
  const sessionStore: SessionStore = useStores().sessionStore;
  const myPlan: Plan | null = sessionStore.session
    ? (sessionStore.session as any).member_plan
    : null;

  const [planList, setPlanList] = useState<Array<Plan>>([]);

  useLayout();

  useEffect(() => {
    getPlanList();
    getProfileData();
  }, []);

  const getPlanList = async () => {
    const loading = uiStore.showLoading();
    let [res, err] = await toAwait(get('provider/plans', {}));
    uiStore.hideLoading(loading);
    if (err) {
      console.log('error get plan list', err);
    } else {
      setPlanList(res.plans);
    }
  };

  const getProfileData = async () => {
    const loading = uiStore.showLoading();
    let [profile, err] = await toAwait(providerService.getMyProfile());
    uiStore.hideLoading(loading);
    if (err) {
      console.log('error get my profile', err);
      return;
    } else {
      sessionStore.updateProfile(profile);
    }
  };

  const isCurrentPlan = (plan?: Plan) => {
    if (!myPlan || !plan) return false;
    return myPlan.type == plan.type && myPlan.days == plan.days;
  };

  const registPlan = async (plan: Plan) => {
    console.log('plan: ', plan, myPlan?.id);
    if (isCurrentPlan(plan)) return; // do nothing
    const loading = uiStore.showLoading();
    const [res, err] = await toAwait(
      post('provider/update-plan', {
        plan_id: plan.id,
        action: 1,
      })
    );
    uiStore.hideLoading(loading);
    if (err) {
      console.log('cannot update plan: ', err);
      const { message, errors, error_code } = err;
      let errMsg = message;
      if (error_code === 'LAST_ACTION_DOWNGRADE_PLAN') {
        const next_plan: Plan = errors.next_plan;
        const nextPlanName =
          next_plan.type === PlanType.Free
            ? '無料プラン'
            : `${PlanName[next_plan.type]}の${next_plan.days}日更新プラン`;
        errMsg = `${nextPlanName}を申し込みしていますので他のプランをもうしこみすることができますません。`;
      }
      let confirm = await uiStore.confirm({
        title: errMsg,
        confirmText: '申込を取消',
        cancelText: '閉じる',
      });
      if (confirm) {
        const loading = uiStore.showLoading();
        const [res, err] = await toAwait(
            post('provider/update-plan', {
              plan_id: plan.id,
              action: 2,
            })
        );
        uiStore.hideLoading(loading);
        uiStore.showAlert({
          type: err ? 'error' : 'alert',
          message: err ? 'エラーが発生しました。しばらくしてから再度お試しください。' : 'お申込みを取消しました。',
          btnText: '閉じる',
        });
      }
      return;
    }
    if (res.redirect_url) {
      window.location.href = res.redirect_url;
    } else {
      const nextDate = myPlan?.next_bill_date ? formatDateJP(myPlan.next_bill_date) : '';
      const nextPlanName =
        plan.type === PlanType.Free
          ? '無料プラン'
          : `${PlanName[plan.type]}の${plan.days}日更新プラン`;
      const currentPlanName = myPlan ? `${PlanName[myPlan.type]}の${myPlan.days}日更新プラン` : '';
      await uiStore.confirm({
        title: `${nextPlanName}を申し込みしました。`,
        content: `${nextDate}から${currentPlanName}から${nextPlanName}に変更されます。`,
        cancelText: '閉じる',
      });
    }
  };

  return (
    <>
      <div className="header-with-back">
        <button onClick={() => history.goBack()}>
          <img src={IcBack} alt="" />
        </button>

        {!layoutStore.isShowHeader && <div className="title">現在のプラン</div>}
      </div>

      <Helmet>
        <title>現在のプラン</title>
      </Helmet>

      <Container className="provider-plan">
        <div className="provider-plan-top">
          <h3 className="text-center">現在のプラン</h3>
          <h1 className="text-center">{myPlan && PlanName[myPlan.type]}会員</h1>
          <p className="text-center">
            {myPlan?.next_bill_date ? `次の更新日：${formatDateJP(myPlan.next_bill_date)}` : ''}
          </p>
        </div>
        <Row className="g-0 justify-between provider-plan-first">
          <Col lg={4}>
            <Card>
              <Card.Header className="text-center">無料会員</Card.Header>
              <Card.Body className="flex flex-column justify-center">
                <p className="text-center" style={{ flex: 0 }}>
                  無料会員は以下がご利用可能です。
                </p>
                <div className="text-center" style={{ flex: 0 }}>
                  <Table className="inline-table">
                    <tbody>
                      <tr>
                        <td className="text-center">
                          <img src={ICON_STAR} />
                        </td>
                        <td className="text-center">
                          <img src={ICON_BOOK} />
                        </td>
                      </tr>
                      <tr>
                        <td className="text-center">
                          <i>お気に入り</i>
                        </td>
                        <td className="text-center">
                          <i>プロフィール確認</i>
                        </td>
                      </tr>
                      <tr>
                        <td className="text-center" style={{ whiteSpace: 'nowrap' }}>
                          <img src={ICON_HAND} />
                        </td>
                        <td className="text-center">
                          <img src={ICON_CAR} />
                        </td>
                      </tr>
                      <tr>
                        <td className="text-center" style={{ whiteSpace: 'nowrap' }}>
                          <i>ブロック</i>
                        </td>
                        <td className="text-center">
                          <i>通報</i>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
                <div className="flex justify-center items-end">
                  <Button variant="base" className="red" onClick={() => registPlan(planList[0])}>
                    無料会員になる
                  </Button>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={4}>
            <Card>
              <Card.Header className="text-center">スタンダード会員</Card.Header>
              <Card.Body className="flex flex-column justify-center">
                <p className="text-center" style={{ flex: 0 }}>
                  スタンダード会員は以下がご利用可能です。
                </p>
                <Table style={{ flex: 0 }}>
                  <tbody>
                    <tr>
                      <td className="text-center">
                        <div className="d-inline-block text-center">
                          <img src={ICON_STAR} />
                        </div>
                      </td>
                      <td className="text-center">
                        <img src={ICON_BOOK} />
                      </td>
                      <td className="text-center">
                        <div className="d-inline-block text-center">
                          <img src={ICON_HAND} />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">
                        <i>お気に入り</i>
                      </td>
                      <td className="text-center">
                        <i>プロフィール確認</i>
                      </td>
                      <td className="text-center">
                        <div className="d-inline-block text-center">
                          <i>ブロック</i>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center" style={{ whiteSpace: 'nowrap' }}>
                        <div className="d-inline-block text-center">
                          <img src={ICON_MAIL} />
                        </div>
                      </td>
                      <td className="text-center">
                        <img src={ICON_CAR} />
                      </td>
                      <td></td>
                    </tr>
                    <tr>
                      <td className="text-center" style={{ whiteSpace: 'nowrap' }}>
                        <i>メッセージ送り放題</i>
                      </td>
                      <td className="text-center">
                        <i>通報</i>
                      </td>
                      <td></td>
                    </tr>
                  </tbody>
                </Table>
                <div className="provider-plan-list">
                  <div className="provider-plan--item">
                    <div className="flex justify-between">
                      <div>
                        <span>{planList[1]?.days}日更新プラン</span>
                      </div>
                      <div>
                        <span>
                          {formatCurrency(planList[1]?.price) || '5,500'}円<small>（定額制）</small>
                        </span>
                      </div>
                    </div>
                    <div className="text-center">
                      <Button
                        variant="base"
                        className="white"
                        onClick={() => registPlan(planList[1])}
                      >
                        {isCurrentPlan(planList[1]) ? '現在のプラン' : '申し込む'}
                      </Button>
                      <div style={{ fontSize: '12px', marginTop: '10px' }}>
                        サービス利用料金の返金は、
                        <br />
                        サービスの性質上いかなる場合も行いません。
                      </div>
                    </div>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={4}>
            <Card>
              <Card.Header className="text-center">ゴールド会員</Card.Header>
              <Card.Body className="flex flex-column justify-center">
                <p className="text-center">ゴールド会員は以下がご利用可能です。</p>
                <Table>
                  <tbody>
                    <tr>
                      <td className="text-center">
                        <div className="d-inline-block text-center">
                          <img src={ICON_STAR} />
                        </div>
                      </td>
                      <td className="text-center">
                        <img src={ICON_BOOK} />
                      </td>
                      <td className="text-center">
                        <div className="d-inline-block text-center">
                          <img src={ICON_HAND} />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">
                        <i>お気に入り</i>
                      </td>
                      <td className="text-center">
                        <i>プロフィール確認</i>
                      </td>
                      <td className="text-center">
                        <div className="d-inline-block text-center">
                          <i>ブロック</i>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center" style={{ whiteSpace: 'nowrap' }}>
                        <div className="d-inline-block text-center">
                          <img src={ICON_MAIL} />
                        </div>
                      </td>
                      <td className="text-center">
                        <img src={ICON_CAR} />
                      </td>
                      <td className="text-center">
                        <img src={ICON_CROWN} />
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center" style={{ whiteSpace: 'nowrap' }}>
                        <i>メッセージ送り放題</i>
                      </td>
                      <td className="text-center">
                        <i>通報</i>
                      </td>
                      <td style={{ whiteSpace: 'nowrap' }} className="text-center">
                        <i>スペシャルオーダー</i>
                      </td>
                    </tr>
                  </tbody>
                </Table>
                <div className="provider-plan-list">
                  <div className="provider-plan--item">
                    <div className="flex justify-between">
                      <div>
                        <span>{planList[2]?.days}日更新プラン</span>
                      </div>
                      <div>
                        <span>
                          {formatCurrency(planList[2]?.price) || '7,700'}円<small>（定額制）</small>
                        </span>
                      </div>
                    </div>
                    <div className="text-center">
                      <Button
                        variant="base"
                        className="white"
                        onClick={() => registPlan(planList[2])}
                      >
                        {isCurrentPlan(planList[2]) ? '現在のプラン' : '申し込む'}
                      </Button>
                      <div style={{ fontSize: '12px', marginTop: '10px' }}>
                        サービス利用料金の返金は、
                        <br />
                        サービスの性質上いかなる場合も行いません。
                      </div>
                    </div>
                  </div>
                  <div className="provider-plan--item">
                    <div>
                      <small>
                        約1ヶ月あたり{formatCurrency(planList[3]?.save_amount_per_month)}円お得！
                      </small>
                    </div>
                    <div className="flex justify-between">
                      <div>
                        <span>{planList[3]?.days}日更新プラン</span>
                      </div>
                      <div>
                        <span>
                          {formatCurrency(planList[3]?.price) || '20,790'}円
                          <small>（定額制）</small>
                        </span>
                      </div>
                    </div>
                    <div className="text-center">
                      <Button
                        variant="base"
                        className="white"
                        onClick={() => registPlan(planList[3])}
                      >
                        {isCurrentPlan(planList[3]) ? '現在のプラン' : '申し込む'}
                      </Button>
                      <div style={{ fontSize: '12px', marginTop: '10px' }}>
                        サービス利用料金の返金は、
                        <br />
                        サービスの性質上いかなる場合も行いません。
                      </div>
                    </div>
                  </div>
                  <div className="provider-plan--item">
                    <div>
                      <small>
                        約1ヶ月あたり{formatCurrency(planList[4]?.save_amount_per_month)}円お得！
                      </small>
                    </div>
                    <div className="flex justify-between">
                      <div>
                        <span>{planList[4]?.days}日更新プラン</span>
                      </div>
                      <div>
                        <span>
                          {formatCurrency(planList[4]?.price) || '39,270'}
                          <small>（定額制）</small>
                        </span>
                      </div>
                    </div>
                    <div className="text-center">
                      <Button
                        variant="base"
                        className="white"
                        onClick={() => registPlan(planList[4])}
                      >
                        {isCurrentPlan(planList[4]) ? '現在のプラン' : '申し込む'}
                      </Button>
                      <div style={{ fontSize: '12px', marginTop: '10px' }}>
                        サービス利用料金の返金は、
                        <br />
                        サービスの性質上いかなる場合も行いません。
                      </div>
                    </div>
                  </div>
                  <div className="provider-plan--item">
                    <div>
                      <small>
                        約1ヶ月あたり{formatCurrency(planList[5]?.save_amount_per_month)}円お得！
                      </small>
                    </div>
                    <div className="flex justify-between">
                      <div>
                        <span>{planList[5]?.days}日更新プラン</span>
                      </div>
                      <div>
                        <span>
                          {formatCurrency(planList[5]?.price) || '73,920'}円
                          <small>（定額制）</small>
                        </span>
                      </div>
                    </div>
                    <div className="text-center">
                      <Button
                        variant="base"
                        className="white"
                        onClick={() => registPlan(planList[5])}
                      >
                        {isCurrentPlan(planList[5]) ? '現在のプラン' : '申し込む'}
                      </Button>
                      <div style={{ fontSize: '12px', marginTop: '10px' }}>
                        サービス利用料金の返金は、
                        <br />
                        サービスの性質上いかなる場合も行いません。
                      </div>
                    </div>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
});
