import { useHistory } from 'react-router-dom';

import styles from './styles.module.scss';

const MamaPolicy = () => {
  const history = useHistory();

  return (
    <div className={styles.container}>
      <section className={styles.policies}>
        <h1>『安心安全の取り組みについて』</h1>

        <h2>１．【本人確認書類の提出義務の徹底】</h2>
        <p>
          利用するには、名前、生年月日のみ確認を行うため、
          <br />
          本人確認書類の提出が必要となります。(それ以外の住所等は不要)
        </p>
        <p>
          管理者が目視にて直接確認しているため、
          <br />
          他人を装い利用する、偽装行為などは行えません。
        </p>

        <h2>２．【24時間の監視体制】</h2>
        <p>
          本サイトでは、ユーザーからの通報など、
          <br />
          問い合わせについての迅速な対応を徹底しております。
        </p>
        <p>
          問題行為についての通報があったユーザーにつきましては、
          <br />
          強制退会なども行っております。
        </p>

        <h2>３．【警察庁公認サイト】</h2>
        <p>
          本サイトは、警察庁へ運営について、
          <br />
          正式に書面にて届出を行い公認されております。
          <br />
          （インターネット異性紹介事業）
        </p>
      </section>
      <section className={styles.managerNotes}>
        <h1> 『運営からみなさまへ』</h1>

        <p>
          私たちDesire運営事務局は、 <br />
          皆様がより安全にサイトを利用できるように日々、 <br />
          改善に注力しております。
        </p>

        <p>
          皆様からのご意見
          <br />
          是非お待ちしております。
        </p>

        <p>
          今後とも皆様のより良い出会いを
          <br />
          日々提供して参ります。
        </p>
      </section>
      <button onClick={() => history.push('/register')}>
        <p>新規会員登録はこちら</p>
      </button>
      <button onClick={() => history.push('/login')}>
        <p>既存会員はこちら</p>
      </button>
    </div>
  );
};

export default MamaPolicy;
