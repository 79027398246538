import { useEffect } from 'react';
import { Button, Form } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import { NavLink, useLocation } from 'react-router-dom';

import { SocialProvider, UserRole } from 'config/constants';
import { useStores } from 'hooks/use-stores';
import { FacebookLoginBtn } from 'modules/user/components/FacebookLoginBtn';
import { LineLoginBtn } from 'modules/user/components/LineLoginBtn';
import { SnsUserData } from 'modules/user/models/UserProfile';
import { get } from 'services';
import SessionStore from 'stores/session';
import UIStore from 'stores/ui';
import { toAwait } from 'utils';

import './styles.scss';

export const LoginScreen = () => {
  const history = useHistory();

  const uiStore: UIStore = useStores().uiStore;
  const sessionStore: SessionStore = useStores().sessionStore;

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const query = new URLSearchParams(useLocation().search);

  useEffect(() => {
    if (sessionStore.session) {
      const { session } = sessionStore;
      if (session.token) {
        history.push('/search');
      }
    }

    if (sessionStore.loginRemember) {
      if (sessionStore.loginRemember.saveEmail) {
        setValue('saveEmail', true);
        setValue('email', sessionStore.loginRemember.email);
      }
      if (sessionStore.loginRemember.savePassword) {
        setValue('savePassword', true);
        setValue('password', sessionStore.loginRemember.password);
      }
    }

    if (query.get('code')) {
      // redirect from other process
      console.log('redirect from: ', sessionStore.registProcess);
    } else {
      sessionStore.setRegisterProcess('login');
    }
  }, [sessionStore]);

  const onSubmit = async (data: any) => {
    const loading = uiStore.showLoading();
    let [_, err] = await toAwait(sessionStore!.login(data['email'], data['password']));
    let getCMSMessage;
    uiStore.hideLoading(loading);
    if (err) {
      return;
    }
    sessionStore.setRememberLogin(data);

    /**
     *check if there is a message from CMS everytime the user login.
     */
    try {
      getCMSMessage = await get('alert', {});
      if (getCMSMessage) {
        uiStore.hasMessage(getCMSMessage.title, getCMSMessage.content, getCMSMessage.image);
      }
    } catch (e) {
      console.log(e);
    }
    history.push('/search');
  };

  const handleLoginSns = async (provider: string, err: Error | null, userData?: SnsUserData) => {
    if (err) {
      console.log('err: ', err);
    }
    if (!userData || !sessionStore) return;

    const loading = uiStore.showLoading();
    let [_, loginErr] = await toAwait(sessionStore.loginBySns(provider, userData));
    uiStore.hideLoading(loading);

    if (loginErr) {
      const { registProcess } = sessionStore;
      if (loginErr.error_code !== 401) {
        if (registProcess?.registStep === 'registSns') {
          history.replace('/register/sns', { role: registProcess.data.role });
        } else {
          history.replace('/login');
        }
        return;
      }

      // not yet create user
      const params = {
        snsProvider: provider,
        userData: userData,
      };
      if (registProcess?.registStep === 'registSns') {
        let role = registProcess.data.role;
        let path = role === UserRole.Mama ? '/register/mama' : '/register/provider';
        history.push(path, params);
        return;
      }

      history.push('/register', params);
      return;
    } else {
      history.push('/search');
    }
  };

  return (
    <div className="cbx login-form">
      <Helmet>
        <title>会員ログイン</title>
      </Helmet>
      <div className="session_login">
        <div className="title_login">会員ログイン</div>
        <div>
          <Form className="form_login" onSubmit={handleSubmit(onSubmit)}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <input
                className="input_login"
                type="email"
                placeholder="メールアドレス"
                // value={oldData.email}
                {...register('email', { required: true, pattern: /^\S+@\S+$/i })}
              />
              {errors.email && errors.email.type === 'required' && (
                <span style={{ color: 'red' }}>メールアドレスが必須項目です。</span>
              )}
              {errors.email && errors.email.type === 'pattern' && (
                <span style={{ color: 'red' }}>※メール形式が正しくありません。</span>
              )}
              <Form.Text className="text-muted">
                <Form.Check
                  type="checkbox"
                  label="メールアドレスを保存する"
                  // checked={oldData.saveEmail}
                  {...register('saveEmail', {})}
                />
              </Form.Text>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicUser">
              <input
                className="input_login"
                type="password"
                placeholder="パスワード"
                // value={oldData.password}
                {...register('password', { required: true, minLength: 8, maxLength: 32 })}
              />
              {errors.password && errors.password.type === 'required' && (
                <span style={{ color: 'red' }}>パスワードが必須項目です。</span>
              )}
              {errors.password &&
                (errors.password.type === 'minLength' || errors.password.type === 'maxLength') && (
                  <span style={{ color: 'red' }}>※８文字以上32文字以内で入力してください。</span>
                )}
              <Form.Text className="text-muted">
                <Form.Check
                  type="checkbox"
                  label="パスワードを保存する"
                  // checked={oldData.savePassword}
                  {...register('savePassword', {})}
                />
              </Form.Text>
            </Form.Group>

            <div className="d-flex flex-column align-items-center">
              <Button className="btn red" type="submit">
                ログイン
              </Button>
              <FacebookLoginBtn
                textBtn="Facebookでログイン"
                loginResponse={(err, userData) =>
                  handleLoginSns(SocialProvider.Facebook, err, userData)
                }
              />
              <LineLoginBtn
                textBtn="Lineでログイン"
                loginResponse={(err, userData) =>
                  handleLoginSns(SocialProvider.Line, err, userData)
                }
              />
            </div>
          </Form>
          <NavLink to="/register" className="link_login d-block">
            新規アカウントを作成される方
          </NavLink>
          <NavLink to="/forgot-password" className="link_login d-block">
            パスワードお忘れの方
          </NavLink>
        </div>
      </div>
    </div>
  );
};
