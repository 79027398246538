import { Container } from 'react-bootstrap';

import { MamaProfile, ProviderProfile } from 'modules/user/models';
import { getProviderService } from 'utils';
import { UserItem } from './UserItem';

interface IProps {
  users: Array<MamaProfile | ProviderProfile>;
  isMama: boolean;
  filter: any;
}

const checkFilterService = (filter: any) => filter.service_id && filter.service_id > 0
const checkFilter = (filter: any) => {
  for (let key in filter) {
    if (key != "service_id" && filter[key] && filter[key] != '') {
      return true;
    }
  }
  return false;
}

export const UserList = ({ users, isMama, filter }: IProps) => {
  const filterService = checkFilterService(filter);
  const filterOther = checkFilter(filter);
  return (
    <Container className="search--result">
      <div className="flex provider-search--bar">
        {!filterService && !filterOther && <span>おすすめユーザー</span>}
        {filterService && <span>{getProviderService(filter.service_id)}</span>}
        {filterService && filterOther && <span> ＞ </span>}
        {filterOther && <span>検索結果</span>}
      </div>
      {users.map((item) => (
        <UserItem key={item.id} user={item} isMama={isMama} />
      ))}
    </Container>
  );
};
