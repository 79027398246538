
import { useState } from 'react';
import { Container } from 'react-bootstrap';

import DefaultAvatar from 'assets/images/img_default_avatar.svg';
import { MamaProfile, ProviderProfile } from 'modules/user/models';
import { getProviderService } from 'utils';

interface IProps {
  user: MamaProfile | ProviderProfile;
}

export const AvatarContainer = ({ user }: IProps) => {
  const [mainAvatar, setMainAvatar] = useState({
    src: user.avatar,
    type: 0,
  });

  return (
    <Container className="text-center">
      <div className="text-center top-title-h1">
        <span>PROFILE</span>
      </div>
      <div className="sub-title">プロフィール</div>
      <div className="slider_wrap_img">
        <div className="slide" id="slide-1">
          <div className="gift_rec_wrap">
            {(user as ProviderProfile).member_services?.includes(1) && (
              <div className="gift_rec gift_first">{getProviderService(1)}</div>
            )}
            {(user as ProviderProfile).member_services?.includes(2) && (
              <div className="gift_rec gift_second">{getProviderService(2)}</div>
            )}
            {(user as ProviderProfile).member_services?.includes(3) && (
              <div className="gift_rec gift_four">{getProviderService(3)}</div>
            )}
          </div>
          {(user as ProviderProfile).is_receive_gif ? <div className="gift_third">物品提供<br/>でも可</div> : null}
          {mainAvatar.type === 0 || !mainAvatar.src ? (
            <img
              src={mainAvatar.src || DefaultAvatar}
              className="detail-large-img"
              alt=""
              style={{ objectFit: 'cover' }}
            />
          ) : (
            <video className="detail-large-img" src={mainAvatar.src} autoPlay />
          )}
        </div>
      </div>
      <div className="flex justify-center slider_wrap_option">
        <div
          className="detail-small--img"
          onClick={() =>
            setMainAvatar({
              src: user.avatar,
              type: 0,
            })
          }
        >
          <img src={user.avatar || DefaultAvatar} alt="" />
        </div>
        <div
          className="detail-small--img"
          onClick={() =>
            setMainAvatar({
              src: user.avatar_1,
              type: 0,
            })
          }
        >
          <img src={user.avatar_1 || DefaultAvatar} alt="" />
        </div>
        <div
          className="detail-small--img"
          onClick={() =>
            setMainAvatar({
              src: user.avatar_2,
              type: 0,
            })
          }
        >
          <img src={user.avatar_2 || DefaultAvatar} alt="" />
        </div>
        <div
          className="detail-small--img"
          onClick={() => setMainAvatar({ src: user.video, type: 1 })}
        >
          {user.video ? (
            <video src={user.video || DefaultAvatar}></video>
          ) : (
            <img src={DefaultAvatar} alt="" />
          )}
        </div>
      </div>
    </Container>
  );
};
