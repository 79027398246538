import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';

import IcBack from 'assets/icons/ic_back.svg';
import useLayout from 'hooks/use-layout';
import { useStores } from 'hooks/use-stores';
import { MamaProfile } from 'modules/user/models';
import { get, post, postFormData } from 'services';
import LayoutStore from 'stores/layout';
import SessionStore from 'stores/session';
import UIStore from 'stores/ui';
import { toAwait } from 'utils';
import { FormTransferComponent } from './form-transfer';

import './style.scss';

export type BankData = {
  id?: number;
  member_id?: number;
  bank_name: string;
  account_type: number;
  branch_code: string;
  account_number: string;
  account_name: string;
  account_document_confirm?: string;
};

export const PointTransferScreen = observer(() => {
  const history = useHistory();

  const uiStore: UIStore = useStores().uiStore;
  const sessionStore: SessionStore = useStores().sessionStore;
  const layoutStore: LayoutStore = useStores().layoutStore;

  const [bankData, setBankData] = useState<BankData | undefined>(undefined);

  useLayout();

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const loading = uiStore.showLoading();
    const [res, err] = await toAwait(get('mama/bank', {}));
    uiStore.hideLoading(loading);
    if (err) {
      console.log('error when get old data: ', err);
    } else {
      setBankData(res);
    }
  };

  const registTransfer = async (data: any) => {
    const mama: MamaProfile = sessionStore.session as MamaProfile;
    const loading = uiStore.showLoading();
    const formData = new FormData();
    for (let key in data) {
      if (data[key]) {
        formData.append(key, data[key]);
      }
    }
    let [res, err] = await toAwait(postFormData('mama/bank', formData));
    if (err) {
      console.log('cannot regist bank info: ', err);
    } else {
      if (!mama.point || mama.point < 10000) {
        await uiStore.confirm({
          title: 'ポイントが不足しています',
          content:
            'ポイントが不足敷いているため振込申請はできません。振込申請は保有ポイントが10,000ポイントを超えた段階で申請が可能になります。',
          cancelText: '閉じる',
        });
        uiStore.hideLoading(loading);
        return;
      } else {
        [res, err] = await toAwait(
          post('mama/point/exchange', {
            point: mama.point,
          })
        );
        if (err) {
          console.log('cannot regist transfer: ', err);
        }
      }
    }
    uiStore.hideLoading(loading);

    if (!err) {
      await uiStore.confirm({
        title: '振込申請が完了いたしました',
        content:
          'お振込は保有ポイントが10,000ptを超えた場合のみ対応いたします。お振込があった場合には月末締めで翌月15日にお振込いたします。お振込が確認できない場合はお問い合わせよりご連絡いただけますと幸いです。',
        cancelText: '閉じる',
      });
      history.goBack();
    }
  };

  return (
    <>
      <div className="header-with-back">
        <button onClick={() => history.goBack()}>
          <img src={IcBack} alt="" />
        </button>

        {!layoutStore.isShowHeader && <div className="title">振込情報登録</div>}
      </div>

      <Helmet>
        <title>振込情報登録</title>
      </Helmet>

      <Container className="pwdpage point-transfer">
        <h1 className="text-center top-title-h1">
          <span>TRANSFER APPLICATION</span>
        </h1>
        <h3 className="sub-title">振込申請</h3>

        <FormTransferComponent onSubmitData={registTransfer} bankData={bankData} />
      </Container>
    </>
  );
});
