import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';

import IcBack from 'assets/icons/ic_back.svg';
import useLayout from 'hooks/use-layout';

import './style.scss';
import styles from './styles.module.scss';

export const FaqScreen = () => {
  const history = useHistory();

  useLayout();

  return (
    <div>
      <div className={`header-with-back ${styles.header}`}>
        <button onClick={() => history.goBack()}>
          <img src={IcBack} alt="" />
        </button>

        <div className="title">よくある質問</div>
      </div>

      <div id="faq">
        <Helmet>
          <title>よくある質問</title>
        </Helmet>

        <div className="faq_top">
          <div className="faq_title">
            <div className="faq_line_col title_line"></div>
            <div className="faq_line_col title_line_text">FAQ</div>
            <div className="faq_line_col title_line"></div>
          </div>
          <div className="faq_title_note">よくある質問</div>
        </div>

        <div className="content">
          <div className="faq_item">
            <input type="checkbox" id="question1" name="q" className="questions" />
            <label htmlFor="question1" className="question">
              無料で使用できますか？
            </label>
            <div className="answers">
              <p>
                女性（サービスを受ける側）は完全無料で、男性（サービスを提供する側）は閲覧など無料でご利用いただけます。
              </p>
            </div>
          </div>
          {/* ____________________________________________________ */}
          <div className="faq_item">
            <input type="checkbox" id="question2" name="q" className="questions" />
            <label htmlFor="question2" className="question">
              トラブルなどの場合、どうすればいいです？
            </label>
            <div className="answers">
              <p>
                相手会員の通報・ブロックをお願いします。またカスタマーサポートへご連絡ください。
              </p>
            </div>
          </div>
          {/* ____________________________________________________ */}
          <div className="faq_item">
            <input type="checkbox" id="question3" name="q" className="questions" />
            <label htmlFor="question3" className="question">
              サクラはいますか？
            </label>
            <div className="answers">
              <p>サクラはいません、24時間の監視体制で安心・安全を提供します。</p>
            </div>
          </div>
          {/* ____________________________________________________ */}
          <div className="faq_item">
            <input type="checkbox" id="question4" name="q" className="questions" />
            <label htmlFor="question4" className="question">
              知り合い・友人などにバレずに使用出来ますか？
            </label>
            <div className="answers">
              <p>
                匿名（ニックネーム）でご利用になれます、またママ会員同士の閲覧はできません（男性・レズビアンもおなじ）
              </p>
            </div>
          </div>
          {/* ____________________________________________________ */}
          <div className="faq_item">
            <input type="checkbox" id="question5" name="q" className="questions" />
            <label htmlFor="question5" className="question">
              有料会員の途中での会員ランク変更は出来ますか？
            </label>
            <div className="answers">
              <p>
                出来ます、その際1ヶ月単位の支払となり日割りなどの返金はありません。
                <br />
                したがって月の経過途中にプラン変更されますと重複しての支払いが発生します。
                <br />
                ゴールドプラン,スタンダードプランの長期割引プランを購入のお客様は、そのプラン期間終了までグレード変更することはできません。
                <br />
                途中解約の場合やいかなる場合も返金は致しません。
                <br />
              </p>
            </div>
          </div>
          {/* ____________________________________________________ */}
          <div className="faq_item">
            <input type="checkbox" id="question6" name="q" className="questions" />
            <label htmlFor="question6" className="question">
              退会は出来ますか？
            </label>
            <div className="answers">
              <p>必要なくなればいつでも簡単に退会出来ます。</p>
            </div>
          </div>
          {/* ____________________________________________________ */}
          <div className="faq_item">
            <input type="checkbox" id="question7" name="q" className="questions" />
            <label htmlFor="question7" className="question">
              登録したプロフィールは変更できますか？
            </label>
            <div className="answers">
              <p>
                変更できます、画像・動画などは自由に変更出来ますが、個人情報、身分証明などに変更があった場合、承認依頼が必要となります。
              </p>
            </div>
          </div>
          {/* ____________________________________________________ */}
          <div className="faq_item">
            <input type="checkbox" id="question8" name="q" className="questions" />
            <label htmlFor="question8" className="question">
              チャットにおいて、LINE　IDやメールアドレス、電話番号などの個人情報のやり取りは出来ますか？
            </label>
            <div className="answers">
              <p>可能です。</p>
            </div>
          </div>
          {/* ____________________________________________________ */}
          <div className="faq_item">
            <input type="checkbox" id="question9" name="q" className="questions" />
            <label htmlFor="question9" className="question">
              迷惑な会員をブロックした際に相手に通知されますか？
            </label>
            <div className="answers">
              <p>通知されません</p>
            </div>
          </div>
          {/* ____________________________________________________ */}
          <div className="faq_item">
            <input type="checkbox" id="question10" name="q" className="questions" />
            <label htmlFor="question10" className="question">
              18歳以上であれば会員登録出来ますか？
            </label>
            <div className="answers">
              <p>出来ます、しかし学生（高校生・専門学校生）はご利用になれません</p>
            </div>
          </div>
          {/* ____________________________________________________ */}
          <div className="faq_item">
            <input type="checkbox" id="question11" name="q" className="questions" />
            <label htmlFor="question11" className="question">
              会員登録において、更新・退会はどうなってますか？
            </label>
            <div className="answers">
              <p>
                更新は自動更新となっております。
                <br />
                退会につきましてはメニューにあります特定商取引法に基づく表示に記載されております通りご本人様がサイト内にて退会処理を行ってください。
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
