import './style.scss';

interface IProps {
  onChange: (checked: boolean) => any;
  oldValue?: number;
}

export const SwitchButton = ({ onChange, oldValue }: IProps) => {
  const handleChange = (e: any) => {
    onChange(e.target.checked);
  };

  return (
    <label className="switch">
      <input type="checkbox" checked={oldValue ? true : false} onChange={(e) => handleChange(e)} />
      <span className="slider round"></span>
    </label>
  );
};
