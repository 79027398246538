import parse from 'html-react-parser';
import { observer } from 'mobx-react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useHistory, useLocation } from 'react-router-dom';

import IcBack from 'assets/icons/ic_back.svg';
import { useStores } from 'hooks/use-stores';
import { News } from 'modules/news/models';
import LayoutStore from 'stores/layout';
import { formatDateTime } from 'utils/datetime';

import './style.scss';

export const NewsDetailScreen = observer(() => {
  const history = useHistory();
  const location: any = useLocation();
  let news: News = {} as News;
  if (location.state) {
    news = location.state.data;
  }

  const layoutStore: LayoutStore = useStores().layoutStore;

  return (
    <>
      <div className="header-with-back" style={{ backgroundColor: 'transparent' }}>
        <button onClick={() => history.goBack()}>
          <img src={IcBack} alt="" />
        </button>

        {!layoutStore.isShowHeader && <div className="title">お知らせ</div>}
      </div>

      <Helmet>
        <title>お知らせ</title>
      </Helmet>

      <div className="news-detail">
        <Container>
          <span className="time">{formatDateTime(news.created_at)}</span>
          <p className="text-center title">
            <span>{news.name}</span>
          </p>
          <p className="content">{parse(news.content)}</p>
        </Container>
      </div>
    </>
  );
});
