import { MouseEventHandler } from 'react';
import parse from 'html-react-parser';
import './styles.scss';

interface IProps {
  title: string;
  content?: string;
  contentComponent?: JSX.Element;
  action?: { text: string; onClick: MouseEventHandler<HTMLButtonElement> };
  ButtonComponent?: JSX.Element;
  onClose: MouseEventHandler<HTMLButtonElement>;
  contentStyle?: any;
  alertType?: string; // "error" || "alert"
  notShowIcon?: boolean;
}

export const Popup = ({
  title,
  content,
  action,
  onClose,
  ButtonComponent,
  contentComponent,
  contentStyle,
  alertType,
  notShowIcon,
}: IProps) => {
  let iconDOM: any;
  if (!notShowIcon && alertType && alertType === 'error') {
    iconDOM = (
      <div className="simplert__icon simplert__icon--error">
        <div className="simplert__line simplert__line--error" />
        <div className="simplert__line simplert__line--error-2" />
      </div>
    );
  }

  return (
    <div id="myModal" className="mama-modal">
      <div className="mama-modal-content">
        {iconDOM ? (
          iconDOM
        ) : (
          <span className="mama-close" onClick={onClose}>
            ×
          </span>
        )}
        {title != "" && <div className="title">
          <p>{parse(title)}</p>
        </div>}
        <div className="content" style={contentStyle}>
          {content ? <p>{parse(content)}</p> : contentComponent}
        </div>

        <div className="button">
          {action ? <button onClick={action.onClick}>{action.text}</button> : ButtonComponent}
        </div>
      </div>
    </div>
  );
};
