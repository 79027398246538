import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';

import { Popup } from 'components/Popup';
import { SwitchButton } from 'components/common/switchButton';
import { useStores } from 'hooks/use-stores';
import { ProviderProfile } from 'modules/user/models';
import { get, post } from 'services';
import providerService from 'services/api/provider';
import NotificationStore from 'stores/notification';
import SessionStore from 'stores/session';
import UIStore from 'stores/ui';
import { LineLinkBtn } from 'modules/user/components/LineLinkBtn';
import { SnsUserData } from 'modules/user/models/UserProfile';
import {
  getAge,
  getGender,
  getJob,
  getPrefecture,
  getProviderType,
  getSalaryPerHour,
  toAwait,
} from 'utils';
import { AvatarContainer } from './components/avatar';
import { toast } from 'react-toastify';

import './style.scss';

export const ProviderMypageScreen = observer(() => {
  const history = useHistory();

  const sessionStore: SessionStore = useStores().sessionStore;
  const uiStore: UIStore = useStores().uiStore;
  const notificationStore: NotificationStore = useStores().notificationStore;

  const [user, setUser] = useState<ProviderProfile>();
  const [noti, setNoti] = useState(false);
  const [font, setFont] = useState(false);

  useEffect(() => {
    getProfileData();
  }, []);

  const getProfileData = async () => {
    const loading = uiStore.showLoading();
    let [profile, err] = await toAwait(providerService.getMyProfile());
    uiStore.hideLoading(loading);
    if (err) {
      console.log('error get my profile', err);
      return;
    }
    console.log('profile: ', profile);
    if (profile) {
      setUser(profile);
      sessionStore.updateProfile(profile);
    }
  };

  const registSettingNotify = async (status: boolean) => {
    const loading = uiStore.showLoading();
    let [_, err] = await toAwait(get('members/notify', {}));
    uiStore.hideLoading(loading);
    if (err) {
      console.log('error setting notify', err);
      return;
    }
    getProfileData();
    notificationStore.settingNotify(status);
  };

  const settingFontRatio = (ratio: string) => {
    sessionStore.settingFontRatio(parseInt(ratio));
  };

  const handleLinkSns = async (
    err: Error | null,
    userData?: SnsUserData
  ) => {
    if (err) {
      console.log('err: ', err);
    }

    if (!userData) return;
    console.log("userData: ", userData);
    const loading = uiStore.showLoading();
    let [_, linkErr] = await toAwait(post('members/link-line', {
      'member_id':sessionStore.session?.id,
      'uid':userData.userID
    }));
    uiStore.hideLoading(loading);
    if (linkErr) {
      history.push('/provider/mypage');
    } else {
      let message = "連携は成功しました。";
      toast(message, {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined
      });
    }
  };

  return (
    <div id="my_page">
      <Helmet>
        <title>マイページ</title>
      </Helmet>
      <div className="my_page_top">
        <div className="my_page_title">
          <div className="my_page_line_col title_line"></div>
          <div className="my_page_line_col title_line_text">MY　PAGE</div>
          <div className="my_page_line_col title_line"></div>
        </div>
        <div className="my_page_title_note">マイページ</div>
      </div>
      {user && (
        <div className="my_page_content">
          <div className="my_page_content_profile">
            <div className="my_page_content_profile_col my_page_content_profile_left">
              <AvatarContainer user={user} />
              <div className="my_page_action my_page_action_pc">
                <div className="my_page_action_item action_change_pw">
                  <button onClick={() => history.push('/change-password')}>パスワード変更</button>
                </div>
                <div className="my_page_action_item action_link_line">
                  <LineLinkBtn
                    textBtn="LINEと連携"
                    provider="provider"
                    loginResponse={(err, userData) => handleLinkSns(err, userData)}
                  />
                </div>
              </div>
            </div>
            <div className="my_page_content_profile_col my_page_content_profile_right">
              <div className="content_table_header">
                <div className="content_table_header_txt">
                  {user.nick_name}
                  <span className="chat_points">（{user.point}p保有）</span>
                </div>
                <div className="content_table_header_title">
                  <div className="header_title_item">
                    <span className="header_title_pc">年齢　</span>
                    {getAge(user.birthday)}歳
                  </div>
                  <div className="header_title_item flash"></div>
                  <div className="header_title_item">
                    <span className="header_title_pc">地域　</span>
                    {getPrefecture(user.prefecture_id)}
                  </div>
                  <div className="header_title_item flash"></div>
                  <div className="header_title_item">
                    <span className="header_title_pc">性別　</span>
                    {getGender(user.gender)}
                  </div>
                  <div className="header_title_item flash"></div>
                  <div className="header_title_item">
                    <span className="header_title_pc">系統　</span>
                    {getProviderType(user.member_type_id)}
                  </div>
                </div>
              </div>
              <div className="my_page_action my_page_action_sp">
                <div className="my_page_action_item action_change_pw">
                  <button onClick={() => history.push('/change-password')}>パスワード変更</button>
                </div>
                <div className="my_page_action_item action_link_line">
                  <LineLinkBtn
                    textBtn="LINEと連携"
                    provider="provider"
                    loginResponse={(err, userData) => handleLinkSns(err, userData)}
                  />
                </div>
              </div>
              <table>
                <tbody>
                  <tr>
                    <td>希望時間給</td>
                    <td>{getSalaryPerHour(user.expect_salary_per_hour)}</td>
                  </tr>
                  <tr>
                    <td>趣味</td>
                    <td>{user.hobby}</td>
                  </tr>
                  <tr>
                    <td>特技</td>
                    <td>{user.special_skills}</td>
                  </tr>
                  <tr>
                    <td>職業</td>
                    <td>{getJob(user.profession_id)}</td>
                  </tr>
                  <tr>
                    <td>自己紹介</td>
                    <td>{user.self_introduction}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="my_page_content_profile_list_action">
            <div className="my_page_content_profile_list_action_group">
              <div className="list_action_item">
                <button onClick={() => history.push('/provider/edit', { userData: user })}>
                  プロフィールを編集する
                </button>
              </div>
              <div className="list_action_item">
                <button onClick={() => history.push('/provider/plan')}>現在のプラン</button>
              </div>
            </div>
            <div className="my_page_content_profile_list_action_group">
              <div className="list_action_item">
                <button onClick={() => history.push('/provider/favorites')}>お気に入り一覧</button>
              </div>
              <div className="list_action_item">
                <button onClick={() => history.push('/provider/blocks')}>ブロック一覧</button>
              </div>
            </div>
            <div className="my_page_content_profile_list_action_group">
              <div className="list_action_item">
                <button onClick={() => history.push('/faq')}>よくある質問</button>
              </div>
              <div className="list_action_item">
                <button onClick={() => setNoti(true)}>通知設定</button>
              </div>
            </div>
            <div className="my_page_content_profile_list_action_group">
              <div className="list_action_item purchase_chat_points">
                <button onClick={() => history.push('/chat-point/packages')}>
                  ポイント購入
                  <br />
                  <span className="pc">※ポイントを使用してメッセージ送信が可能です</span>
                  <span className="mobile">
                    ※ポイントを使用して
                    <br />
                    メッセージ送信が可能です
                  </span>
                </button>
              </div>
              <div className="list_action_item padding_item">
                <button></button>
              </div>
            </div>
            <div className="my_page_content_profile_list_action_group action_sp">
              <div className="list_action_item">
                <button onClick={() => setFont(true)}>文字の大きさ設定</button>
              </div>
            </div>
          </div>
        </div>
      )}
      {noti && (
        <Popup
          title="通知設定"
          contentComponent={
            <div className="flex justify-between align-items-center">
              <span>通知のON/OFF設定</span>
              <SwitchButton
                oldValue={user?.noti_status}
                onChange={(checked) => registSettingNotify(checked)}
              />
            </div>
          }
          contentStyle={{ borderTop: '1px solid black', paddingTop: '5vh', marginTop: '-2vh' }}
          onClose={() => setNoti(false)}
        />
      )}
      {font && (
        <Popup
          title="文字の大きさ設定"
          contentComponent={
            <div className="flex justify-evenly align-items-center">
              <span>文字の大きさ</span>
              <div style={{ width: '45%' }}>
                <select
                  className="form-select"
                  onChange={(e) => settingFontRatio(e.target.value)}
                  value={sessionStore.fontRatioSetting}
                >
                  <option value={100}>100%</option>
                  <option value={125}>125%</option>
                  <option value={150}>150%</option>
                  <option value={200}>200%</option>
                </select>
              </div>
            </div>
          }
          contentStyle={{ borderTop: '1px solid black', paddingTop: '5vh', marginTop: '-2vh' }}
          onClose={() => setFont(false)}
        />
      )}
    </div>
  );
});
