import { Button, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';

interface IConfirmCodeProps {
  onSubmitCode: (email: string) => any;
  onResendCode: () => any;
}

export const ConfirmCodeScreen = ({ onSubmitCode, onResendCode }: IConfirmCodeProps) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data: any) => {
    console.log('data: ', data);
    onSubmitCode(data.code);
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <small className="d-block pwd-note">メールアドレスの送信されたコードを入力してください</small>
      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label className="label-base required mb-3">認証コード</Form.Label>
        <input
          type="text"
          className="input-base"
          placeholder="認証コードを入力してください"
          {...register('code', { required: true })}
        />
        {errors.code && errors.code.type === 'required' && (
          <span className="input-base" style={{ color: 'red' }}>
            認証コードが必須項目です。
          </span>
        )}
      </Form.Group>

      <div className="text-center">
        <div>
          <Button variant="base" type="submit">
            OK
          </Button>
        </div>
        <div onClick={onResendCode} className="pwdpage--resend d-block text-center mt-3">
          コードを再送信
        </div>
      </div>
    </Form>
  );
};
