import moment from 'moment';
import { useHistory, useLocation } from 'react-router-dom';

import { useStores } from 'hooks/use-stores';
import { ProviderProfile } from 'modules/user/models';
import { RegisterScreenTemplate } from 'screens/register/components/RegisterScreenTemplate';
import { postFormData } from 'services';
import SessionStore from 'stores/session';
import UIStore from 'stores/ui';
import { toAwait } from 'utils';

export const ProviderEditScreen = () => {
  const history = useHistory();
  const location: any = useLocation();
  let userData: ProviderProfile = {} as ProviderProfile;
  if (location.state) {
    userData = location.state.userData;
  }

  const uiStore: UIStore = useStores().uiStore;
  const sessionStore: SessionStore = useStores().sessionStore;

  const onSubmit = async (data: any) => {
    const loading = uiStore.showLoading();
    const formData = new FormData();
    const newData = { ...data, birthday: moment(data.birthday).format('YYYY/MM/DD') };
    for (let key in newData) {
      if (newData[key]) {
        if (Array.isArray(newData[key])) {
          newData[key].map((item: number | string) => {
            formData.append(key + '[]', item + '');
          });
        } else {
          formData.append(key, newData[key]);
        }
      }
    }

    const [_, err] = await toAwait(postFormData('provider/edit-profile', formData));
    uiStore.hideLoading(loading);
    if (err) {
      console.log('error update profile: ', err);
    } else {
      const message = newData['identity_verification_documents']
        ? '本人確認書類の内容が変更したため本人確認書類が再度申請中となります。再申請中はアカウントは一時停止いたしますがデータは削除されません。'
        : '';
      await uiStore.confirm({
        title: 'プロフィールが更新されました',
        content: message ? message : undefined,
        cancelText: '閉じる',
      });
      if (message) {
        await sessionStore.logout();
        history.replace('/');
      } else {
        history.push('/provider/mypage');
      }
    }
  };

  const deleteAccount = async () => {
    const confirm = await uiStore.confirm({
      title: 'アカウントを本当に削除しますか？',
      content:
        'このアカウントを削除すると、メッセージ履歴などDesireに関するすべてのデータが削除されます。（課金も停止されます）本当に削除しますか？',
      confirmText: 'はい',
      cancelText: 'いいえ',
    });
    if (confirm) {
      await sessionStore.deleteAccount();
      history.replace('/');
    }
  };

  return (
    <>
      <RegisterScreenTemplate isMama={false} onSubmit={onSubmit} userData={userData} />
      <div className="text_register text-center delete-account" onClick={deleteAccount}>
        アカウントを削除する
      </div>
    </>
  );
};
