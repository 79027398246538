import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';

import IcTrash from 'assets/icons/ic_trash.png';
import ICON3 from 'assets/icons/icon-sorder-3.svg';
import DefaultGoldAvatar from 'assets/images/avatar-default-gold.png';
import DefaultAvatar from 'assets/images/img_default_avatar.svg';
import { Pagination, PaginationData } from 'components/common/pagination';
import { PlanType } from 'config/constants';
import { useStores } from 'hooks/use-stores';
import { Order } from 'modules/special-order/models';
import { Plan } from 'modules/user/models';
import { del, get } from 'services';
import { deleteConversation, getUnreadCountForOrder, updateUnread } from 'services/firebase';
import NotificationStore from 'stores/notification';
import SessionStore from 'stores/session';
import UIStore from 'stores/ui';
import { toAwait } from 'utils';
import { formatDateTimeJP } from 'utils/datetime';

import 'screens/mama/special-order/style.scss';
import styles from './styles.module.scss';

export const ProviderOrderListScreen = observer(() => {
  const history = useHistory();

  const uiStore: UIStore = useStores().uiStore;
  const notificationStore: NotificationStore = useStores().notificationStore;
  const sessionStore: SessionStore = useStores().sessionStore;
  const myPlan: Plan | null = sessionStore.session
    ? (sessionStore.session as any).member_plan
    : null;

  const [orders, setOrders] = useState<Array<Order>>([]);
  const [pagination, setPagination] = useState<PaginationData>({
    currentPage: 1,
    limit: 30,
    total: 0,
  });
  const [inited, setInited] = useState<boolean>(false);

  useEffect(() => {
    getData(1).then(() => setInited(true));
  }, []);

  useEffect(() => {
    if (inited) getData(pagination.currentPage); // reload data when new message arrived
  }, [notificationStore.specialCount]);

  const getData = async (page: number) => {
    const loading = uiStore.showLoading();
    const [res, err] = await toAwait(get('provider/orders', { page: page }));
    uiStore.hideLoading(loading);
    if (err) {
      console.log('error when list orders: ', err);
    } else {
      setPagination({
        currentPage: page,
        limit: res.per_page,
        total: res.total,
      });

      let datas = res.data;
      const getUnreadCountTasks = [];
      for (let i = 0; i < datas.length; i++) {
        getUnreadCountTasks.push(
          getUnreadCountForOrder(datas[i].id, sessionStore.session!.id, false)
        );
      }

      Promise.all(getUnreadCountTasks)
        .then((unreadCounts) => {
          console.log('unreadCounts: ', unreadCounts);
          for (let i = 0; i < unreadCounts.length; i++) {
            datas[i].unread_count = unreadCounts[i];
          }
        })
        .finally(() => {
          setOrders(datas);
          uiStore.hideLoading(loading);
        });
    }
  };

  const goToProfile = (order: Order) => {
    const userPlan = myPlan?.type;
    if (userPlan && userPlan >= PlanType.Gold && order.mama) {
      history.push(`/search/${order.mama.id}`, {
        user: order.mama,
        isMama: false,
      });
    }
  };

  const gotoChatOrderScreen = async (order: Order) => {
    const loading = uiStore.showLoading();
    await toAwait(get('provider/orders/' + order.id, {}));
    notificationStore.getUnreadCount();
    uiStore.hideLoading(loading);
    if (order.mama) {
      history.push(`/messages/${order.mama.id}`, {
        order: order,
        conversationId: order.conversation_id || null,
      });
    }
  };

  const renderAvatarSrc = (order: Order) => {
    const userPlan = myPlan?.type;
    if (userPlan && userPlan >= PlanType.Gold) return order.mama?.avatar || DefaultAvatar;
    else return DefaultGoldAvatar;
  };

  const onDeleteClick = async (conversationId: number) => {
    const confirmed = await uiStore.confirm({
      title: '削除',
      contentComponent: (
        <p>
          スレッドメッセージを削除します。<br></br>よろしいでしょうか。
        </p>
      ),
      confirmText: 'OK',
      cancelText: 'キャンセル',
    });

    if (confirmed && sessionStore.session) {
      try {
        await deleteConversation(conversationId, sessionStore.session.id);
        await updateUnread(sessionStore.session.id, sessionStore.session.role, conversationId.toString());

        //TODO: invoke API to delete conversation
        const res = await del(`message/delete-inbox/${conversationId}`);
        console.log('Delete api res: ', res);
      } catch (err) {
        console.log('Delete thread error: ', err);
      } finally {
        getData(1);
      }
    }
  };

  const renderItem = (order: Order) => {
    const userPlan = myPlan?.type;
    const unreadCount = order.unread_count || 0;
    return (
      <Row key={order.id} style={{ flexWrap: 'nowrap' }}>
        {order.conversation_id && (
          <Col
            style={{
              flex: 0,
              paddingRight: 0,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <img
              src={IcTrash}
              alt=""
              className={styles.deleteButton}
              onClick={() => order.conversation_id && onDeleteClick(order.conversation_id)}
            />
          </Col>
        )}

        <Col style={{ flex: 0 }} className="flex items-center" onClick={() => goToProfile(order)}>
          <img src={renderAvatarSrc(order)} className="avatar" />
        </Col>
        <Col
          style={{ flex: 1 }}
          className="flex flex-column justify-center"
          onClick={() => gotoChatOrderScreen(order)}
        >
          <div className="name">
            {userPlan && userPlan >= PlanType.Gold
              ? order.mama?.nick_name
              : 'ゴールド会員になると相手のプロフィールが確認できます。'}
          </div>
          <table>
            <tbody>
              <tr>
                <td>
                  <span>希望日</span>
                </td>
                <td>
                  <span>：{formatDateTimeJP(order.desired_datetime)}</span>
                </td>
              </tr>
              <tr>
                <td>
                  <span>希望デート利用時間</span>
                </td>
                <td>
                  <span>：{order.desired_time}時間</span>
                </td>
              </tr>
              <tr>
                <td>
                  <span>希望場所</span>
                </td>
                <td>
                  <span>：{order.desired_place}</span>
                </td>
              </tr>
              <tr>
                <td>
                  <span>希望金額</span>
                </td>
                <td>
                  <span>：{order.desired_price}円</span>
                </td>
              </tr>
            </tbody>
          </table>
          <div className="flex items-start">
            <div>
              <span className="d-block">{order.message}</span>
            </div>
          </div>
        </Col>
        <Col
          style={{ flex: 0 }}
          className={`d-flex flex-column justify-content-${unreadCount > 0 ? 'between' : 'end'}`}
          onClick={() => gotoChatOrderScreen(order)}
        >
          {unreadCount > 0 && (
            <div className={`d-flex justify-content-center align-items-center unread-count`}>
              {unreadCount < 100 ? unreadCount : '99+'}
            </div>
          )}
          <img src={ICON3} alt="icon3" />
        </Col>
      </Row>
    );
  };

  return (
    <div className="sorder-page">
      <Helmet>
        <title>スペシャルオーダー</title>
      </Helmet>
      <Container>
        <div className="text-center top-title-h1">
          <span>SPECIAL ORDER</span>
        </div>
        <div className="sub-title">スペシャルオーダー</div>

        <div className="sorder-page-inner message-list-inner">
          {orders.map((item) => renderItem(item))}
        </div>

        <Pagination pagination={pagination} onClickPage={(page) => getData(page)} />
      </Container>
    </div>
  );
});
