import FacebookLogin from 'react-facebook-login';

import { FACEBOOK_ID } from 'config';
import { SnsUserData } from '../models/UserProfile';

interface FacebookBtnProps {
  loginResponse: (err: Error | null, userData?: SnsUserData) => any;
  textBtn: string;
}

export const FacebookLoginBtn = ({ loginResponse, textBtn }: FacebookBtnProps) => {
  const responseFacebook = (response: any) => {
    console.log(response);
    if (!response.accessToken) {
      loginResponse(new Error('Login facebook failed'));
    } else {
      const userData: SnsUserData = {
        accessToken: response.accessToken,
        userID: response.userID,
        email: response.email,
        birthday: response.birthday,
        name: response.name,
        first_name: response.first_name,
        last_name: response.last_name,
      };
      loginResponse(null, userData);
    }
  };

  return FACEBOOK_ID ? (
    <FacebookLogin
      appId={FACEBOOK_ID}
      fields="name,first_name,last_name,email,picture,birthday"
      scope="public_profile,email,user_birthday"
      callback={responseFacebook}
      textButton={textBtn || 'Facebookでログイン'}
      cssClass="btn blue"
      isMobile={true}
      disableMobileRedirect={true}
    />
  ) : null;
};
