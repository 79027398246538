import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';

import { FileInputButton } from 'components/FileInputButton';
import { Popup } from 'components/Popup';

interface IProps {
  onFileChange: any;
  oldValue?: string;
}

export const FileSelectorPopup = ({ onFileChange, oldValue }: IProps) => {
  const [src, setSrc] = useState<any>(null);
  const [popup, setPopup] = useState(false);
  const fileRef = React.createRef<HTMLInputElement>();

  useEffect(() => {
    if (oldValue) setSrc(oldValue);
  }, [oldValue]);

  let bgStyle = !!src
    ? {
        backgroundImage: `url(${src})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
      }
    : {};

  return (
    <div className="bg_upload_form" style={bgStyle}>
      <Button
        className="upload_btn"
        onClick={(e) => {
          e.preventDefault();
          if (oldValue) {
            setPopup(true);
          } else {
            if (fileRef.current) fileRef.current.click();
          }
        }}
      >
        アップロード
      </Button>
      <input
        type="file"
        name="avatar"
        accept=".png,.jpeg,.jpg"
        onChange={(e) => {
          if (!!fileRef.current?.files?.length) {
            const file = fileRef.current.files[0];
            setSrc(URL.createObjectURL(file));
            setPopup(false);
            onFileChange(file);
          }
        }}
        className="d-none"
        ref={fileRef}
      />
      {popup && (
        <Popup
          title="口座確認書類"
          contentComponent={
            <>
              <div className="preview_old_identity" style={bgStyle}></div>
              <p>
                口座情報が変更した際は再度口座確認書類をアップロードして頂けますようお願いいたします。
              </p>
            </>
          }
          ButtonComponent={
            <FileInputButton
              onChange={(file: File) => {
                setSrc(URL.createObjectURL(file));
                setPopup(false);
                onFileChange(file);
              }}
            />
          }
          onClose={() => setPopup(false)}
        />
      )}
    </div>
  );
};
