import moment from "moment";

export function formatDate(date: string) {
  // yyyy/MM/dd
  return moment(date).format('YYYY/MM/DD');
}

export function formatTime(date: string) {
  // hh:mm
  return moment(date).format('HH:mm');
}

export function formatDateTime(date: string) {
  // yyyy/MM/dd hh:mm
  return `${formatDate(date)} ${formatTime(date)}`;
}

export function formatDateJP(date: string) {
  // yyyy年MM月dd日
  return moment(date).format('YYYY年MM月DD日');
}

export function formatDateTimeJP(date: string) {
  // yyyy年MM月dd日 hh:mm
  return `${formatDateJP(date)} ${formatTime(date)}`;
}
