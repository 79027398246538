import moment from 'moment';
import { useHistory } from 'react-router-dom';

import DefaultGoldAvatar from 'assets/images/avatar-default-gold.png';
import DefaultPaidAvatar from 'assets/images/avatar-default-paid.png';
import DefaultAvatar from 'assets/images/img_default_avatar.svg';
import { PlanType } from 'config/constants';
import { Order } from 'modules/special-order/models';
import { formatDateTimeJP } from 'utils/datetime';

interface IProps {
  order: Order;
  isMama: boolean;
  userPlan?: PlanType;
  permissionNeed?: PlanType;
}

export const OrderContent = ({ order, isMama, userPlan, permissionNeed }: IProps) => {
  const createAt = order.created_at ? new Date(order.created_at) : new Date();
  const renderAvatarSrc = () => {
    if (userPlan && permissionNeed) {
      if (userPlan >= permissionNeed) return order.mama?.avatar || DefaultAvatar;
      if (permissionNeed === PlanType.Standard) return DefaultPaidAvatar;
      if (permissionNeed === PlanType.Gold) return DefaultGoldAvatar;
    }
    return DefaultAvatar;
  };

  const history = useHistory();
  const goToProfile = () => {
    if (userPlan && permissionNeed) {
      if (userPlan < permissionNeed) return;
    }
    history.push(`/search/${order.mama?.id}`, {
      user: order.mama,
      isMama: false,
    });
  };

  return isMama ? (
    <div className="me order-content d-flex justify-content-end">
      <div className="send-time">
        <div>
          <p className="status">既読</p>
          <p className="date">{moment(createAt).format('YYYY年M月D日')}</p>
          <p className="time">{moment(createAt).format('HH:mm')}</p>
        </div>
      </div>
      <div className="content">
        <div className="text">
          <p>{`希望日　　　　　　：${formatDateTimeJP(order.desired_datetime)}
              希望デート利用時間：${order.desired_time}時間
              希望場所　　　　　：${order.desired_place}
              希望金額　　　　　：${order.desired_price}円
              ${order.message}`}</p>
        </div>
        <div className="icon-right"></div>
      </div>
    </div>
  ) : (
    <div className="guest order-content">
      {<img className="avatar" src={renderAvatarSrc()} alt="" onClick={() => goToProfile()} />}
      <div className="content">
        <div className="icon-left"></div>
        <div className="text">
          <p>{`希望日　　　　　　：${formatDateTimeJP(order.desired_datetime)}
              希望デート利用時間：${order.desired_time}時間
              希望場所　　　　　：${order.desired_place}
              希望金額　　　　　：${order.desired_price}円
              ${order.message}`}</p>
        </div>
      </div>
      <div className="send-time">
        <div>
          <p className="date">{moment(createAt).format('YYYY年M月D日')}</p>
          <p className="time">{moment(createAt).format('HH:mm')}</p>
        </div>
      </div>
    </div>
  );
};
