import { useEffect } from 'react';

import LayoutStore from 'stores/layout';
import { useStores } from './use-stores';

const useLayout = () => {
  const layoutStore: LayoutStore = useStores().layoutStore;

  useEffect(() => {
    if (window.innerWidth <= 991) {
      layoutStore.hideHeader();
      layoutStore.hideFooter();
    }
    // Specify hide header and footer on mobile
    return () => {
      layoutStore.showHeader();
      layoutStore.showFooter();
    };
  }, []);
};

export default useLayout;
