import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { Route, useHistory, useRouteMatch } from 'react-router-dom';

import { useStores } from 'hooks/use-stores';
import UIStore from 'stores/ui';
import { toAwait } from 'utils';
import SessionStore from '../../stores/session';
import { ChangePassScreen } from './change-pass';
import { ConfirmCodeScreen } from './confirm-code';
import { ConfirmEmailScreen } from './confirm-email';

import './style.scss';

export const ForgotPasswordScreen = () => {
  const history = useHistory();
  const match = useRouteMatch();

  const uiStore: UIStore = useStores().uiStore;
  const sessionStore: SessionStore = useStores().sessionStore;

  const confirmEmail = async (email: string) => {
    const loading = uiStore.showLoading();
    let [res, err] = await toAwait(sessionStore.forgotPass(email));
    uiStore.hideLoading(loading);
    if (err) {
      console.log('cannot send code to email: ', err);
      return;
    }

    sessionStore.setForgotPassProcess(email);
    history.push(`${match.path}/confirm-code`);
  };

  const confirmCode = async (code: string) => {
    const email = sessionStore.forgotPassProcess?.email || '';
    const loading = uiStore.showLoading();
    let [res, err] = await toAwait(sessionStore.checkCode(email, code));
    uiStore.hideLoading(loading);
    if (err) {
      console.log('cannot verify code: ', err);
      return;
    }

    sessionStore.setForgotPassProcess(email, code);
    history.push(`${match.path}/change-pass`);
  };

  const resendCode = async () => {
    const email = sessionStore.forgotPassProcess?.email || '';
    const loading = uiStore.showLoading();
    let [res, err] = await toAwait(sessionStore.forgotPass(email));
    uiStore.hideLoading(loading);
    if (err) {
      console.log('cannot send code to email: ', err);
      return;
    }
    uiStore.showAlert({
      type: 'alert',
      message: '認証コードを再度送信しました。',
      btnText: '閉じる',
    });
  };

  const changePassword = async (password: string) => {
    const forgotData = sessionStore.forgotPassProcess;
    if (!forgotData) return;
    const loading = uiStore.showLoading();
    let [res, err] = await toAwait(
      sessionStore.resetPass(forgotData.email, forgotData.token, password)
    );
    uiStore.hideLoading(loading);
    if (err) {
      console.log('cannot reset password: ', err);
      return;
    }

    await uiStore.confirm({
      title: 'パスワードの変更が完了いたしました',
      confirmText: 'ログイン画面へ',
    });

    history.push('/login');
  };

  return (
    <Container className="pwdpage">
      <Helmet>
        <title>パスワード再設定</title>
      </Helmet>
      <h3 className="mb-3 title">パスワード再設定</h3>
      <Route exact path={`${match.path}`}>
        <ConfirmEmailScreen onSubmitEmail={confirmEmail} />
      </Route>
      <Route exact path={`${match.path}/confirm-code`}>
        <ConfirmCodeScreen onSubmitCode={confirmCode} onResendCode={resendCode} />
      </Route>
      <Route exact path={`${match.path}/change-pass`}>
        <ChangePassScreen onSubmitPassword={changePassword} />
      </Route>
    </Container>
  );
};
