import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';

import { PlanType, UserRole } from 'config/constants';
import { useStores } from 'hooks/use-stores';
import { Plan } from 'modules/user/models';
import { get } from 'services';
import { getUnreadCount } from 'services/firebase';
import NotificationStore from 'stores/notification';
import SessionStore from 'stores/session';
import UIStore from 'stores/ui';
import { toAwait } from 'utils';
import { ConversationItem } from './components/ConversationItem';

import './styles.scss';

export const MessageListScreen = observer(() => {
  const uiStore: UIStore = useStores().uiStore;
  const notificationStore: NotificationStore = useStores().notificationStore;
  const sessionStore: SessionStore = useStores().sessionStore;
  const myPlan: Plan | null =
    sessionStore.session && sessionStore.session.role !== UserRole.Mama
      ? (sessionStore.session as any).member_plan
      : null;

  const [conversations, setConversations] = useState<Array<any>>([]);

  useEffect(() => {
    if (uiStore.inboxList.length) {
      setConversations(uiStore.inboxList);
    }

    fetchData();
    // reload profile to get latest user chat points
    getProfileData();
  }, [notificationStore.messageCount]);

  const fetchData = async () => {
    const isMama =
      sessionStore.session && sessionStore.session.role === UserRole.Mama ? true : false;
    const loadingId = !uiStore.inboxList.length ? uiStore.showLoading() : '0';
    try {
      const res = await get('message/inbox', {});
      console.log('API res: ', res);
      uiStore.hideLoading(loadingId);

      const getUnreadCountTasks = [];
      for (let i = 0; i < res.length; i++) {
        getUnreadCountTasks.push(getUnreadCount(res[i].id.toString(), isMama));
      }

      Promise.all(getUnreadCountTasks)
        .then((unreadCounts) => {
          console.log('unreadCounts: ', unreadCounts);
          for (let i = 0; i < unreadCounts.length; i++) {
            res[i].unread_count = unreadCounts[i];
          }
        })
        .finally(() => {
          setConversations(res);
          uiStore.setInboxList(res);
        });
    } catch (err) {
      console.log('API error: ', err);
      uiStore.hideLoading(loadingId);
    }
  };

  const getProfileData = async () => {
    let [profile, err] = await toAwait(get('profile', []));
    if (err) {
      console.log('error get my profile', err);
      return;
    } else {
      sessionStore.updateProfile(profile);
    }
  };

  return (
    <main className="message-list">
      <Container>
        <div className="text-center top-title-h1">
          <span>MESSAGE</span>
        </div>
        <div className="sub-title">メッセージ</div>
        <div className="message-list-inner">
          {conversations.map((item, index) => (
            <ConversationItem
              key={index}
              item={item}
              userPlan={myPlan?.type}
              permissionNeed={PlanType.Standard}
              onDeleteComplete={() => fetchData()}
            />
          ))}
        </div>
      </Container>
    </main>
  );
});
