import { useEffect } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';

import { BankData } from '.';
import { FileSelectorPopup } from './FilePopup';

interface IFromTransferProps {
  onSubmitData: (data: any) => any;
  bankData?: BankData;
}

export const FormTransferComponent = ({ onSubmitData, bankData }: IFromTransferProps) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (bankData) {
      if (bankData.bank_name) setValue('bank_name', bankData.bank_name);
      if (bankData.account_type) setValue('account_type', bankData.account_type);
      if (bankData.branch_code) setValue('branch_code', bankData.branch_code);
      if (bankData.account_number) setValue('account_number', bankData.account_number);
      if (bankData.account_name) setValue('account_name', bankData.account_name);
      if (bankData.account_document_confirm) {
        register('account_document_confirm', { required: false });
      } else {
        register('account_document_confirm', { required: true });
      }
    }
  }, [bankData]);

  const onSubmit = async (data: any) => {
    console.log('data: ', data);
    onSubmitData(data);
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Form.Group className="change-pass-form">
        <Form.Label className="label-base required mb-3">銀行</Form.Label>
        <input
          type="text"
          className="input-base"
          placeholder="銀行を入力してください"
          {...register('bank_name', { required: true })}
        />
        {errors.bank_name && errors.bank_name.type === 'required' && (
          <span className="input-base" style={{ color: 'red' }}>
            銀行が必須項目です。
          </span>
        )}
      </Form.Group>
      <Form.Group className="change-pass-form">
        <Form.Label className="label-base required mb-3">口座種別</Form.Label>
        <input
          type="text"
          className="input-base"
          placeholder="口座種別を入力してください"
          {...register('account_type', { required: true })}
        />
        {errors.account_type && errors.account_type.type === 'required' && (
          <span className="input-base" style={{ color: 'red' }}>
            口座種別が必須項目です。
          </span>
        )}
      </Form.Group>
      <Form.Group className="change-pass-form">
        <Form.Label className="label-base required mb-3">支店コード</Form.Label>
        <input
          type="text"
          className="input-base"
          placeholder="支店コードを入力してください"
          {...register('branch_code', { required: true })}
        />
        {errors.branch_code && errors.branch_code.type === 'required' && (
          <span className="input-base" style={{ color: 'red' }}>
            支店コードが必須項目です。
          </span>
        )}
      </Form.Group>
      <Form.Group className="change-pass-form">
        <Form.Label className="label-base required mb-3">口座番号</Form.Label>
        <input
          type="text"
          className="input-base"
          placeholder="口座番号を入力してください"
          {...register('account_number', { required: true })}
        />
        <span className="label-base" style={{ marginTop: '5px' }}>
          ※口座番号が7桁未満の場合は先頭に0をつけてください。
        </span>
        {errors.account_number && errors.account_number.type === 'required' && (
          <span className="input-base" style={{ color: 'red' }}>
            口座番号が必須項目です。
          </span>
        )}
      </Form.Group>
      <Form.Group className="change-pass-form">
        <Form.Label className="label-base required mb-3">口座名義</Form.Label>
        <input
          type="text"
          className="input-base"
          placeholder="口座名義を入力してください"
          {...register('account_name', { required: true })}
        />
        {errors.account_name && errors.account_name.type === 'required' && (
          <span className="input-base" style={{ color: 'red' }}>
            口座名義が必須項目です。
          </span>
        )}
      </Form.Group>
      <Form.Group className="change-pass-form">
        <Form.Label className="label-base required mb-3">口座確認書類</Form.Label>
        <FileSelectorPopup
          onFileChange={(file: File) =>
            setValue('account_document_confirm', file)
          }
          oldValue={bankData?.account_document_confirm}
        />
        {errors.account_document_confirm && errors.account_document_confirm.type === 'required' && (
          <span className="input-base" style={{ color: 'red' }}>
            口座確認書類が必須項目です。
          </span>
        )}
      </Form.Group>

      <div className="text-center note">
        お振込は保有ポイントが10,000ptから対応いたします。お振込は月末締めの翌月15日払いとなります。お振込日にお振込がされない場合はお問い合わせよりご連絡いただけますと幸いです。振込先が間違っている場合、再度振込手数料が発生いたします。登録された身分証の氏名と振込口座が一致しない場合、振込申請をすることはできません。振込口座はご本人名義の口座のみご利用いただけます。口座名義が旧性の場合は名義変更の上改めてお手続きをお願いいたします。
      </div>

      <div className="text-center">
        <Button variant="base" type="submit">
          申請
        </Button>
      </div>
    </Form>
  );
};
