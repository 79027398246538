import { getMessaging, getToken, isSupported, Messaging, onMessage } from 'firebase/messaging';

import { FIREBASE_VAPID_KEY } from 'config';
import { app } from '.';

let messaging: Messaging;
let FCMToken: string = '';

export const isSupportedPush = async () => {
  const pushSupported = await isSupported();
  console.log('Browser support push: ', pushSupported);
  return pushSupported;
};

export const askForPermissionToReceiveNotifications = async () => {
  messaging = getMessaging(app);
  const token = await getToken(messaging, { vapidKey: FIREBASE_VAPID_KEY });
  console.log('Your token is:', token);
  return token;
};

export const listenToNotify = (callback: (payload: any) => any) => {
  onMessage(messaging, (payload) => {
    console.log('Received foreground message: ', payload);
    callback(payload.data);
  });
};

export const setFCMToken = (token: string) => {
  FCMToken = token;
};

export const getFCMToken = () => FCMToken;
