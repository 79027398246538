import moment from 'moment';
import { useEffect, useRef, useState } from 'react';

import IcDots from 'assets/icons/ic_dots.svg';
import { MessageType } from 'config/constants';
import { Message } from 'modules/message/models';
import ImageView from './ImageView';

interface IProps {
  message: Message;
  onDelete: () => any;
}

export const MyMessage = ({ message, onDelete }: IProps) => {
  const [isFocus, setIsFocus] = useState<boolean>(false);
  const wrapperRef = useRef<any>(null);
  const [zoomIn, setZoomIn] = useState(false);

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (wrapperRef && wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setIsFocus(false);
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  let date = '';
  let time = '';
  if (message.created_at) {
    date = moment(message.created_at.toDate()).format('YYYY年M月D日');
    time = moment(message.created_at.toDate()).format('HH:mm');
  }

  const renderImages = () => {
    if (message.type === MessageType.Images && message.image_urls) {
      return (
        <p>
          {zoomIn && (
            <ImageView imageUrl={message.image_urls[0]} onClose={() => setZoomIn(false)} />
          )}
          {message.image_urls.map((image, index) => (
            <img
              key={index}
              src={image}
              alt=""
              style={{ maxHeight: '10rem', maxWidth: '100%', cursor: 'pointer' }}
              onClick={() => setZoomIn(true)}
            />
          ))}
        </p>
      );
    } else return null;
  };

  return (
    <div
      className="me d-flex justify-content-end"
      onClick={() => !message.is_deleted && setIsFocus(true)}
      ref={wrapperRef}
    >
      {isFocus && <MessageActionBtn onDeleteClick={onDelete} />}
      <div className="send-time">
        <div>
          {message.is_read ? <p className="status">既読</p> : null}
          <p className="date">{date}</p>
          <p className="time">{time}</p>
        </div>
      </div>
      {!message.is_deleted && (
        <div
          className="content"
          style={{
            marginRight: '4px',
          }}
        >
          <div className="text">
            {message.type === MessageType.Text && <p> {message.text}</p>}
            {renderImages()}
          </div>
          <div className="icon-right"></div>
        </div>
      )}
      {message.is_deleted && <div className="content deleted">メッセージが削除されました</div>}
    </div>
  );
};

const MessageActionBtn = ({ onDeleteClick }: { onDeleteClick: () => void }) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    const handler = (event: MouseEvent) => {
      const popupEl = document.getElementById('delete-popup');
      const targetEl = event.target;
      console.log('Target: ', targetEl, targetEl === popupEl);
      if (popupEl !== targetEl) setShow(false);
    };

    if (show) {
      document.addEventListener('click', handler);
    }

    return () => {
      document.removeEventListener('click', handler);
    };
  }, [show]);

  return (
    <div style={{ position: 'relative' }} className="d-flex flex-cols justify-content-end">
      <img src={IcDots} style={{ color: 'white' }} alt="" onClick={() => setShow(true)} />
      {show && (
        <div style={{ position: 'absolute', top: 35 }}>
          <button
            id="delete-popup"
            style={{
              backgroundColor: '#d10b02',
              color: 'white',
              border: 'none',
              fontSize: 12,
              width: 'max-content',
              padding: '2px 8px',
              borderRadius: 3,
            }}
            onClick={onDeleteClick}
          >
            削除
          </button>
        </div>
      )}
    </div>
  );
};
