import { Col, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import IconCompass from 'assets/icons/ic_compass.svg';
import IconFolder from 'assets/icons/ic_folder.svg';
import IconArrow from 'assets/icons/ic_right_arrow.svg';
import IcTrash from 'assets/icons/ic_trash.png';
import DefaultPaidAvatar from 'assets/images/avatar-default-paid.png';
import DefaultAvatar from 'assets/images/img_default_avatar.svg';
import { MessageType, PlanType, UserRole } from 'config/constants';
import { useStores } from 'hooks/use-stores';
import { Conversation } from 'modules/message/models';
import { Order } from 'modules/special-order/models';
import { MamaProfile, ProviderProfile } from 'modules/user/models';
import { deleteConversation, updateUnread } from 'services/firebase';
import SessionStore from 'stores/session';
import UIStore from 'stores/ui';
import { getGender, getGeneration, getPrefecture, getProviderType, getPurpose } from 'utils';
import { del } from 'services';

import styles from './styles.module.scss';

interface IProps {
  item: Conversation;
  userPlan?: PlanType;
  permissionNeed?: PlanType;
  order?: Partial<Order>;
  onDeleteComplete: () => void;
}

export const ConversationItem = ({
  item,
  userPlan,
  permissionNeed,
  order,
  onDeleteComplete,
}: IProps) => {
  const history = useHistory();

  const uiStore: UIStore = useStores().uiStore;
  const sessionStore: SessionStore = useStores().sessionStore;

  const renderServices = () => {
    const provider = item.user as ProviderProfile;
    return (
      <>
        {provider.member_services && provider.member_services.includes(1) && (
          <div className="lbl-gender lbl-gender-purple">レズビアン</div>
        )}
        {provider.member_services && provider.member_services.includes(2) && (
          <div className="lbl-gender lbl-gender-orange">マッサージ</div>
        )}
        {provider.member_services && provider.member_services.includes(3) && (
          <div className="lbl-gender lbl-gender-red">レンタル彼氏</div>
        )}
      </>
    );
  };

  const renderAvatarSrc = () => {
    if (item.user.role === UserRole.Provider) return item.user.avatar || DefaultAvatar;
    if (userPlan && permissionNeed) {
      if (userPlan >= permissionNeed) return item.user.avatar || DefaultAvatar;
      else return DefaultPaidAvatar;
    }
    return DefaultAvatar;
  };

  const checkPermission = async () => {
    if (item.user.role !== UserRole.Provider) {
      if (
        ((userPlan && permissionNeed && userPlan < permissionNeed) || !userPlan) &&
        sessionStore.session &&
        sessionStore.session?.point < 50
      ) {
        const confirm = await uiStore.confirm({
          title:
            'メッセージを開くためには有料会員になるか、<br/>ポイントを使用をする必要があります',
          content:
            '無料会員としてはお相手のプロフィールを確認することができます。その他お気に入り（相手に通知はされません）やブロック、通報なども可能です。実際に連絡を取ることなどはできません。連絡を取るためには有料会員になる、またはポイントを使用する必要がございます。',
          confirmText: '有料会員になる',
          confirmText2: 'ポイント購入する',
          cancelText: '閉じる',
        });
        if ((confirm as any).confirm2) {
          history.push('/chat-point/packages');
        } else if (confirm == true) {
          history.push('/provider/plan');
        }
        return false;
      }
    }
    return true;
  };

  const goToProfile = async () => {
    const check = await checkPermission();
    if (check) {
      history.push(`/search/${item.user.id}`, {
        user: item.user,
        isMama: item.user.role === UserRole.Provider,
      });
    }
  };

  const goToChat = async () => {
    const check = await checkPermission();
    if (check) {
      history.push(`/messages/${item.user.id}`, {
        order: order,
        conversationId: item.id,
      });
    }
  };

  const onDeleteClick = async () => {
    const confirmed = await uiStore.confirm({
      title: '削除',
      contentComponent: (
        <p>
          スレッドメッセージを削除します。<br></br>よろしいでしょうか。
        </p>
      ),
      confirmText: 'OK',
      cancelText: 'キャンセル',
    });
    if (confirmed && sessionStore.session) {
      try {
        await deleteConversation(item.id, sessionStore.session.id);
        await updateUnread(sessionStore.session.id, sessionStore.session.role, item.id.toString());

        //TODO: invoke API to delete conversation
        const res = await del(`message/delete-inbox/${item.id}`);
        console.log('Delete api res: ', res);
      } catch (err) {
        console.log('Delete thread error: ', err);
      } finally {
        onDeleteComplete();
      }
    }
  };

  const unreadCount =
    item.latest_message && item.latest_message.sender_id == item.user.id ? item.unread_count : 0;

  function detectMob() {
    return window.innerWidth <= 800;
  }

  return (
    <Row style={{ flexWrap: 'nowrap' }}>
      <Col
        style={{
          flex: 0,
          paddingRight: 0,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <img src={IcTrash} alt="" className={styles.deleteButton} onClick={onDeleteClick} />
      </Col>
      <Col
        style={{ flex: 0, cursor: 'pointer' }}
        className="flex items-center"
        onClick={() => goToProfile()}
      >
        <div className="position-relative">
          <div>
            <img src={renderAvatarSrc()} className="avatar" alt="" />
            {(item.user as ProviderProfile).is_receive_gif === 1 && (
              <div className="received_gift">
                物品提供
                <br />
                でも可
              </div>
            )}
          </div>
          {detectMob() && <p className="user-name">{item.user.nick_name}</p>}
        </div>
      </Col>
      <Col
        style={{ flex: 1, cursor: 'pointer' }}
        className="flex flex-column justify-start"
        onClick={() => goToChat()}
      >
        <div className="name">
          {item.user.role === UserRole.Provider && renderServices()}
          {!detectMob() && item.user.nick_name}
        </div>
        <div className="flex items-center">
          <img src={IconFolder} alt="icon1" className="sub-icon" />
          <span style={{ marginRight: '1rem' }}>{`${getGeneration(item.user.birthday)}代`}</span>
          <span style={{ marginRight: '1rem' }}>{getGender(item.user.gender)}</span>
          <span>
            {item.user.role === UserRole.Mama &&
              (item.user as MamaProfile).purpose_id &&
              getPurpose((item.user as MamaProfile).purpose_id)}
            {item.user.role === UserRole.Provider &&
              (item.user as ProviderProfile).member_type_id &&
              getProviderType((item.user as ProviderProfile).member_type_id)}
          </span>
        </div>
        <div className="flex items-start">
          <img src={IconCompass} alt="icon2" className="sub-icon" />
          <div className="last-message-item">
            <span className="d-block">{getPrefecture(item.user.prefecture_id)}</span>
            {item.latest_message && (
              <span className="last-message-txt">
                {item.latest_message.type === MessageType.Images &&
                  item.user.id === item.latest_message.sender_id &&
                  item.user.nick_name + 'さんが写真を送りました'}
                {item.latest_message.type === MessageType.Images &&
                  item.user.id !== item.latest_message.sender_id &&
                  'あなたが写真を送りました'}
                {item.latest_message.type === MessageType.Text && item.latest_message.message}
              </span>
            )}
          </div>
        </div>
      </Col>
      <Col
        style={{ flex: 0, cursor: 'pointer' }}
        className={`d-flex flex-column justify-content-${unreadCount > 0 ? 'between' : 'end'}`}
        onClick={() => goToChat()}
      >
        {unreadCount > 0 && (
          <div className={`d-flex justify-content-center align-items-center unread-count`}>
            {unreadCount < 100 ? unreadCount : '99+'}
          </div>
        )}
        <a>
          <img src={IconArrow} alt="icon3" />
        </a>
      </Col>
    </Row>
  );
};
