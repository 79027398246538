import { useState } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';

import IconSearch from 'assets/icons/ic_search.svg';
import Img1 from 'assets/images/image1.png';
import Img2 from 'assets/images/image2.png';
import Img3 from 'assets/images/image3.png';
import {
  ANNUAL_INCOMES,
  EXPECTED_HOURLY_RATES,
  GENDERS,
  PREFECTURES,
  PROVIDER_TYPES,
  PURPOSES,
} from 'config/constants';

const GENERATION: Array<number> = [];
for (let i = 1; i <= 10; i++) {
  GENERATION.push(i * 10);
}
const AGES: Array<number> = [];
for (let i = 18; i <= 100; i++) {
  AGES.push(i);
}

interface IProps {
  isMama?: boolean;
  onSearch: (data: any) => void;
}

export const Filter = ({ isMama = false, onSearch }: IProps) => {
  const [activeBtn, setActiveBtn] = useState<null | number>(null);

  const { register, handleSubmit, control } = useForm();

  const onSubmit = (data: any) => {
    data = { ...data, is_receive_gift: data.is_receive_gift ? '1' : '' };
    onSearch(data);
  };

  const renderKeywordFilter = () => (
    <Form.Group as={Row} className="g-0">
      <Form.Label column lg="3">
        キーワード
      </Form.Label>
      <Col lg="9" className="input">
        <input
          className="form-control"
          placeholder="キーワードを入力してください"
          {...register('keyword')}
        />
      </Col>
    </Form.Group>
  );

  const renderPrefectureFilter = () => (
    <Form.Group as={Row} className="g-0">
      <Form.Label column lg="3">
        地域
      </Form.Label>
      <Col lg="9" className="input">
        <Form.Select {...register('prefecture_id')}>
          <option value="">指定なし</option>
          {Object.keys(PREFECTURES).map((prefectureId: string) => (
            <option key={prefectureId} value={prefectureId}>
              {PREFECTURES[parseInt(prefectureId)]}
            </option>
          ))}
        </Form.Select>
      </Col>
    </Form.Group>
  );

  const renderAgeFilter = () => (
    <Form.Group as={Row} className="g-0">
      <Form.Label column lg="3">
        年齢
      </Form.Label>
      <Col lg="9" className="input">
        <Row className="g-0 w-100">
          <Col lg="5" className="select-item">
            <Form.Select {...register('age_from')}>
              <option value="">指定なし</option>
              {isMama
                ? AGES.map((age) => (
                    <option key={age} value={age}>
                      {age}歳
                    </option>
                  ))
                : GENERATION.map((age) => (
                    <option key={age} value={age}>
                      {age}代
                    </option>
                  ))}
            </Form.Select>
          </Col>
          <Col lg="2" className="flex items-center justify-center select-label" style={{ flex: 1 }}>
            <span>～</span>
          </Col>
          <Col lg="5" className="flex justify-end select-item">
            <Form.Select {...register('age_to')}>
              <option value="">指定なし</option>
              {isMama
                ? AGES.map((age) => (
                    <option key={age} value={age}>
                      {age}歳
                    </option>
                  ))
                : GENERATION.map((age) => (
                    <option key={age} value={age + 9}>
                      {age}代
                    </option>
                  ))}
            </Form.Select>
          </Col>
        </Row>
      </Col>
    </Form.Group>
  );

  const renderAnnualIncomeFilter = () => (
    <Form.Group as={Row} className="g-0" controlId="formPlaintextEmail">
      <Form.Label column lg="3">
        年収
      </Form.Label>
      <Col lg="9" className="input">
        <Form.Select {...register('annual_income_id')}>
          <option value="">指定なし</option>
          {Object.keys(ANNUAL_INCOMES).map((key) => (
            <option key={key} value={key}>
              {ANNUAL_INCOMES[parseInt(key)]}
            </option>
          ))}
        </Form.Select>
      </Col>
    </Form.Group>
  );

  const renderPurposeFilter = () => (
    <Form.Group as={Row} className="g-0" controlId="formPlaintextEmail">
      <Form.Label column lg="3">
        目的
      </Form.Label>
      <Col lg="9" className="last input">
        <Form.Select {...register('purpose_id')}>
          <option value="">指定なし</option>
          {Object.keys(PURPOSES).map((key) => (
            <option key={key} value={key}>
              {PURPOSES[parseInt(key)]}
            </option>
          ))}
        </Form.Select>
      </Col>
    </Form.Group>
  );

  const renderGenderFilter = () => (
    <Form.Group as={Row} className="g-0">
      <Form.Label column lg="3">
        性別
      </Form.Label>
      <Col lg="9" className="input">
        {GENDERS.map((gender, index) => (
          <Col key={index} lg="4">
            <label className="radio">
              <input type="radio" value={gender.value} {...register('gender')} />
              <span>{gender.label}</span>
            </label>
          </Col>
        ))}
      </Col>
    </Form.Group>
  );

  const renderExpectedHourlyRateFilter = () => (
    <Form.Group as={Row} className="g-0">
      <Col lg="3" className="text">
        <div className="main">希望報酬 / 時</div>
        <div className="sub">希望報酬はサービス提供側の希望金額となります。</div>
        <div className="sub">メッセージで交渉することも可能です</div>
      </Col>
      <Col lg="9" className="inline-block input">
        <Col lg="12" className="col-group1">
          <Form.Select {...register('expect_salary_per_hour')}>
            <option value="">指定なし</option>
            {Object.keys(EXPECTED_HOURLY_RATES).map((key: string) => (
              <option key={key} value={key}>
                {EXPECTED_HOURLY_RATES[parseInt(key)]}
              </option>
            ))}
          </Form.Select>
        </Col>
        <Col lg="12" className="col-group2">
          <Form.Check
            className="checkbox"
            type="checkbox"
            label="物品報酬でも可能な場合はこちらにチェックしてください"
            {...register('is_receive_gift')}
          />
        </Col>
      </Col>
    </Form.Group>
  );

  const renderProviderTypeFilter = () => (
    <Form.Group as={Row} className="g-0">
      <Form.Label column lg="3">
        系統
      </Form.Label>
      <Col lg="9" className="input last-item">
        <Form.Select {...register('member_type_id')}>
          <option value="">指定なし</option>
          {Object.keys(PROVIDER_TYPES).map((key: string) => (
            <option key={key} value={key}>
              {PROVIDER_TYPES[parseInt(key)]}
            </option>
          ))}
        </Form.Select>
      </Col>
    </Form.Group>
  );

  const renderServiceFilter = () => {
    const activeBtnStyle = {
      borderStyle: 'solid',
      borderWidth: 1,
      borderColor: 'white',
    };
    return (
      <Controller
        control={control}
        name="service_id"
        render={({ field }) => (
          <Container className="find-type">
            <div className="flex provider-search--bar">
              <img src={IconSearch} alt="search" />
              <span>ジャンルから探す</span>
            </div>
            <Row className="g-0 w-100">
              {[
                { value: 3, label: 'レズビアン', img: Img1 },
                { value: 2, label: 'マッサージ', img: Img2 },
                { value: 1, label: 'レンタル彼氏', img: Img3 },
              ].map((block, index) => (
                <Col
                  key={block.value}
                  lg="4"
                  md="4"
                  xs="4"
                  className="item"
                  onClick={(e) => {
                    e.preventDefault();
                    setActiveBtn(block.value);
                    field.onChange(block.value);
                    handleSubmit(onSubmit)();
                  }}
                >
                  <a href="#">
                    <label>{block.label}</label>
                    <img
                      src={block.img}
                      alt=""
                      style={activeBtn === block.value ? activeBtnStyle : {}}
                    ></img>
                  </a>
                </Col>
              ))}
            </Row>
          </Container>
        )}
      ></Controller>
    );
  };

  const renderMamaFilter = () => (
    <>
      <div className="container provider-search">
        <div className="flex provider-search--bar">
          <img src={IconSearch} alt="search" />
          <span>相手を探す</span>
        </div>
        <Form>
          {renderKeywordFilter()}
          {renderPrefectureFilter()}
          {renderAgeFilter()}
          {renderAnnualIncomeFilter()}
          {renderPurposeFilter()}
          <p className="text-center mb-0">
            <Button type="submit" variant="base" onClick={handleSubmit(onSubmit)}>
              検索
            </Button>
          </p>
        </Form>
      </div>
    </>
  );

  const renderProviderFilter = () => (
    <Container className="find-partner provider-search">
      <div className="flex provider-search--bar">
        <img src={IconSearch} alt="search" />
        <span>相手を探す</span>
      </div>
      <Form>
        {renderGenderFilter()}
        {renderKeywordFilter()}
        {renderPrefectureFilter()}
        {renderAgeFilter()}
        {renderExpectedHourlyRateFilter()}
        {renderProviderTypeFilter()}
        <p className="text-center mb-0">
          <Button type="submit" variant="base" onClick={handleSubmit(onSubmit)}>
            検索
          </Button>
        </p>
      </Form>
      {renderServiceFilter()}
    </Container>
  );

  return <>{isMama ? renderProviderFilter() : renderMamaFilter()}</>;
};
