import { initializeApp } from 'firebase/app';
import {
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  increment,
  onSnapshot,
  orderBy,
  query,
  serverTimestamp,
  setDoc,
  updateDoc,
  where,
} from 'firebase/firestore';

import {
  FIREBASE_API_KEY,
  FIREBASE_APP_ID,
  FIREBASE_AUTH_DOMAIN,
  FIREBASE_MEASUREMENT_ID,
  FIREBASE_MESSAGING_SENDER_ID,
  FIREBASE_PROJECT_ID,
  FIREBASE_STORAGE_BUCKET,
} from 'config';
import {
  FIREBASE_COLLECTION_CONVERSATIONS,
  FIREBASE_COLLECTION_MESSAGES,
  UserRole,
} from 'config/constants';

const firebaseConfig = {
  apiKey: FIREBASE_API_KEY,
  authDomain: FIREBASE_AUTH_DOMAIN,
  projectId: FIREBASE_PROJECT_ID,
  storageBucket: FIREBASE_STORAGE_BUCKET,
  messagingSenderId: FIREBASE_MESSAGING_SENDER_ID,
  appId: FIREBASE_APP_ID,
  measurementId: FIREBASE_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

let unreadMessageUnsubscribe: any = null;
let conversationUnsubscribe: any = null;

const createConversationDocument = async (
  conversationId: number,
  mamaId: number,
  providerId: number,
  orderId?: number
) => {
  await setDoc(doc(db, FIREBASE_COLLECTION_CONVERSATIONS, conversationId.toString()), {
    mama_id: mamaId,
    provider_id: providerId,
    last_message_id: null,
    mama_unread: 0,
    provider_unread: 0,
    order_id: orderId || null,
  });
};

export const setupMessageListener = (conversationId: number, onSuccess: any, userId: number) => {
  if (conversationUnsubscribe) {
    conversationUnsubscribe();
  }
  conversationUnsubscribe = onSnapshot(
    query(
      collection(
        db,
        FIREBASE_COLLECTION_CONVERSATIONS,
        conversationId.toString(),
        FIREBASE_COLLECTION_MESSAGES
      ),
      orderBy('created_at')
    ),
    (docSnaps) => {
      const msgs: Array<any> = [];
      docSnaps.forEach((docSnap) => {
        const data = docSnap.data();
        if (!data.deleted_by || !data.deleted_by.includes(userId)) {
          msgs.push({
            ...docSnap.data(),
            id: docSnap.id,
          });
        }
      });
      onSuccess(msgs);
    }
  );
};

export const getAllMessages = async (
  conversationId: number,
  onSuccess: any,
  myId: number,
  mamaId: number,
  providerId: number,
  orderId?: number
) => {
  const docRef = await getDoc(
    doc(db, FIREBASE_COLLECTION_CONVERSATIONS, conversationId.toString())
  );
  if (docRef.exists()) {
    console.log('Doc  exist');
    setupMessageListener(conversationId, onSuccess, myId);
  } else {
    console.log('Doc not exist');
    await createConversationDocument(conversationId, mamaId, providerId, orderId);
    setupMessageListener(conversationId, onSuccess, myId);
    onSuccess([]);
  }
};

export const sendMessage = async (
  conversationId: string,
  msgId: string,
  msg: any,
  mamaId: number,
  providerId: number,
  orderId?: number
) => {
  await setDoc(
    doc(db, FIREBASE_COLLECTION_CONVERSATIONS, conversationId),
    {
      last_message_id: msgId,
      mama_id: mamaId,
      provider_id: providerId,
      order_id: orderId || null,
    },
    {
      merge: true,
    }
  );

  if (msg.sender_id === mamaId) {
    await updateDoc(doc(db, FIREBASE_COLLECTION_CONVERSATIONS, conversationId), {
      provider_unread: increment(1),
    });
  } else {
    await updateDoc(doc(db, FIREBASE_COLLECTION_CONVERSATIONS, conversationId), {
      mama_unread: increment(1),
    });
  }

  await setDoc(
    doc(db, FIREBASE_COLLECTION_CONVERSATIONS, conversationId, FIREBASE_COLLECTION_MESSAGES, msgId),
    {
      ...msg,
      created_at: serverTimestamp(),
    }
  );
};

export const deleteMessage = async (conversationId: string, msgId: string) => {
  await updateDoc(
    doc(db, FIREBASE_COLLECTION_CONVERSATIONS, conversationId, FIREBASE_COLLECTION_MESSAGES, msgId),
    {
      is_deleted: 1,
    }
  );
};

export const deleteConversation = async (conversationId: number, userId: number) => {
  const querySnapshot = await getDocs(
    query(
      collection(
        db,
        FIREBASE_COLLECTION_CONVERSATIONS,
        conversationId.toString(),
        FIREBASE_COLLECTION_MESSAGES
      )
    )
  );

  querySnapshot.forEach(async (doc) => {
    const deletedBy = doc.data().deleted_by;
    const data = [userId];
    if (deletedBy && deletedBy.length > 0) {
      data.push(...deletedBy);
    }
    await updateDoc(doc.ref, { deleted_by: data });
  });
};

export const updateUnread = async (userId: number, userRole: UserRole, conversationId: string) => {
  const querySnapshot = await getDocs(
    query(
      collection(
        db,
        FIREBASE_COLLECTION_CONVERSATIONS,
        conversationId.toString(),
        FIREBASE_COLLECTION_MESSAGES
      ),
      where('receiver_id', '==', userId),
      where('is_read', '==', 0)
    )
  );
  querySnapshot.forEach((doc) => {
    updateDoc(doc.ref, { is_read: 1 });
  });

  if (userRole == UserRole.Mama) {
    await updateDoc(doc(db, FIREBASE_COLLECTION_CONVERSATIONS, conversationId), {
      mama_unread: 0,
    });
  } else {
    await updateDoc(doc(db, FIREBASE_COLLECTION_CONVERSATIONS, conversationId), {
      provider_unread: 0,
    });
  }
};

export const listenerUnreadMessage = (userId: number, userRole: UserRole, onSuccess: any) => {
  if (unreadMessageUnsubscribe) {
    unreadMessageUnsubscribe();
  }
  const fieldName = userRole == UserRole.Mama ? 'mama_id' : 'provider_id';
  const fieldCount = userRole == UserRole.Mama ? 'mama_unread' : 'provider_unread';
  unreadMessageUnsubscribe = onSnapshot(
    query(collection(db, FIREBASE_COLLECTION_CONVERSATIONS), where(fieldName, '==', userId)),
    (docSnaps) => {
      let messageCount = 0;
      let specialCount = 0;
      docSnaps.forEach((docSnap) => {
        const data = docSnap.data();
        if (!data['order_id']) {
          if (data[fieldCount] && data[fieldCount] > 0) {
            messageCount += data[fieldCount];
          }
        } else {
          specialCount += data[fieldCount] || 0;
        }
      });
      onSuccess([messageCount, specialCount]);
    }
  );
};

export const getUnreadCount = async (conversationId: string, isMama: boolean) => {
  const docRef = doc(db, FIREBASE_COLLECTION_CONVERSATIONS, conversationId);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    const data = docSnap.data();
    if (isMama) {
      return data.mama_unread;
    } else return data.provider_unread;
  } else {
    console.log('This conversation does not exist: ', conversationId);
  }
};

export const getUnreadCountForOrder = async (orderId: number, userId: number, isMama: boolean) => {
  const fieldName = isMama ? 'mama_id' : 'provider_id';
  const fieldCount = isMama ? 'mama_unread' : 'provider_unread';
  const querySnapshot = await getDocs(
    query(
      collection(db, FIREBASE_COLLECTION_CONVERSATIONS),
      where('order_id', '==', orderId),
      where(fieldName, '==', userId)
    )
  );
  let messageCount = 0;
  querySnapshot.forEach((doc) => {
    const data = doc.data();
    if (data[fieldCount] && data[fieldCount] > 0) {
      messageCount += data[fieldCount];
    }
  });
  return messageCount;
};

export const detachMessageListeners = () => {
  conversationUnsubscribe && conversationUnsubscribe();
};

export const detachUnreadListeners = () => {
  unreadMessageUnsubscribe && unreadMessageUnsubscribe();
};

export { app };
