import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';

import { UserRole } from 'config/constants';
import { useStores } from 'hooks/use-stores';
import { Conversation } from 'modules/message/models';
import { ConversationItem } from 'screens/message-list/components/ConversationItem';
import { get } from 'services';
import { getUnreadCount } from 'services/firebase';
import NotificationStore from 'stores/notification';
import SessionStore from 'stores/session';
import UIStore from 'stores/ui';
import { toAwait } from 'utils';

export const SpecialOrderConversationListScreen = observer(() => {
  const uiStore: UIStore = useStores().uiStore;
  const notificationStore: NotificationStore = useStores().notificationStore;
  const sessionStore: SessionStore = useStores().sessionStore;
  const isMama = sessionStore.session && sessionStore.session.role === UserRole.Mama ? true : false;

  const [conversations, setConversations] = useState<Array<Conversation>>([]);
  const [inited, setInited] = useState<boolean>(false);

  useEffect(() => {
    getData(1).then(() => setInited(true));
  }, []);

  useEffect(() => {
    if (inited) getData(1); // reload data when new message arrived
  }, [notificationStore.specialCount]);

  const getData = async (page: number) => {
    const loading = uiStore.showLoading();
    const [res, err] = await toAwait(get('message/inbox?is_order=1', { page: page }));
    if (err) {
      console.log('error when list news: ', err);
    } else {
      const getUnreadCountTasks = [];
      for (let i = 0; i < res.length; i++) {
        getUnreadCountTasks.push(getUnreadCount(res[i].id.toString(), isMama));
      }

      Promise.all(getUnreadCountTasks)
        .then((unreadCounts) => {
          console.log('unreadCounts: ', unreadCounts);
          for (let i = 0; i < unreadCounts.length; i++) {
            res[i].unread_count = unreadCounts[i] || 0;
          }
        })
        .finally(() => {
          setConversations(res);
          uiStore.hideLoading(loading);
        });
    }
  };

  return (
    <div className="sorder-page">
      <Helmet>
        <title>スペシャルオーダー</title>
      </Helmet>
      <Container>
        <div className="text-center top-title-h1">
          <span>SPECIAL ORDER</span>
        </div>
        <div className="sub-title">スペシャルオーダー</div>
        <p className="sub-content">
          スペシャルオーダーとはサービス提供側のすべての会員に一斉送信ができる機能となっています。
          <br />
          自分のやりたい事、叶えてみたい事、夢などを送信して叶えてくれるお相手を探してみませんか？
        </p>
        <div className="sorder-page-inner message-list-inner">
          {conversations.map((item) => (
            <ConversationItem
              key={item.id}
              item={item}
              order={{ id: item.order_id }}
              onDeleteComplete={() => getData(1)}
            />
          ))}
        </div>
      </Container>
    </div>
  );
});
