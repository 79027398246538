import {
  ANNUAL_INCOMES,
  EXPECTED_HOURLY_RATES,
  GENDERS,
  Gender,
  OCCUPATIONS,
  PREFECTURES,
  PROVIDER_TYPES,
  PURPOSES,
  SERVICES,
} from 'config/constants';

export async function toAwait<T>(promise: Promise<T>): Promise<[T | null, any | null]> {
  try {
    let res = await promise;
    return [res, null];
  } catch (err) {
    return [null, err];
  }
}

export function makeUuid(length: number) {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export async function sleep(delay: number) {
  return new Promise(function (resolve) {
    setTimeout(resolve, delay);
  });
}

export function getAge(birthday: string) {
  const ageDifMs = Date.now() - new Date(birthday).getTime();
  const ageDate = new Date(ageDifMs); // miliseconds from epoch
  return Math.abs(ageDate.getUTCFullYear() - 1970);
}

export function getGeneration(birthday: string) {
  const age = getAge(birthday);
  return Math.floor(age / 10) * 10;
}

export function getPrefecture(id?: number) {
  return id ? PREFECTURES[id] : '';
}

export function getProviderType(id?: number) {
  return id ? PROVIDER_TYPES[id] : '';
}

export function getSalaryPerHour(id?: number) {
  return id ? EXPECTED_HOURLY_RATES[id] : '';
}

export function getJob(id?: number) {
  return id ? OCCUPATIONS[id] : '';
}

export function getPurpose(id?: number) {
  return id ? PURPOSES[id] : '';
}

export function getAnnualIncome(id?: number) {
  return id ? ANNUAL_INCOMES[id] : '';
}

export function getProviderService(id?: number) {
  return id ? SERVICES[id] : '';
}

export function getGender(id: Gender) {
  const index = GENDERS.findIndex((item) => item.value === id);
  return index > -1 ? GENDERS[index].label : '';
}

export function formatCurrency(number?: number) {
  return new Intl.NumberFormat().format(number || 0);
}

export function formatUnread(number: number) {
  return number > 100 ? '99+' : number;
}

export function filterBadWords(text: string, badwords: Array<string>) {
  if (badwords.length == 0) return text;
  const rgx = new RegExp(badwords.join("|"), "gi");
  return text.replace(rgx, "****");
}
