import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';

import BubbleImage from 'assets/images/bubble.png';
import HeartImage from 'assets/images/heart.png';
import BackgroundImage from 'assets/images/provider-check.png';
import TestimonialImage1 from 'assets/images/testimonial_1.png';
import TestimonialImage2 from 'assets/images/testimonial_2.png';
import TestimonialImage3 from 'assets/images/testimonial_3.png';
import { UserRole } from 'config/constants';
import { useStores } from 'hooks/use-stores';
import { ProviderProfile } from 'modules/user/models';
import { get } from 'services';
import SessionStore from 'stores/session';
import UIStore from 'stores/ui';
import { UserList } from '../user-list/components/UserList';

import './style.scss';

export const ProviderCheckScreen = observer(() => {
  const history = useHistory();
  const location: any = useLocation();
  let params: any = {};
  if (location.state) {
    params.snsProvider = location.state.snsProvider;
    params.userData = location.state.userData;
    console.log('params register: ', params);
  }

  const sessionStore: SessionStore = useStores().sessionStore;
  const uiStore: UIStore = useStores().uiStore;
  const isMama = sessionStore.session
    ? sessionStore.session.role === UserRole.Mama
      ? true
      : false
    : null;

  const [providersWithPlan, setProvidersWithPlan] = useState<Array<ProviderProfile>>([]);
  const [providersWithoutPlan, setProvidersWithoutPlan] = useState<Array<ProviderProfile>>([]);

  useEffect(() => {
    fetchProvidersWithPlan(1);
    fetchProvidersWithoutPlan(1);
  }, [isMama]);

  const goToRegistScreen = (role: UserRole) => {
    sessionStore.setRegisterProcess('registRole', role);
    if (!params.snsProvider) {
      history.push('/register/sns', { role: role });
    } else {
      let path = role === UserRole.Mama ? '/register/mama' : '/register/provider';
      history.push(path, params);
    }
  };

  const fetchProvidersWithPlan = async (page: number) => {
    const loadingId = uiStore.showLoading();
    const url = 'providers/search';
    const res = await get(url, { page: page, limit: 5, rand: 1, has_plan: 1 });
    console.log('search providers res: ', res);
    if (res) {
      setProvidersWithPlan(res.data);
    }
    uiStore.hideLoading(loadingId);
  };

  const fetchProvidersWithoutPlan = async (page: number) => {
    const loadingId = uiStore.showLoading();
    const url = 'providers/search';
    const res = await get(url, { page: page, limit: 10, rand: 1, has_plan: 0 });
    console.log('search providers res: ', res);
    if (res) {
      setProvidersWithoutPlan(res.data);
    }
    uiStore.hideLoading(loadingId);
  };

  return (
    <div className="col-md-12 col-sm-12 col-xs-12">
      <div className="image-banner">
        <img src={BackgroundImage} alt="" />
      </div>
      <div className="provider-check">
        <div className="register-mama-btn">
          <div role="button" onClick={() => goToRegistScreen(UserRole.Mama)}>
            新規ママ登録はコチラ
          </div>
        </div>

        <div className="provider-list-title">ご利用中ユーザー 一覧</div>
        <div className="provider-list">
          <UserList users={providersWithPlan} isMama={true} filter={{}} />
          <UserList users={providersWithoutPlan} isMama={true} filter={{}} />
        </div>

        <div className="testimonial">
          <div className="title">
            <img src={HeartImage} alt="" />
            <span>ご利用中ママ達のお声</span>
            <img src={HeartImage} alt="" />
          </div>
          <div className="bubble">
            <img src={BubbleImage} alt="" />
            <div>
              ご利用者
              <br />
              急上昇中
            </div>
          </div>
          <div className="detail">
            <div className="item">
              <div className="i-title">30代　主婦Aさん</div>
              <hr />
              <div className="content">
                何度も利用してます、最近だと女子会の後にここで２人男の子を呼びました！！
                <br />
                会話も弾んで楽しかったです!(^^)!
              </div>
              <div className="avatar">
                <img src={TestimonialImage1} alt="" />
              </div>
            </div>
            <div className="item">
              <div className="i-title">27歳　取締役Kさん</div>
              <hr />
              <div className="content">
                どうしても誰かに話しをきいてほしくて利用しました。
                <br />
                想像以上に男の子からいっぱい連絡きて贅沢に選ばせて頂きましたｗｗ
              </div>
              <div className="avatar">
                <img src={TestimonialImage2} alt="" />
              </div>
            </div>
            <div className="item">
              <div className="i-title">50代　フリーターIさん</div>
              <hr />
              <div className="content">
                夜の営みに相手を毎回ここで見つけさせて頂いております。
                <br />
                直ぐにお会いできるので私としては非常に助かってます。今後も利用させて頂きます。
              </div>
              <div className="avatar">
                <img src={TestimonialImage3} alt="" />
              </div>
            </div>
          </div>

          <div className="inquiry-btn">
            <div role="button" onClick={() => history.push('/inquiry')}>
              口コミを送信する(ママ限定)
            </div>
          </div>

          <div className="flattery">
            Desireでは、ママからのお声を日々募集しております。
            <br />
            ご提供頂いた中から選考しサイト内にて公開させて頂きます。
            <br />
            ・登録済みアドレス・ご年齢・記載希望のユーザー名
            <br />
            ・アイコン画像掲載の可否・口コミ内容を記載の上ご連絡ください
          </div>
        </div>

        <div className="privacy">
          <div>『安心安全の取り組みについて』</div>
          <p>
            １．【本人確認書類の提出義務の徹底】
            <br />
            利用するには、名前、生年月日のみ確認を行うため、
            <br />
            本人確認書類の提出が必要となります。(それ以外の住所等は不要)
            <br />
            管理者が目視にて直接確認しているため、
            <br />
            他人を装い利用する、偽装行為などは行えません。
            <br />
            <br />
            ２．【24時間の監視体制】
            <br />
            本サイトでは、ユーザーからの通報など、
            <br />
            問い合わせについての迅速な対応を徹底しております。
            <br />
            問題行為についての通報があったユーザーにつきましては、
            <br />
            強制退会なども行っております。
            <br />
            <br />
            ３．【警察庁公認サイト】
            <br />
            本サイトは、警察庁へ運営について、
            <br />
            正式に書面にて届出を行い公認されております。
            <br />
            （インターネット異性紹介事業）
          </p>
        </div>

        <div className="register-btn">
          <div role="button" onClick={() => history.push('/register')}>
            登録はコチラ
          </div>
        </div>
      </div>
    </div>
  );
});
