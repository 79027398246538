import { useEffect, useState } from 'react';

import { ImageInputButton } from '../../../components/ImageInputButton';
import { Popup } from '../../../components/Popup';

interface IProps {
  onFileChange: any;
  oldValue?: string;
}

export const ImageSelectorPopup = ({ onFileChange, oldValue }: IProps) => {
  const [src, setSrc] = useState<any>(null);
  const [popup, setPopup] = useState(false);

  useEffect(() => {
    if (oldValue) setSrc(oldValue);
  }, [oldValue]);

  let bgStyle = !!src ? { backgroundImage: `url(${src})`, backgroundSize: 'cover' } : {};

  return (
    <div className="round_register">
      <div className="content_round_register" style={bgStyle}>
        <button
          style={{ border: 0 }}
          className="text_round_register"
          onClick={(e: any) => {
            e.preventDefault();
            setPopup(true);
          }}
        >
          ファイルの選択
        </button>
      </div>
      {popup && (
        <Popup
          title={oldValue ? '' : '写真をアップロードしてください'}
          contentComponent={
            oldValue ? (
              <>
                <div className="preview_old_profile" style={bgStyle}></div>
                <p>プロフィール写真を変更する場合は写真をアップロードしなおしてください</p>
              </>
            ) : undefined
          }
          ButtonComponent={
            <ImageInputButton
              onChange={(file: File) => {
                setSrc(URL.createObjectURL(file));
                setPopup(false);
                onFileChange(file);
              }}
            />
          }
          onClose={() => setPopup(false)}
        />
      )}
    </div>
  );
};
